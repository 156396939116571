/**
 * @constant
 * @type {ConstantsType}
 * @name Constants
 * @description An object of constant data for use throughtout the library
 *
 * @property {string} METADATA_LIB_NAME_VERSION {METADATA_LIB_NAME_VERSION}
 * @property {string} JS_TYPE_OBJECT object
 * @property {string} JS_TYPE_NUMBER number
 * @property {string} JS_TYPE_STRING string
 * @property {string} JS_TYPE_BOOLEAN boolean
 * @property {string} JS_TYPE_UNDEFINED undefined
 * @property {string} HTMLMEDIAELEMENT_PROPERTY_CURRENTTIME currentTime
 * @property {string} HTMLMEDIAELEMENT_PROPERTY_DURATION duration
 * @property {string} ERROR_PROPERTY_STACK stack
 * @property {string} CONFIG_ANALYTICS_SERVICES analyticsServices
 * @property {string} CONFIG_ELEMENTS elements
 * @property {string} CONFIG_LOGGER_OPTIONS loggerOptions
 * @property {string} CONFIG_LOG_LEVEL logLevel
 * @property {string} CONFIG_LOG_SUFFIX_ON suffixOn
 * @property {string} CONFIG_GLOBALS globals
 * @property {string} CONFIG_GLOBAL_VARIABLES variables
 * @property {string} SESSION_STORAGE_LOG_LEVEL wk.ual.logLevel
 * @property {string} SESSION_STORAGE_DEFERRED_CALLS wk.ual.deferredObjcounters
 * @property {string} DEFERRED_TRACKING_OBJ_CALLS calls
 * @property {Object} DDOBJ_DEFINITION { dynamicDataType: '', selectorType: '', selectorOptions: {} }
 * @property {string} DDOBJ_DYNAMIC_DATA_TYPE dynamicDataType
 * @property {string} DDOBJ_SELECTOR_TYPE selectorType
 * @property {string} DDOBJ_SELECTOR_OPTIONS selectorOptions
 * @property {string} DDOBJ_SELECTOR_OPTIONS_VALUE_TYPE valueType
 * @property {string} DDOBJ_SELECTOR_OPTIONS_SELECTOR selector
 * @property {string} DDOBJ_SELECTOR_OPTIONS_VALUE value
 * @property {string} DDOBJ_SELECTOR_OPTIONS_MULTIVAL_SEPARATOR separator
 * @property {string} DDOBJ_SELECTOR_OPTIONS_ANCESTOR ancestor
 * @property {string} DDOBJ_SELECTOR_OPTIONS_FORMAT format
 * @property {string} DDOBJ_SELECTOR_OPTIONS_LOCAL_TIME localTime
 * @property {string} DDOBJ_SELECTOR_OPTIONS_PATTERN pattern
 * @property {string} DDOBJ_SELECTOR_OPTIONS_CALCULATION calculation
 * @property {string} DDOBJ_SELECTOR_OPTIONS_PRECISION precision
 * @property {string} DYNAMIC_DATA_TYPE_INIT init
 * @property {string} DYNAMIC_DATA_TYPE_GLOBAL global
 * @property {string} DYNAMIC_DATA_TYPE_CLICK click
 * @property {string} DYNAMIC_DATA_TYPE_SUBMIT submit
 * @property {string} DYNAMIC_DATA_TYPE_DEFER defer
 * @property {string} DYNAMIC_DATA_TYPE_OBSERVE observe
 * @property {string} DYNAMIC_DATA_TYPE_MEDIA media
 * @property {string} DYNAMIC_DATA_TYPE_WINDOWEVENT windowevent
 * @property {string} DYNAMIC_DATA_TYPE_EVENTTIMING eventtiming
 * @property {string} DYNAMIC_DATA_TYPE_MULTI multi
 * @property {string} DYNAMIC_DATA_TYPE_MULTI_CLICK multiclick
 * @property {string} DYNAMIC_DATA_TYPE_MULTI_SEPARATOR ?
 * @property {string} DYNAMIC_DATA_PAGEVALUE pagevalue
 * @property {string} DYNAMIC_DATA_CSS css
 * @property {string} DYNAMIC_DATA_URLPARAM urlparam
 * @property {string} DYNAMIC_DATA_PROPERTIES properties
 * @property {string} DYNAMIC_DATA_TARGET target
 * @property {string} DYNAMIC_DATA_COUNTER counter
 * @property {string} DYNAMIC_DATA_SYSTEM system
 * @property {string} DYNAMIC_DATA_SYSTEM_LOADTIME loadtime
 * @property {string} DYNAMIC_DATA_SYSTEM_TIMESTAMP timestamp
 * @property {string} DYNAMIC_DATA_SYSTEM_TIMESTAMP_DEFAULT ddd mmm dd yyyy HH:MM:ss
 * @property {string} DYNAMIC_DATA_SYSTEM_UALVERSION ualversion
 * @property {string} DYNAMIC_DATA_SYSTEM_TIMING timing
 * @property {string} DYNAMIC_DATA_OBJ_DATA data
 * @property {string} DYNAMIC_DATA_OBJ_DATA_PATH path
 * @property {string} DYNAMIC_DATA_OBJ_DATA_VALUE value
 * @property {string} DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIERS modifiers
 * @property {string} DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_TYPE type
 * @property {string} DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_TYPE_MATCH match
 * @property {string} DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_TYPE_REPLACE replace
 * @property {string} DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_TYPE_CONVERT convert
 * @property {string} DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REGEX regex
 * @property {string} DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REGEX_GROUP group
 * @property {string} DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REPLACE replace
 * @property {string} DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REPLACE_GLOBAL global
 * @property {string} DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_CONVERT_TO convertTo
 * @property {string} DYNAMIC_DATA_CONVERSION_TYPE_NUMBER number
 * @property {string} DYNAMIC_DATA_CONVERSION_TYPE_STRING string
 * @property {string} DYNAMIC_DATA_CONVERSION_TYPE_BOOLEAN boolean
 * @property {string} DYNAMIC_DATA_SEPARATOR |
 * @property {string} DYNAMIC_DATA_PROPERTIES_SEPARATOR ,
 * @property {string} DYNAMIC_DATA_PAGE_VALUE_OBJECT_PROPERTY_SEPARATOR .
 * @property {string} DYNAMIC_DATA_SINGLE_VAL single
 * @property {string} DYNAMIC_DATA_MULTI_VAL multi
 * @property {string} DYNAMIC_DATA_COUNT_VAL count
 * @property {string} DYNAMIC_DATA_COMBINE_VAL combine
 * @property {string} DYNAMIC_DATA_PATTERN_VAL pattern
 * @property {string} DYNAMIC_DATA_CALCULATION_VAL calculation
 * @property {string} DYNAMIC_DATA_BOOLEAN_VAL boolean
 * @property {string[]} DYNAMIC_DATA_URLPARAM_BOOLEAN_STRING_TRUE_EQUIVALENCE [ 'true', '1', 'yes', 'y' ]
 * @property {string} DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES pageValues
 * @property {string} DYNAMIC_DATA_PROCESS_TYPE_ARGS_MULTI_SELECTOR multiSelector
 * @property {string} DYNAMIC_DATA_PROCESS_TYPE_ARGS_EVENT event
 * @property {string} DYNAMIC_DATA_PROCESS_TYPE_ARGS_TIMING timing
 * @property {string} TRUE_STRING 'true'
 * @property {string} FALSE_STRING 'false'
 * @property {string[]} FALSE_VALUES_ARRAY [ false, 'false', '0', '', 0, null, undefined ]
 * @property {string} ELEMENT_DATA_TYPE type
 * @property {string} ELEMENT_DATA_TRACKING_EVENT_TYPE trackingEventType
 * @property {string} ELEMENT_DATA_DATA data
 * @property {string} ELEMENT_DATA_START_DATA startData
 * @property {string} ELEMENT_DATA_FINISH_DATA finishData
 * @property {string} ELEMENT_DATA_SEGMENT_DATA segmentData
 * @property {string} ELEMENT_DATA_SELECTOR selector
 * @property {string} ELEMENT_DATA_DEFER defer
 * @property {string} ELEMENT_DATA_TRACK_MULTIPLE trackMultiple
 * @property {string} ELEMENT_DATA_RETAIN_HANDLERS retainHandlers
 * @property {string} ELEMENT_DATA_WINDOW_EVENT_EVENT_TYPE eventType
 * @property {string} ELEMENT_DATA_OBSERVE_ELEMENTS elements
 * @property {string} ELEMENT_DATA_WAIT_FOR_EXIST waitForExist
 * @property {string} ELEMENT_DATA_WAIT_FOR_EXIST_OPTION_SELECTOR selector
 * @property {string} ELEMENT_DATA_WAIT_FOR_EXIST_OPTION_TIMEOUT timeout
 * @property {string} ELEMENT_DATA_ASYNC async
 * @property {string} ELEMENT_DATA_ASYNC_OPTION_TRACK track
 * @property {string} ELEMENT_DATA_ASYNC_OPTION_RESCAN rescan
 * @property {string} ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_TIMEOUT timeout
 * @property {string} ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_SELECTOR selector
 * @property {string} ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_SELECTOR_ROOT root
 * @property {string} ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_WAIT wait
 * @property {string} ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_DELETEELEMENT deleteElement
 * @property {string} ELEMENT_DATA_CONDITIONS conditions
 * @property {string} ELEMENT_DATA_PRE_CONDITIONS preConditions
 * @property {string} ELEMENT_DATA_CONDITIONAL_OPTIONS_TYPE type
 * @property {string} ELEMENT_DATA_CONDITIONAL_OPTIONS_TYPE_CSS css
 * @property {string} ELEMENT_DATA_CONDITIONAL_OPTIONS_TYPE_PAGEVALUE pagevalue
 * @property {string} ELEMENT_DATA_CONDITIONAL_OPTIONS_SELECTOR selector
 * @property {string} ELEMENT_DATA_CONDITIONAL_OPTIONS_CONDITION condition
 * @property {string} ELEMENT_DATA_CONDITIONAL_OPTIONS_CONDITION_IF if
 * @property {string} ELEMENT_DATA_CONDITIONAL_OPTIONS_CONDITION_NOT not
 * @property {string} ELEMENT_DATA_HASH_ID hashID
 * @property {string} ELEMENT_DATA_HASH_ID_NO_ID NO_HASH_ID
 * @property {string} ELEMENT_DATA_DEFERRED_TIMESTAMP timestamp
 * @property {string} ELEMENT_DATA_MULTI_ELEMENT_TYPE_CLICKABLE clickable
 * @property {string} ELEMENT_DATA_MULTI_ELEMENT_TYPE_HTML html
 * @property {string} ELEMENT_DATA_DESCRIPTION description
 * @property {string} ELEMENT_DATA_MEDIA_OPTIONS mediaOptions
 * @property {string} ELEMENT_DATA_MEDIA_OPTIONS_TRACK_START trackStart
 * @property {string} ELEMENT_DATA_MEDIA_OPTIONS_TRACK_FINISH trackFinish
 * @property {string} ELEMENT_DATA_MEDIA_OPTIONS_TRACK_SEGMENTS trackSegments
 * @property {string} ELEMENT_DATA_MEDIA_OPTIONS_SEGMENTS segments
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS observerOptions
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS_CURRENT_CONDITIONS currentConditions
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS_CONDITIONS conditions
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE type
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE_ELEMENT element
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE_ELEMENT_ID 1
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE_TEXT text
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE_TEXT_ID 3
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TARGET target
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_ATTRIBUTE attribute
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_SELECTOR selector
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_VALUE value
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST childList
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST_ADDED added
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST_REMOVED removed
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS_ATTRIBUTES attributes
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS_CHARACTER_DATA characterData
 * @property {string} ELEMENT_DATA_OBSERVER_OPTIONS_PROCESS_ON_LOAD processOnLoad
 * @property {string} ELEMENT_DATA_EVENT_TIMING_START start
 * @property {string} ELEMENT_DATA_EVENT_TIMING_END end
 * @property {string} ELEMENT_DATA_EVENT_TIMING_TYPE type
 * @property {string} ELEMENT_DATA_EVENT_TIMING_ELEMENT element
 * @property {string} ELEMENT_DATA_EVENT_TIMING_EVENT event
 * @property {string} ELEMENT_DATA_EVENT_TIMING_TYPE_GLOBAL global
 * @property {string} ELEMENT_DATA_EVENT_TIMING_TYPE_HTML html
 * @property {string} ELEMENT_DATA_EVENT_TIMING_DEFERRED_START_TIME deferredStartTime
 * @property {string} ELEMENT_DATA_EVENT_TIMING_LOAD_EVENT load
 * @property {string} ELEMENT_DATA_EVENT_TIMING_WINDOW_ELEMENT window
 * @property {string} ELEMENT_DATA_PROCESSING processing
 * @property {string} ELEMENT_TYPE_PAGE page
 * @property {string} ELEMENT_TYPE_CLICKABLE clickable
 * @property {string} ELEMENT_TYPE_SUBMITABLE submitable
 * @property {string} ELEMENT_TYPE_HTML html
 * @property {string} ELEMENT_TYPE_MULTI multi
 * @property {string} ELEMENT_TYPE_OBSERVABLE observable
 * @property {string} ELEMENT_TYPE_MEDIA media
 * @property {string} ELEMENT_TYPE_WINDOWEVENT windowevent
 * @property {string} ELEMENT_TYPE_EVENTTIMING eventtiming
 * @property {string[]} ELEMENT_TYPES_USING_REMOVABLE_EVENT_HANDLERS ['clickable', 'submitable', 'media', 'windowevent']
 * @property {string[]} PRE_SCAN_ELEMENT_TYPES ['clickable', 'submitable', 'observable', 'windowevent', 'eventtiming']
 * @property {string[]} UNSUPPORTED_OBSERVABLE_ELEMENT_TYPES ['multi']
 * @property {string} ANALYZE_PAGE_INPUT_PAGEVALUES pageValues
 * @property {string} ANALYZE_PAGE_INPUT_OPTIONS options
 * @property {string} OPTIONS_MULTIVAL_SEP_DEFAULT |
 * @property {string} GLOBAL_VARIABLES_INDICATOR @
 * @property {number} RESCAN_TIMEOUT_DEFAULT 5000
 * @property {number} RESCAN_INTERVAL 500
 * @property {number} WAIT_FOR_EXIST_TIMEOUT_DEFAULT 2000
 * @property {number} WAIT_FOR_EXIST_INTERVAL 100
 * @property {number} DEFERRED_CALL_STALE_TIME 600000
 * @property {number} LOAD_EVENT_PRE_SCAN_INTERVAL 100
 * @property {number} CHECK_INTERVAL 100
 * @property {Object} LOGGER_LEVELS_ENUM { NONE: 0, INFO: 1, WARN: 2, ERROR: 4, DEBUG: 8, TRACE: 16, NORMAL: 7, VERBOSE: 15, ALL: 31 }
 * @property {string} PACKAGE_NAME wk.ual
 * @property {string} EVENT_TYPE_PAGE page
 * @property {string} EVENT_TYPE_GENERAL general
 * @property {string} EVENT_LISTENER_TYPE_CLICK click
 * @property {string} EVENT_LISTENER_TYPE_SUBMIT submit
 * @property {string} EVENT_LISTENER_TYPE_PLAY play
 * @property {string} EVENT_LISTENER_TYPE_ENDED ended
 * @property {string} EVENT_LISTENER_TYPE_TIMEUPDATE timeupdate
 * @property {string} MEDIA_KEY_STRING_SUFFIX_PLAY _play
 * @property {string} MEDIA_KEY_STRING_SUFFIX_ENDED _ended
 * @property {string} MEDIA_KEY_STRING_SUFFIX_TIMEUPDATE _timeupdate
 * @property {string} LOGGER_VAR wk.ual.logger
 * @property {string} MUTATION_RECORD_TYPE_CHILD_LIST childList
 * @property {string} MUTATION_RECORD_TYPE_ATTRIBUTES attributes
 * @property {string} MUTATION_RECORD_TYPE_CHARACTER_DATA characterData
 * @property {string} MUTATION_RECORD_TARGET_TAG_NAME tagName
 * @property {string} MUTATION_RECORD_TARGET_ID id
 * @property {string} MUTATION_RECORD_TARGET_CLASS_NAME className
 * @property {string} COUNTERS_GLOBAL_PROPERTY counters
 * @property {string} COUNTERS_OPTIONS_SELECTOR selector
 * @property {string} COUNTERS_OPTIONS_UPDATE update
 * @property {string} COUNTERS_OPTIONS_ACTION action
 * @property {string} COUNTERS_OPTIONS_INTERVAL interval
 * @property {string} COUNTERS_TYPE_ACTION action
 * @property {string} COUNTERS_TYPE_INTERVAL interval
 * @property {string} OBSERVABLE_ELEMENT_PROPERTY_CHECK_NAME data-ual-observable
 * @property {string} ELEMENT_HANDLER_PROPERTY_CHECK_NAME_PREFIX data-ual-handler-
 * @property {string} ADOBE_ANALYTICS_LAUNCH_PROPERTY AdobeAnalyticsLaunch
 * @property {string} ADOBE_ANALYTICS_LAUNCH_CONFIG_APP_MEASUREMENT_TRACKER appMeasurementTracker
 * @property {string} ADOBE_ANALYTICS_LAUNCH_CONFIG_TRACKING_OBJ trackingObj
 * @property {string} ADOBE_ANALYTICS_LAUNCH_CONFIG_DATA_OBJ dataObj
 * @property {string} ADOBE_ANALYTICS_LAUNCH_CONFIG_EVENTS events
 * @property {string} ADOBE_ANALYTICS_LAUNCH_CONFIG_EVARS eVars
 * @property {string} ADOBE_ANALYTICS_LAUNCH_CONFIG_PROPS props
 * @property {string} ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS globals
 * @property {string} ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS_COMMON common
 * @property {string} ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS_PAGE page
 * @property {string} ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS_LINK link
 * @property {string} ADOBE_ANALYTICS_LAUNCH_TRACKING_DATA_OBJ data
 * @property {string} ADOBE_ANALYTICS_LAUNCH_TRACKING_DATA_TRACKING_ID id
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_ID common-ual-rule
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_RUNNING wk.ual.launch.common-ual-rule.running
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_RETRY_TIME 500
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_LAUNCH_CONFIG launchConfig
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_BEACON beacon
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_PAGE_VIEW pageView
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_LINK_TYPE linkType
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_LINK_NAME linkName
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DATA data
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_SET set
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DATA_ELEMENT_INDICATOR %
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DEFAULT_LINK_TYPE o
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DEFAULT_LINK_NAME Other
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DELIMETER ,
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_EVENT_DATA_INDICATOR =
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_EVENTS events
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_LINKTRACKVARS linkTrackVars
 * @property {string} ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_LINKTRACKEVENTS linkTrackEvents
 */
export const Constants = {
    METADATA_LIB_NAME_VERSION: '{METADATA_LIB_NAME_VERSION}',
    JS_TYPE_OBJECT: 'object',
    JS_TYPE_NUMBER: 'number',
    JS_TYPE_STRING: 'string',
    JS_TYPE_BOOLEAN: 'boolean',
    JS_TYPE_UNDEFINED: 'undefined',
    HTMLMEDIAELEMENT_PROPERTY_CURRENTTIME: 'currentTime',
    HTMLMEDIAELEMENT_PROPERTY_DURATION: 'duration',
    ERROR_PROPERTY_STACK: 'stack',
    CONFIG_ANALYTICS_SERVICES: 'analyticsServices',
    CONFIG_ELEMENTS: 'elements',
    CONFIG_LOGGER_OPTIONS: 'loggerOptions',
    CONFIG_LOG_LEVEL: 'level',
    CONFIG_LOG_SUFFIX_ON: 'suffixOn',
    CONFIG_GLOBALS: 'globals',
    CONFIG_GLOBAL_VARIABLES: 'variables',
    SESSION_STORAGE_LOG_LEVEL: 'wk.ual.logLevel',
    SESSION_STORAGE_DEFERRED_CALLS: 'wk.ual.deferredObj',
    DEFERRED_TRACKING_OBJ_CALLS: 'calls',
    DDOBJ_DEFINITION: { dynamicDataType: '', selectorType: '', selectorOptions: {} },
    DDOBJ_DYNAMIC_DATA_TYPE: 'dynamicDataType',
    DDOBJ_SELECTOR_TYPE: 'selectorType',
    DDOBJ_SELECTOR_OPTIONS: 'selectorOptions',
    DDOBJ_SELECTOR_OPTIONS_VALUE_TYPE: 'valueType',
    DDOBJ_SELECTOR_OPTIONS_SELECTOR: 'selector',
    DDOBJ_SELECTOR_OPTIONS_VALUE: 'value',
    DDOBJ_SELECTOR_OPTIONS_MULTIVAL_SEPARATOR: 'separator',
    DDOBJ_SELECTOR_OPTIONS_ANCESTOR: 'ancestor',
    DDOBJ_SELECTOR_OPTIONS_FORMAT: 'format',
    DDOBJ_SELECTOR_OPTIONS_LOCAL_TIME: 'localTime',
    DDOBJ_SELECTOR_OPTIONS_PATTERN: 'pattern',
    DDOBJ_SELECTOR_OPTIONS_CALCULATION: 'calculation',
    DDOBJ_SELECTOR_OPTIONS_PRECISION: 'precision',
    DYNAMIC_DATA_TYPE_INIT: 'init',
    DYNAMIC_DATA_TYPE_GLOBAL: 'global',
    DYNAMIC_DATA_TYPE_CLICK: 'click',
    DYNAMIC_DATA_TYPE_SUBMIT: 'submit',
    DYNAMIC_DATA_TYPE_DEFER: 'defer',
    DYNAMIC_DATA_TYPE_OBSERVE: 'observe',
    DYNAMIC_DATA_TYPE_MEDIA: 'media',
    DYNAMIC_DATA_TYPE_WINDOWEVENT: 'windowevent',
    DYNAMIC_DATA_TYPE_EVENTTIMING: 'eventtiming',
    DYNAMIC_DATA_TYPE_MULTI: 'multi',
    DYNAMIC_DATA_TYPE_MULTI_CLICK: 'multiclick',
    DYNAMIC_DATA_TYPE_MULTI_SEPARATOR: '?',
    DYNAMIC_DATA_PAGEVALUE: 'pagevalue',
    DYNAMIC_DATA_CSS: 'css',
    DYNAMIC_DATA_URLPARAM: 'urlparam',
    DYNAMIC_DATA_PROPERTIES: 'properties',
    DYNAMIC_DATA_TARGET: 'target',
    DYNAMIC_DATA_COUNTER: 'counter',
    DYNAMIC_DATA_SYSTEM: 'system',
    DYNAMIC_DATA_SYSTEM_LOADTIME: 'loadtime',
    DYNAMIC_DATA_SYSTEM_TIMESTAMP: 'timestamp',
    DYNAMIC_DATA_SYSTEM_TIMESTAMP_DEFAULT: 'ddd mmm dd yyyy HH:MM:ss',
    DYNAMIC_DATA_SYSTEM_UALVERSION: 'ualversion',
    DYNAMIC_DATA_SYSTEM_TIMING: 'timing',
    DYNAMIC_DATA_OBJ_DATA: 'data',
    DYNAMIC_DATA_OBJ_DATA_PATH: 'path',
    DYNAMIC_DATA_OBJ_DATA_VALUE: 'value',
    DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIERS: 'modifiers',
    DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_TYPE: 'type',
    DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_TYPE_MATCH: 'match',
    DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_TYPE_REPLACE: 'replace',
    DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_TYPE_CONVERT: 'convert',
    DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REGEX: 'regex',
    DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REGEX_GROUP: 'group',
    DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REPLACE: 'replace',
    DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REPLACE_GLOBAL: 'global',
    DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_CONVERT_TO: 'convertTo',
    DYNAMIC_DATA_CONVERSION_TYPE_NUMBER: 'number',
    DYNAMIC_DATA_CONVERSION_TYPE_STRING: 'string',
    DYNAMIC_DATA_CONVERSION_TYPE_BOOLEAN: 'boolean',
    DYNAMIC_DATA_SEPARATOR: '|',
    DYNAMIC_DATA_PROPERTIES_SEPARATOR: ',',
    DYNAMIC_DATA_PAGE_VALUE_OBJECT_PROPERTY_SEPARATOR: '.',
    DYNAMIC_DATA_SINGLE_VAL: 'single',
    DYNAMIC_DATA_MULTI_VAL: 'multi',
    DYNAMIC_DATA_COUNT_VAL: 'count',
    DYNAMIC_DATA_COMBINE_VAL: 'combine',
    DYNAMIC_DATA_PATTERN_VAL: 'pattern',
    DYNAMIC_DATA_CALCULATION_VAL: 'calculation',
    DYNAMIC_DATA_BOOLEAN_VAL: 'boolean',
    DYNAMIC_DATA_URLPARAM_BOOLEAN_STRING_TRUE_EQUIVALENCE: [ 'true', '1', 'yes', 'y' ],
    DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES: 'pageValues',
    DYNAMIC_DATA_PROCESS_TYPE_ARGS_MULTI_SELECTOR: 'multiSelector',
    DYNAMIC_DATA_PROCESS_TYPE_ARGS_EVENT: 'event',
    DYNAMIC_DATA_PROCESS_TYPE_ARGS_TIMING: 'timing',
    TRUE_STRING: 'true',
    FALSE_STRING: 'false',
    FALSE_VALUES_ARRAY: [ false, 'false', '0', '', 0, null, undefined ],
    ELEMENT_DATA_TYPE: 'type',
    ELEMENT_DATA_TRACKING_EVENT_TYPE: 'trackingEventType',
    ELEMENT_DATA_DATA: 'data',
    ELEMENT_DATA_START_DATA: 'startData',
    ELEMENT_DATA_FINISH_DATA: 'finishData',
    ELEMENT_DATA_SEGMENT_DATA: 'segmentData',
    ELEMENT_DATA_SELECTOR: 'selector',
    ELEMENT_DATA_DEFER: 'defer',
    ELEMENT_DATA_RETAIN_HANDLERS: 'retainHandlers',
    ELEMENT_DATA_WINDOW_EVENT_EVENT_TYPE: 'eventType',
    ELEMENT_DATA_OBSERVE_ELEMENTS: 'elements',
    ELEMENT_DATA_TRACK_MULTIPLE: 'trackMultiple',
    ELEMENT_DATA_WAIT_FOR_EXIST: 'waitForExist',
    ELEMENT_DATA_WAIT_FOR_EXIST_OPTION_SELECTOR: 'selector',
    ELEMENT_DATA_WAIT_FOR_EXIST_OPTION_TIMEOUT: 'timeout',
    ELEMENT_DATA_ASYNC: 'async',
    ELEMENT_DATA_ASYNC_OPTION_TRACK: 'track',
    ELEMENT_DATA_ASYNC_OPTION_RESCAN: 'rescan',
    ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_TIMEOUT: 'timeout',
    ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_SELECTOR: 'selector',
    ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_SELECTOR_ROOT: 'root',
    ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_WAIT: 'wait',
    ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_DELETEELEMENT: 'deleteElement',
    ELEMENT_DATA_CONDITIONS: 'conditions',
    ELEMENT_DATA_PRE_CONDITIONS: 'preConditions',
    ELEMENT_DATA_CONDITIONAL_OPTIONS_TYPE: 'type',
    ELEMENT_DATA_CONDITIONAL_OPTIONS_TYPE_CSS: 'css',
    ELEMENT_DATA_CONDITIONAL_OPTIONS_TYPE_PAGEVALUE: 'pagevalue',
    ELEMENT_DATA_CONDITIONAL_OPTIONS_SELECTOR: 'selector',
    ELEMENT_DATA_CONDITIONAL_OPTIONS_CONDITION: 'condition',
    ELEMENT_DATA_CONDITIONAL_OPTIONS_CONDITION_IF: 'if',
    ELEMENT_DATA_CONDITIONAL_OPTIONS_CONDITION_NOT: 'not',
    ELEMENT_DATA_HASH_ID: 'hashID',
    ELEMENT_DATA_HASH_ID_NO_ID: 'NO_HASH_ID',
    ELEMENT_DATA_DEFERRED_TIMESTAMP: 'timestamp',
    ELEMENT_DATA_MULTI_ELEMENT_TYPE_CLICKABLE: 'clickable',
    ELEMENT_DATA_MULTI_ELEMENT_TYPE_HTML: 'html',
    ELEMENT_DATA_DESCRIPTION: 'description',
    ELEMENT_DATA_MEDIA_OPTIONS: 'mediaOptions',
    ELEMENT_DATA_MEDIA_OPTIONS_TRACK_START: 'trackStart',
    ELEMENT_DATA_MEDIA_OPTIONS_TRACK_FINISH: 'trackFinish',
    ELEMENT_DATA_MEDIA_OPTIONS_TRACK_SEGMENTS: 'trackSegments',
    ELEMENT_DATA_MEDIA_OPTIONS_SEGMENTS: 'segments',
    ELEMENT_DATA_OBSERVER_OPTIONS: 'observerOptions',
    ELEMENT_DATA_OBSERVER_OPTIONS_CURRENT_CONDITIONS: 'currentConditions',
    ELEMENT_DATA_OBSERVER_OPTIONS_CONDITIONS: 'conditions',
    ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE: 'type',
    ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE_ELEMENT: 'element',
    ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE_ELEMENT_ID: 1,
    ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE_TEXT: 'text',
    ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE_TEXT_ID: 3,
    ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TARGET: 'target',
    ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_ATTRIBUTE: 'attribute',
    ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_SELECTOR: 'selector',
    ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_VALUE: 'value',
    ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST: 'childList',
    ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST_ADDED: 'added',
    ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST_REMOVED: 'removed',
    ELEMENT_DATA_OBSERVER_OPTIONS_ATTRIBUTES: 'attributes',
    ELEMENT_DATA_OBSERVER_OPTIONS_CHARACTER_DATA: 'characterData',
    ELEMENT_DATA_OBSERVER_OPTIONS_PROCESS_ON_LOAD: 'processOnLoad',
    ELEMENT_DATA_EVENT_TIMING_START: 'start',
    ELEMENT_DATA_EVENT_TIMING_END: 'end',
    ELEMENT_DATA_EVENT_TIMING_TYPE: 'type',
    ELEMENT_DATA_EVENT_TIMING_ELEMENT: 'element',
    ELEMENT_DATA_EVENT_TIMING_EVENT: 'event',
    ELEMENT_DATA_EVENT_TIMING_TYPE_GLOBAL: 'global',
    ELEMENT_DATA_EVENT_TIMING_TYPE_HTML: 'html',
    ELEMENT_DATA_EVENT_TIMING_DEFERRED_START_TIME: 'deferredStartTime',
    ELEMENT_DATA_EVENT_TIMING_LOAD_EVENT: 'load',
    ELEMENT_DATA_EVENT_TIMING_WINDOW_ELEMENT: 'window',
    ELEMENT_DATA_PROCESSING: 'processing',
    ELEMENT_TYPE_PAGE: 'page',
    ELEMENT_TYPE_CLICKABLE: 'clickable',
    ELEMENT_TYPE_SUBMITABLE: 'submitable',
    ELEMENT_TYPE_HTML: 'html',
    ELEMENT_TYPE_MULTI: 'multi',
    ELEMENT_TYPE_OBSERVABLE: 'observable',
    ELEMENT_TYPE_MEDIA: 'media',
    ELEMENT_TYPE_WINDOWEVENT: 'windowevent',
    ELEMENT_TYPE_EVENTTIMING: 'eventtiming',
    ELEMENT_TYPES_USING_REMOVABLE_EVENT_HANDLERS: ['clickable', 'submitable', 'media', 'windowevent'],
    PRE_SCAN_ELEMENT_TYPES: ['clickable', 'submitable', 'observable', 'windowevent', 'eventtiming'],
    UNSUPPORTED_OBSERVABLE_ELEMENT_TYPES: ['multi'],
    ANALYZE_PAGE_INPUT_PAGEVALUES: 'pageValues',
    ANALYZE_PAGE_INPUT_OPTIONS: 'options',
    OPTIONS_MULTIVAL_SEP_DEFAULT: '|',
    GLOBAL_VARIABLES_INDICATOR: '@',
    RESCAN_TIMEOUT_DEFAULT: 5000,
    RESCAN_INTERVAL: 500,
    WAIT_FOR_EXIST_TIMEOUT_DEFAULT: 2000,
    WAIT_FOR_EXIST_INTERVAL: 100,
    DEFERRED_CALL_STALE_TIME: 300000,
    LOAD_EVENT_PRE_SCAN_INTERVAL: 100,
    CHECK_INTERVAL: 100,
    LOGGER_LEVELS_ENUM: { NONE: 0, INFO: 1, WARN: 2, ERROR: 4, DEBUG: 8, TRACE: 16, NORMAL: 7, VERBOSE: 15, ALL: 31 },
    PACKAGE_NAME: 'wk.ual',
    EVENT_TYPE_PAGE: 'page',
    EVENT_TYPE_GENERAL: 'general',
    EVENT_LISTENER_TYPE_CLICK: 'click',
    EVENT_LISTENER_TYPE_SUBMIT: 'submit',
    EVENT_LISTENER_TYPE_PLAY: 'play',
    EVENT_LISTENER_TYPE_ENDED: 'ended',
    EVENT_LISTENER_TYPE_TIMEUPDATE: 'timeupdate',
    MEDIA_KEY_STRING_SUFFIX_PLAY: '_play',
    MEDIA_KEY_STRING_SUFFIX_ENDED: '_ended',
    MEDIA_KEY_STRING_SUFFIX_TIMEUPDATE: '_timeupdate',
    LOGGER_VAR: 'wk.ual.logger',
    MUTATION_RECORD_TYPE_CHILD_LIST: 'childList',
    MUTATION_RECORD_TYPE_ATTRIBUTES: 'attributes',
    MUTATION_RECORD_TYPE_CHARACTER_DATA: 'characterData',
    MUTATION_RECORD_TARGET_TAG_NAME: 'tagName',
    MUTATION_RECORD_TARGET_ID: 'id',
    MUTATION_RECORD_TARGET_CLASS_NAME: 'className',
    COUNTERS_GLOBAL_PROPERTY: 'counters',
    COUNTERS_OPTIONS_SELECTOR: 'selector',
    COUNTERS_OPTIONS_UPDATE: 'update',
    COUNTERS_OPTIONS_ACTION: 'action',
    COUNTERS_OPTIONS_INTERVAL: 'interval',
    COUNTERS_TYPE_ACTION: 'action',
    COUNTERS_TYPE_INTERVAL: 'interval',
    OBSERVABLE_ELEMENT_PROPERTY_CHECK_NAME: 'data-ual-observable',
    ELEMENT_HANDLER_PROPERTY_CHECK_NAME_PREFIX: 'data-ual-handler-',
    ADOBE_ANALYTICS_LAUNCH_PROPERTY: 'AdobeAnalyticsLaunch',
    ADOBE_ANALYTICS_LAUNCH_CONFIG_APP_MEASUREMENT_TRACKER: 'appMeasurementTracker',
    ADOBE_ANALYTICS_LAUNCH_CONFIG_TRACKING_OBJ: 'trackingObj',
    ADOBE_ANALYTICS_LAUNCH_CONFIG_DATA_OBJ: 'dataObj',
    ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS: 'globals',
    ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS_COMMON: 'common',
    ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS_PAGE: 'page',
    ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS_LINK: 'link',
    ADOBE_ANALYTICS_LAUNCH_CONFIG_EVENTS: 'events',
    ADOBE_ANALYTICS_LAUNCH_CONFIG_EVARS: 'eVars',
    ADOBE_ANALYTICS_LAUNCH_CONFIG_PROPS: 'props',
    ADOBE_ANALYTICS_LAUNCH_TRACKING_DATA_OBJ: 'data',
    ADOBE_ANALYTICS_LAUNCH_TRACKING_DATA_TRACKING_ID: 'id',
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_ID: 'common-ual-rule',
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_RUNNING: 'wk.ual.launch.common-ual-rule.running',
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_RETRY_TIME: 500,
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_LAUNCH_CONFIG: 'launchConfig',
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_BEACON: 'beacon',
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_PAGE_VIEW: 'pageView',
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_LINK_TYPE: 'linkType',
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_LINK_NAME: 'linkName',
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DATA: 'data',
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_SET: 'set',
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DATA_ELEMENT_INDICATOR: '%',
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DEFAULT_LINK_TYPE: 'o',
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DEFAULT_LINK_NAME: 'Other',
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DELIMETER: ',',
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_EVENT_DATA_INDICATOR: '=',
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_EVENTS: 'events',
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_LINKTRACKVARS: 'linkTrackVars',
    ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_LINKTRACKEVENTS: 'linkTrackEvents'
};
