import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import Logger from '../Logger';
import DynamicDataProcessor from '../DynamicDataProcessor';
import CounterManager from '../CounterManager';
import Utils from '../Utils';

/**
 * Base class for analytics elements that track data
 * @class
 */
class UsageElement {

    /**
     * @static
     * @member {AnalyticsLib}
     * @memberof UsageElement
     */
    static AnalyticsLib = null;

    /**
     * Constructor for UsageElement
     *
     * @constructor
     * @param {String} classname
     * @param {Object} elementConfig
     */
    constructor (classname, elementConfig) {
        this._classname = classname;

        if (this._classname === undefined) {
            this._classname = 'UsageElement';
        }

        if (this._classname === 'UsageElement') {
            throw new TypeError('Cannot instantiate base class UsageElement');
        }

        this._logger = Logger.getLogger();
        this._lib = null;
        this._config = elementConfig;
        this._logPrefix = null;
        this._observableElement = null;

        this._type = null;
        if (Constants.ELEMENT_DATA_TYPE in this._config) {
            this._type = this._config[Constants.ELEMENT_DATA_TYPE];
        }
    }

    /**
     * Function to hanldle the particular element. Must be implemented by derived class.
     *
     * @async
     * @param {ObservableElement} observableElement
     * @returns {Promise}
     */
    async handle (observableElement) {
        // When in production code, we will load AnalyticsLib here and get the lib instance after.
        // In unit tests, the test will set up the mock on its own, and needs to make sure it's
        // available to be set in this function call.
        if (!process.env.UNIT_TEST || (global && global.ANALYTICS_LIB_TEST)) {
            UsageElement.AnalyticsLib = (await import('../AnalyticsLib')).default;
        }
        if (UsageElement.AnalyticsLib) {
            this._lib = UsageElement.AnalyticsLib.getLib();
        }

        this._observableElement = observableElement;
    }

    /**
     * Process 'init' dynamic data for this element
     *
     * @private
     */
    _processInitDynamicData () {
        if (Constants.ELEMENT_DATA_DATA in this._config) {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.START_PROCESSING.format(Constants.DYNAMIC_DATA_TYPE_INIT));
            let processTypeArgs = {};
            processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES] = this._lib.getPageValues();
            DynamicDataProcessor.process(this._config[Constants.ELEMENT_DATA_DATA], Constants.DYNAMIC_DATA_TYPE_INIT, processTypeArgs);
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.FINISH_PROCESSING.format(Constants.DYNAMIC_DATA_TYPE_INIT));
        }
    }

    /**
     * If this UsageElement came from an Observable, get that element that is in the DOM
     *
     * @private
     * @returns {HTMLElement}
     */
    _getObservableElementInDOM () {
        if (this._observableElement._config && Constants.ELEMENT_DATA_SELECTOR in this._observableElement._config) {
            let el = document.querySelectorDeep(this._observableElement._config[Constants.ELEMENT_DATA_SELECTOR]);
            if (el) {
                return el;
            }
        }
    }

    /**
     * Does a querySelector call on document if there's no observalble associated with this elements. If there is
     * it does the querySelector from that element down.
     *
     * @private
     * @param {String} selector
     * @param {String} selectorRoot
     * @returns {Node}
     */
    _querySelector (selector, selectorRoot = null) {
        let root = document;
        if (selectorRoot) {
            if (window[selectorRoot]) {
                root = window[selectorRoot];
            } else {
                let rootFind = document.querySelectorDeep(selectorRoot);
                if (rootFind) {
                    root = rootFind;
                }
            }
        } else if (this._observableElement) {
            root = this._getObservableElementInDOM();
        }
        return root.querySelectorDeep(selector);
    }

    /**
     * Does a querySelectorAll call on document if there's no observalble associated with this elements. If there is
     * it does the querySelectorAll from that element down.
     *
     * @private
     * @param {String} selector
     * @param {String} selectorRoot
     * @returns {Node[]}
     */
    _querySelectorAll (selector, selectorRoot = null) {
        let root = document;
        if (selectorRoot) {
            if (window[selectorRoot]) {
                root = window[selectorRoot];
            } else {
                let rootFind = document.querySelectorDeep(selectorRoot);
                if (rootFind) {
                    root = rootFind;
                }
            }
        } else if (this._observableElement) {
            root = this._getObservableElementInDOM();
        }
        return root.querySelectorAllDeep(selector);
    }

    /**
     * A function get the HTML elements associated with a CSS selector. It can optionally
     * wait until it exists or we timeout.
     *
     * @private
     * @async
     * @param {string} selector
     * @param {boolean} waitForExist
     * @param {String} waitForSelector
     * @param {Number} waitForExistTimeout
     * @returns {Promise<HTMLElement[]>}
     */
    async _getHTMLElements (selector, waitForExist = false, waitForSelector = null, waitForExistTimeout = null) {
        let context = this;
        return new Promise(function (resolve /*, reject*/) {
            let timeout = waitForExistTimeout ? waitForExistTimeout : Constants.WAIT_FOR_EXIST_TIMEOUT_DEFAULT;
            let waitForExistSelector = waitForSelector;

            let intervalSelector = selector;
            if (waitForExistSelector) {
                context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.WAIT_FOR_EXIST_SELECTOR.format(waitForExistSelector));
                intervalSelector = waitForExistSelector;
            }

            let elements = [];
            let startTime = new Date().getTime();
            let interval = setInterval(function () {
                try {
                    elements = context._querySelectorAll(intervalSelector);
                } catch (e) {
                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.FAILED_TO_QUERY_SELECTOR.format(intervalSelector));
                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.ERROR, e.stack);
                    clearInterval(interval);
                    resolve(elements);
                }

                if (elements.length || !waitForExist) {
                    if (elements.length){
                        context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.FOUND_ELEMENTS.format(intervalSelector, elements.length));
                    } else {
                        context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.NOT_FOUND_WITH_SELECTOR.format(intervalSelector));
                    }

                    // If the wait for exists options has a selector and that's what we waited on,
                    // we need to get the actual elements now before returning.
                    if (waitForExist && waitForExistSelector) {
                        try {
                            elements = context._querySelectorAll(selector);
                        } catch (e) {
                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.FAILED_TO_QUERY_SELECTOR.format(selector));
                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.ERROR, e.stack);
                            elements = [];
                        }
                    }

                    clearInterval(interval);
                    resolve(elements);
                } else {
                    if (new Date().getTime() - startTime >= timeout) {
                        context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.TIMED_OUT_WAITING_FOR_SELECTOR.format(intervalSelector));
                        clearInterval(interval);
                        resolve(elements);
                    }
                }
            }, Constants.WAIT_FOR_EXIST_INTERVAL);
        });
    }

    /**
     * Function to copy the elements _config property for use when it will be modified and
     * the original should remain intact (i.e., in a click handler that may be used multiple times)
     *
     * @private
     * @returns {Object}
     */
    _copyConfig () {
        let copiedElement = null;
        try {
            copiedElement = Utils.JSONParse(Utils.JSONStringify(this._config));
        } catch (e) {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.FAILED_TO_COPY);
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.ERROR, e.stack);
        }
        return copiedElement;
    }

    /**
     * Checks preCondition scenario based on element's options. Defaults to true.
     *
     * @private
     * @returns {boolean}
     */
    _checkPreCondition () {
        let preConditionCheck = true;
        if (Constants.ELEMENT_DATA_PRE_CONDITIONS in this._config && this._config[Constants.ELEMENT_DATA_PRE_CONDITIONS] !== null) {
            let preConditions = this._config[Constants.ELEMENT_DATA_PRE_CONDITIONS];
            if (!Array.isArray(preConditions)) {
                preConditions = [preConditions];
            }

            if (preConditions.length) {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.PRE_CONDITIONAL_ELEMENT);

                preConditionCheck = this._checkConditions(preConditions);
                if (preConditionCheck) {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.PRE_CONDITION_TRUE);
                } else {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.PRE_CONDITION_FALSE);
                }
            }
        }

        return preConditionCheck;
    }

    /**
     * Checks conditional scenario based on element's options. Defaults to true.
     *
     * @private
     * @returns {boolean}
     */
    _checkCondition () {
        let conditionCheck = true;
        if (Constants.ELEMENT_DATA_CONDITIONS in this._config && this._config[Constants.ELEMENT_DATA_CONDITIONS] !== null) {
            let conditions = this._config[Constants.ELEMENT_DATA_CONDITIONS];
            if (!Array.isArray(conditions)) {
                conditions = [conditions];
            }

            if (conditions.length) {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.CONDITIONAL_ELEMENT);

                conditionCheck = this._checkConditions(conditions);
                if (conditionCheck) {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.CONDITION_TRUE);
                } else {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.CONDITION_FALSE);
                }
            }
        }

        return conditionCheck;
    }

    /**
     * Checks the input condition objects and returns true if all conditions are true
     *
     * @private
     * @param {Object[]} conditions
     * @returns {boolean}
     */
    _checkConditions (conditions) {
        let retCondition = true;
        for (let c = 0; (c < conditions.length) && retCondition; c++) {
            let thisCondition = conditions[c];
            let thisConditionCheck = true;
            if (Constants.ELEMENT_DATA_CONDITIONAL_OPTIONS_TYPE in thisCondition && thisCondition[Constants.ELEMENT_DATA_CONDITIONAL_OPTIONS_TYPE] !== null) {
                let conditionalType = thisCondition[Constants.ELEMENT_DATA_CONDITIONAL_OPTIONS_TYPE];
                if (conditionalType === Constants.ELEMENT_DATA_CONDITIONAL_OPTIONS_TYPE_CSS) {
                    thisConditionCheck = this._checkCSSCondition(thisCondition);
                } else if (conditionalType === Constants.ELEMENT_DATA_CONDITIONAL_OPTIONS_TYPE_PAGEVALUE) {
                    thisConditionCheck = this._checkPageValueCondition(thisCondition);
                } else {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.UNSUPPORTED_CONDITIONAL.format(conditionalType));
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.CONDITIONAL_DEFAULT_TRUE);
                }
            }
            retCondition = thisConditionCheck;
        }
        return retCondition;
    }

    /**
     * Checks a CSS selector condition according to input options. Defaults to true;
     *
     * @private
     * @param {ConditionalOptions} options
     * @returns {boolean}
     */
    _checkCSSCondition (options) {
        let retVal = true;

        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.CONDITIONAL_TYPE_IS.format(
            ConsoleStrings.Element.BaseElementClass.CONDITION_TYPE_CSS));

        let [condition, selector] = this._getConditionalOptions(options);
        if (condition && selector) {
            let elements = [];
            try {
                elements = this._querySelectorAll(selector);
            } catch (e) {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.FAILED_TO_QUERY_SELECTOR.format(selector));
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.ERROR, e.stack);
            }
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.FOUND_NUMBER_OF_ELEMENTS.format(elements.length));

            if (condition === Constants.ELEMENT_DATA_CONDITIONAL_OPTIONS_CONDITION_IF) {
                if (elements.length) {
                    retVal = true;
                } else {
                    retVal = false;
                }
            } else if (condition === Constants.ELEMENT_DATA_CONDITIONAL_OPTIONS_CONDITION_NOT) {
                if (elements.length) {
                    retVal = false;
                } else {
                    retVal = true;
                }
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.CSS_CONDITIONAL_REQUIRES);
        }

        return retVal;
    }

    /**
     * Checks a page value condition according to input options. Defaults to true;
     *
     * @private
     * @param {ConditionalOptions} options
     * @returns {boolean}
     */
    _checkPageValueCondition (options) {
        let retVal = true;

        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.CONDITIONAL_TYPE_IS.format(
            ConsoleStrings.Element.BaseElementClass.CONDITION_TYPE_PAGEVAL));

        let [condition, selector] = this._getConditionalOptions(options);
        if (condition && selector) {
            let pageValues = this._lib.getPageValues();
            if (selector in pageValues) {
                let pageValValue = pageValues[selector];
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.PAGE_VALUE_NAME.format(selector));
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.VALUE.format((pageValValue ? 'true' : 'false')));

                if (condition === Constants.ELEMENT_DATA_CONDITIONAL_OPTIONS_CONDITION_IF) {
                    retVal = pageValValue;
                } else if (condition === Constants.ELEMENT_DATA_CONDITIONAL_OPTIONS_CONDITION_NOT) {
                    retVal = !pageValValue;
                }
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.NOT_IN_PAGE_VALUES.format(selector));
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.PAGE_VALUE_CONDITIONAL_REQUIRES);
        }

        return retVal;
    }

    /**
     * Function to get the conditional options from the config
     *
     * @private
     * @param {ConditionalOptions} options
     * @returns {String[]}
     */
    _getConditionalOptions (options) {
        let condition = null;
        if (Constants.ELEMENT_DATA_CONDITIONAL_OPTIONS_CONDITION in options && options[Constants.ELEMENT_DATA_CONDITIONAL_OPTIONS_CONDITION] !== null) {
            condition = options[Constants.ELEMENT_DATA_CONDITIONAL_OPTIONS_CONDITION];
        }
        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.CONDITION.format(condition));

        let selector = null;
        if (Constants.ELEMENT_DATA_CONDITIONAL_OPTIONS_SELECTOR in options && options[Constants.ELEMENT_DATA_CONDITIONAL_OPTIONS_SELECTOR] !== null) {
            selector = options[Constants.ELEMENT_DATA_CONDITIONAL_OPTIONS_SELECTOR];
        }
        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.SELECTOR.format(selector));

        return [condition, selector];
    }

    /**
     * Check if the element is set up to perform a rescan and send back rescan options
     *
     * @private
     * @returns {RescanOptionsObj}
     */
    _checkIfRescan () {
        let isRescan = false;
        let rescanTimeout = Constants.RESCAN_TIMEOUT_DEFAULT;
        let rescanSelector = '';
        let rescanSelectorRoot = '';
        let rescanWait = 0;
        let rescanDeleteElement = false;

        let asyncOptions = null;
        if (Constants.ELEMENT_DATA_ASYNC in this._config) {
            asyncOptions = this._config[Constants.ELEMENT_DATA_ASYNC];
        }

        if (asyncOptions) {
            if (Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN in asyncOptions) {
                if (typeof asyncOptions[Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN] === Constants.JS_TYPE_OBJECT) {
                    isRescan = true;
                    const rescanOptions = asyncOptions[Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN];
                    if (Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_TIMEOUT in rescanOptions &&
                        typeof rescanOptions[Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_TIMEOUT] === Constants.JS_TYPE_NUMBER &&
                        (Number.isInteger(rescanOptions[Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_TIMEOUT]) ||
                        rescanOptions[Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_TIMEOUT] === Infinity)) {
                        rescanTimeout = rescanOptions[Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_TIMEOUT];
                    }
                    if (Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_SELECTOR in rescanOptions) {
                        rescanSelector = rescanOptions[Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_SELECTOR];
                    }
                    if (Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_SELECTOR_ROOT in rescanOptions) {
                        rescanSelectorRoot = rescanOptions[Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_SELECTOR_ROOT];
                    }
                    if (Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_WAIT in rescanOptions &&
                        typeof rescanOptions[Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_WAIT] === Constants.JS_TYPE_NUMBER &&
                        Number.isInteger(rescanOptions[Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_WAIT])) {
                        rescanWait = rescanOptions[Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_WAIT];
                    }
                    if (Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_DELETEELEMENT in rescanOptions) {
                        rescanDeleteElement = rescanOptions[Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN_OPTIONS_DELETEELEMENT];
                    }
                } else if (typeof asyncOptions[Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN] === Constants.JS_TYPE_BOOLEAN) {
                    isRescan = asyncOptions[Constants.ELEMENT_DATA_ASYNC_OPTION_RESCAN];
                }
            }
        }

        return {
            rescan: isRescan,
            timeout: rescanTimeout,
            selector: rescanSelector,
            selectorRoot: rescanSelectorRoot,
            wait: rescanWait,
            delete: rescanDeleteElement
        };
    }

    /**
     * Perform the rescan of the page
     *
     * @private
     * @param {RescanOptionsObj} rescanOptions
     */
    async _performRescan (rescanOptions) {
        const context = this;
        return new Promise(async function (resolve /*, reject*/) {
            const rescanSelector = rescanOptions.selector;
            const rescanSelectorRoot = rescanOptions.selectorRoot;
            const rescanTimeout = rescanOptions.timeout;
            const rescanWait = rescanOptions.wait;
            const rescanDeleteElement = rescanOptions.delete;

            context._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.BaseElementClass.ELEMENT_SET_TO_PERFORM_RESCAN);
            CounterManager.updateCounters();
            if (rescanSelector) {
                context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.LOOKING_FOR_RESCAN_SELECTOR.format(rescanSelector));
                const startTime = new Date().getTime();
                const interval = setInterval(async function () {
                    let rescanForElements = null;
                    try {
                        rescanForElements = context._querySelectorAll(rescanSelector, rescanSelectorRoot);
                    } catch (e) {
                        context._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.FAILED_TO_QUERY_SELECTOR.format(rescanSelector));
                        context._logMessage(Constants.LOGGER_LEVELS_ENUM.ERROR, e.stack);
                    }

                    if (rescanForElements.length > 0) {
                        clearInterval(interval);
                        context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.FOUND_RESCAN_SELECTOR_RESET_HANDLERS);
                        context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.FOUND_NUMBER_OF_ELEMENTS.format(rescanForElements.length));
                        context._lib.resetAllHandlers();

                        if (rescanDeleteElement) {
                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.DELETE_ELEMENT_TRUE);
                            rescanForElements[0].parentNode.removeChild(rescanForElements[0]);
                        }

                        context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.STARTING_RESCAN);
                        await context._lib.scanPage();
                        resolve();
                    } else {
                        if (new Date().getTime() - startTime >= rescanTimeout) {
                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.TIMED_OUT_WAITING_FOR_SELECTOR.format(rescanSelector));
                            clearInterval(interval);
                            resolve();
                        }
                    }
                }, Constants.RESCAN_INTERVAL);
            } else if (rescanWait) {
                setTimeout(async function () {
                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.TIME_WAITED_RESETTING);
                    context._lib.resetAllHandlers();
                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.STARTING_RESCAN);
                    await context._lib.scanPage();
                    resolve();
                }, rescanWait);
            } else {
                context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.BaseElementClass.RESCAN_REQUIRES);
                resolve();
            }
        });
    }

    /**
     * Logs a message for this element
     *
     * @private
     * @param {Number} msgType
     * @param {string} msg
     */
    _logMessage (msgType, msg) {
        if (this._logPrefix === null) {
            this._logPrefix = this._lib.getLoggingElementPrefix(this._config);
        }

        switch (msgType) {
            case Constants.LOGGER_LEVELS_ENUM.INFO:
                this._logger.info(this._logPrefix + ' ' + msg);
                break;

            case Constants.LOGGER_LEVELS_ENUM.WARN:
                this._logger.warn(this._logPrefix + ' ' + msg);
                break;

            case Constants.LOGGER_LEVELS_ENUM.ERROR:
                this._logger.error(this._logPrefix + ' ' + msg);
                break;

            case Constants.LOGGER_LEVELS_ENUM.DEBUG:
                this._logger.debug(this._logPrefix + ' ' + msg);
                break;

            case Constants.LOGGER_LEVELS_ENUM.TRACE:
                this._logger.trace(this._logPrefix + ' ' + msg);
                break;

            default:
                break;
        }
    }

    /**
     * Function to get the "waitForExist" config options.
     *
     * @private
     * @returns {Array}
     */
    _getWaitForExistConfig () {
        let waitForExist = false;
        let waitForExistOptions = null;
        let waitForSelector = null;
        let waitForExistTimeout = null;
        if (Constants.ELEMENT_DATA_WAIT_FOR_EXIST in this._config) {
            if (typeof this._config[Constants.ELEMENT_DATA_WAIT_FOR_EXIST] === Constants.JS_TYPE_OBJECT) {
                waitForExist = true;
                waitForExistOptions = this._config[Constants.ELEMENT_DATA_WAIT_FOR_EXIST];
                if (Constants.ELEMENT_DATA_WAIT_FOR_EXIST_OPTION_SELECTOR in waitForExistOptions) {
                    waitForSelector = waitForExistOptions[Constants.ELEMENT_DATA_WAIT_FOR_EXIST_OPTION_SELECTOR];
                }
                if (Constants.ELEMENT_DATA_WAIT_FOR_EXIST_OPTION_TIMEOUT in waitForExistOptions) {
                    waitForExistTimeout = waitForExistOptions[Constants.ELEMENT_DATA_WAIT_FOR_EXIST_OPTION_TIMEOUT];
                }
            } else if (typeof this._config[Constants.ELEMENT_DATA_WAIT_FOR_EXIST] === Constants.JS_TYPE_BOOLEAN) {
                waitForExist = this._config[Constants.ELEMENT_DATA_WAIT_FOR_EXIST];
            }
        }

        return [waitForExist, waitForSelector, waitForExistTimeout];
    }
}

export default UsageElement; // JSDoc workaround for documenting classes
