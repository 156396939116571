import { Constants } from './Constants';
import { ConsoleStrings } from './ConsoleStrings';
import Logger from './Logger';
import StorageUtils from './StorageUtils';
import Utils from './Utils';
import MultiElement from './UsageElementTypes/MultiElement';
import EventTimingElement from './UsageElementTypes/EventTimingElement';
import ClickableElement from './UsageElementTypes/ClickableElement';
import SubmitableElement from './UsageElementTypes/SubmitableElement';

/**
 * Class to deal with the deferral setup and processing
 */
class DeferralHelper {

    /**
     * A function that sets up a deferred call by adding element data to a
     * sessionStorage variable to save across page loads.
     *
     * @static
     * @param {AnalyticsElement} elementData
     * @returns {void}
     */
    static setupDeferredCall (elementData) {
        let logger = Logger.getLogger();
        const storageObj = StorageUtils.getSessionStorageObject();
        if (storageObj) {
            logger.trace(ConsoleStrings.AnalyticsLib.Deferred.SETTING_UP.format(Utils.JSONStringify(elementData)));

            let deferredTrackingCallsObj = this._getDeferredCallsObject();
            if (deferredTrackingCallsObj) {
                const deferredElementData = elementData;

                let originalHash = null;
                if (!this.isElementProcessingDeferral(deferredElementData)) {
                    // set 'processing' to true and update hashID before setting deferral if not already processing
                    deferredElementData[Constants.ELEMENT_DATA_PROCESSING] = true;
                    deferredElementData[Constants.ELEMENT_DATA_HASH_ID] = Utils.JSONStringify(deferredElementData).hashCode();
                    deferredElementData[Constants.ELEMENT_DATA_DEFERRED_TIMESTAMP] = Date.now();
                } else {
                    // if already processing, check if element has changed and retain timestamp and hash if it hasn't, updated timestamp and hash if it has
                    originalHash = deferredElementData[Constants.ELEMENT_DATA_HASH_ID];
                    delete deferredElementData[Constants.ELEMENT_DATA_HASH_ID];

                    let originalTimestamp = deferredElementData[Constants.ELEMENT_DATA_DEFERRED_TIMESTAMP];
                    delete deferredElementData[Constants.ELEMENT_DATA_DEFERRED_TIMESTAMP];

                    if (Utils.JSONStringify(deferredElementData).hashCode() === originalHash) {
                        deferredElementData[Constants.ELEMENT_DATA_HASH_ID] = originalHash;
                        deferredElementData[Constants.ELEMENT_DATA_DEFERRED_TIMESTAMP] = originalTimestamp;
                    } else {
                        deferredElementData[Constants.ELEMENT_DATA_HASH_ID] = Utils.JSONStringify(deferredElementData).hashCode();
                        deferredElementData[Constants.ELEMENT_DATA_DEFERRED_TIMESTAMP] = Date.now();
                    }
                }

                this._filterDeferredObject(deferredTrackingCallsObj, originalHash);

                deferredTrackingCallsObj[Constants.DEFERRED_TRACKING_OBJ_CALLS].push(deferredElementData);
                const storageStringSet = Utils.JSONStringify(deferredTrackingCallsObj);
                storageObj.setItem(Constants.SESSION_STORAGE_DEFERRED_CALLS, storageStringSet);
                logger.trace(ConsoleStrings.AnalyticsLib.Deferred.DEFERRED_DATA.format(storageStringSet));
            }
        } else {
            logger.warn(ConsoleStrings.AnalyticsLib.Deferred.NO_SUPPORTED);
        }
    }

    /**
     * Function to filter the deferred element object with elements that shouldn't be
     * there anymore. Ones that duplicate our current one or ones that are stale get removed.
     *
     * @private
     * @static
     * @param {Object} deferredTrackingCallsObj
     * @param {String} hashID
     */
    static _filterDeferredObject (deferredTrackingCallsObj, hashID) {
        // Filter out calls we don't want:
        //   1. any deferred calls with the current hashID so we don't have duplicates
        //   2. any deferred calls older than DEFERRED_CALL_STALE_TIME
        deferredTrackingCallsObj[Constants.DEFERRED_TRACKING_OBJ_CALLS] = deferredTrackingCallsObj[Constants.DEFERRED_TRACKING_OBJ_CALLS].filter(function (call) {
            let retVal = true;

            // 1.
            if (typeof hashID !== Constants.JS_TYPE_UNDEFINED && hashID !== null) {
                if (Constants.ELEMENT_DATA_HASH_ID in call && call[Constants.ELEMENT_DATA_HASH_ID] === hashID) {
                    retVal = false;
                }
            }

            // 2.
            if (Constants.ELEMENT_DATA_DEFERRED_TIMESTAMP in call) {
                const timeDiff = Date.now() - call[Constants.ELEMENT_DATA_DEFERRED_TIMESTAMP];
                if (timeDiff >= Constants.DEFERRED_CALL_STALE_TIME) {
                    retVal = false;
                }
            }

            return retVal;
        });
    }

    /**
     * Function to get the deferred calls object from session storage
     *
     * @static
     * @private
     * @returns {DeferredCallsObject}
     */
    static _getDeferredCallsObject () {
        let logger = Logger.getLogger();
        const storageObj = StorageUtils.getSessionStorageObject();
        if (storageObj) {
            let storageStringGet = null;
            try {
                storageStringGet = storageObj.getItem(Constants.SESSION_STORAGE_DEFERRED_CALLS);
            } catch (e) {
                logger.warn(ConsoleStrings.Storage.CAUGHT_EXCEPTION);
                logger.error(e.stack);
            }

            let newTrackingCallsObj = {};
            newTrackingCallsObj[Constants.DEFERRED_TRACKING_OBJ_CALLS] = [];
            if (storageStringGet && storageStringGet !== '') {
                let deferredTrackingCallsObj = null;
                try {
                    deferredTrackingCallsObj = Utils.JSONParse(storageStringGet);
                } catch (e) {
                    logger.warn(ConsoleStrings.Storage.PARSE_ERROR);
                    logger.error(e.stack);
                    storageObj.removeItem(Constants.SESSION_STORAGE_DEFERRED_CALLS);
                }
                if (deferredTrackingCallsObj && Constants.DEFERRED_TRACKING_OBJ_CALLS in deferredTrackingCallsObj &&
                    Array.isArray(deferredTrackingCallsObj[Constants.DEFERRED_TRACKING_OBJ_CALLS])) {
                    return deferredTrackingCallsObj;
                } else {
                    return newTrackingCallsObj;
                }
            } else {
                return newTrackingCallsObj;
            }
        } else {
            logger.warn(ConsoleStrings.AnalyticsLib.Deferred.NO_SUPPORTED);
            return null;
        }
    }

    /**
     * Function to get the deferred calls array
     *
     * @static
     * @private
     * @returns {AnalyticsElement[]}
     */
    static getDeferredCallsArray () {
        let deferredTrackingCallsObj = this._getDeferredCallsObject();
        if (deferredTrackingCallsObj !== null && Constants.DEFERRED_TRACKING_OBJ_CALLS in deferredTrackingCallsObj &&
            Array.isArray(deferredTrackingCallsObj[Constants.DEFERRED_TRACKING_OBJ_CALLS])) {
            return deferredTrackingCallsObj[Constants.DEFERRED_TRACKING_OBJ_CALLS];
        } else {
            return [];
        }
    }

    /**
     * Handle pre-scan elements in the deferred list so that clickable items get handlers attached
     * as soon as possible.
     *
     * @static
     * @async
     * @returns {Promise}
     */
    static async preProcessDeferredCalls () {
        let logger = Logger.getLogger();
        const storageObj = StorageUtils.getSessionStorageObject();
        if (storageObj) {
            let storageStringGet = null;
            try {
                storageStringGet = storageObj.getItem(Constants.SESSION_STORAGE_DEFERRED_CALLS);
            } catch (e) {
                logger.warn(ConsoleStrings.Storage.CAUGHT_EXCEPTION);
                logger.error(e.stack);
            }
            if (storageStringGet && storageStringGet !== '') {
                logger.debug(ConsoleStrings.AnalyticsLib.Deferred.PRE_PROCESSING_CALLS);
                logger.debug(ConsoleStrings.AnalyticsLib.Deferred.DEFERRED_DATA.format(storageStringGet));

                let deferredTrackingCallsObj = null;
                try {
                    deferredTrackingCallsObj = Utils.JSONParse(storageStringGet);
                } catch (e) {
                    logger.warn(ConsoleStrings.Storage.PARSE_ERROR);
                    logger.error(e.stack);
                    storageObj.removeItem(Constants.SESSION_STORAGE_DEFERRED_CALLS);
                }

                if (deferredTrackingCallsObj !== null
                    && Constants.DEFERRED_TRACKING_OBJ_CALLS in deferredTrackingCallsObj
                    && Array.isArray(deferredTrackingCallsObj[Constants.DEFERRED_TRACKING_OBJ_CALLS])) {
                    this._filterDeferredObject(deferredTrackingCallsObj);

                    for (let d = 0; d < deferredTrackingCallsObj[Constants.DEFERRED_TRACKING_OBJ_CALLS].length; d++) {
                        let deferredElement = deferredTrackingCallsObj[Constants.DEFERRED_TRACKING_OBJ_CALLS][d];

                        let processing = false;
                        if (Constants.ELEMENT_DATA_PROCESSING in deferredElement && deferredElement[Constants.ELEMENT_DATA_PROCESSING] !== null) {
                            processing = deferredElement[Constants.ELEMENT_DATA_PROCESSING];
                        }

                        let type = null;
                        if (Constants.ELEMENT_DATA_TYPE in deferredElement && deferredElement[Constants.ELEMENT_DATA_TYPE] !== null) {
                            type = deferredElement[Constants.ELEMENT_DATA_TYPE];
                            logger.trace(ConsoleStrings.AnalyticsLib.Deferred.CALL_TYPE.format((d+1), type));

                            let e = null;
                            if (processing && type === Constants.ELEMENT_TYPE_MULTI) {
                                if (Constants.ELEMENT_DATA_SELECTOR in deferredElement && deferredElement[Constants.ELEMENT_DATA_SELECTOR] !== null) {
                                    let multiList = deferredElement[Constants.ELEMENT_DATA_SELECTOR];
                                    if (Array.isArray(multiList) && multiList.length) {
                                        let curMultiElement = multiList[0];
                                        if (Constants.ELEMENT_DATA_TYPE in curMultiElement && curMultiElement[Constants.ELEMENT_DATA_TYPE] !== null) {
                                            let curMultiElementType = curMultiElement[Constants.ELEMENT_DATA_TYPE];

                                            // Only handle here if current item in multi list is in list of types to pre-scan
                                            if (Constants.PRE_SCAN_ELEMENT_TYPES.indexOf(curMultiElementType) !== -1) {
                                                e = new MultiElement(deferredElement);
                                            }
                                        }
                                    }
                                }
                            } else if (processing && type === Constants.ELEMENT_TYPE_EVENTTIMING) {
                                e = new EventTimingElement(deferredElement);
                            } else {
                                logger.trace(ConsoleStrings.AnalyticsLib.PreScanPage.NO_PRE_PROCESS);
                            }

                            if (e) {
                                try {
                                    await e.handle();
                                } catch (e) {
                                    logger.warn(ConsoleStrings.AnalyticsLib.Deferred.EXCEPTION_CAUGHT);
                                    logger.error(e.stack);
                                }
                            }
                        } else {
                            logger.trace(ConsoleStrings.AnalyticsLib.Deferred.NO_TYPE);
                        }
                    }
                }
                logger.debug(ConsoleStrings.AnalyticsLib.Deferred.FINISHED_PREPROCESSING);
            } else {
                logger.trace(ConsoleStrings.AnalyticsLib.Deferred.NOTHING_TO_PRE_PROCESS);
            }
        } else {
            logger.trace(ConsoleStrings.AnalyticsLib.Deferred.NO_STORAGE_AVAILABLE_PRE_PROCESS);
        }
    }

    /**
     * A function that processes deferred calls in the sessionStorage variable
     * used to save calls across page loads.
     *
     * @static
     * @async
     * @returns {Promise}
     */
    static async processDeferredCalls () {
        let logger = Logger.getLogger();
        let storageObj = StorageUtils.getSessionStorageObject();
        if (storageObj) {
            let storageStringGet = null;
            try {
                storageStringGet = storageObj.getItem(Constants.SESSION_STORAGE_DEFERRED_CALLS);
            } catch (e) {
                logger.warn(ConsoleStrings.Storage.CAUGHT_EXCEPTION);
                logger.error(e.stack);
            }
            if (storageStringGet && storageStringGet !== '') {
                logger.debug(ConsoleStrings.AnalyticsLib.Deferred.PROCESSING);
                logger.debug(ConsoleStrings.AnalyticsLib.Deferred.DEFERRED_DATA.format(storageStringGet));

                let deferredTrackingCallsObj = null;
                try {
                    deferredTrackingCallsObj = Utils.JSONParse(storageStringGet);
                } catch (e) {
                    logger.warn(ConsoleStrings.Storage.PARSE_ERROR);
                    logger.error(e.stack);
                    storageObj.removeItem(Constants.SESSION_STORAGE_DEFERRED_CALLS);
                }

                // Remove now so elements can add during handle call if needed
                try {
                    storageObj.removeItem(Constants.SESSION_STORAGE_DEFERRED_CALLS);
                } catch (e) {
                    logger.warn(ConsoleStrings.Storage.CAUGHT_EXCEPTION);
                    logger.error(e.stack);
                }

                if (deferredTrackingCallsObj !== null
                    && Constants.DEFERRED_TRACKING_OBJ_CALLS in deferredTrackingCallsObj
                    && Array.isArray(deferredTrackingCallsObj[Constants.DEFERRED_TRACKING_OBJ_CALLS])) {
                    this._filterDeferredObject(deferredTrackingCallsObj);

                    for (let d = 0; d < deferredTrackingCallsObj[Constants.DEFERRED_TRACKING_OBJ_CALLS].length; d++) {
                        let deferredElement = deferredTrackingCallsObj[Constants.DEFERRED_TRACKING_OBJ_CALLS][d];

                        let processing = this.isElementProcessingDeferral(deferredElement);
                        let type = null;
                        if (Constants.ELEMENT_DATA_TYPE in deferredElement && deferredElement[Constants.ELEMENT_DATA_TYPE] !== null) {
                            type = deferredElement[Constants.ELEMENT_DATA_TYPE];
                            logger.trace(ConsoleStrings.AnalyticsLib.Deferred.CALL_TYPE.format((d+1), type));

                            let e = null;
                            if (processing) {
                                if (type === Constants.ELEMENT_TYPE_MULTI) {
                                    e = new MultiElement(deferredElement);
                                } else if (type === Constants.ELEMENT_TYPE_CLICKABLE) {
                                    e = new ClickableElement(deferredElement);
                                } else if (type === Constants.ELEMENT_TYPE_SUBMITABLE) {
                                    e = new SubmitableElement(deferredElement);
                                }
                            }

                            if (e) {
                                try {
                                    await e.handle();
                                } catch (e) {
                                    logger.warn(ConsoleStrings.AnalyticsLib.Deferred.EXCEPTION_CAUGHT);
                                    logger.error(e.stack);
                                }
                            }
                        } else {
                            logger.trace(ConsoleStrings.AnalyticsLib.Deferred.NO_TYPE);
                        }
                    }
                }
                logger.debug(ConsoleStrings.AnalyticsLib.Deferred.FINISHED_PROCESSING);
            } else {
                logger.trace(ConsoleStrings.AnalyticsLib.Deferred.NOTHING_TO_PROCESS);
            }
        } else {
            logger.trace(ConsoleStrings.AnalyticsLib.Deferred.NO_STORAGE_AVAILABLE_PROCESS);
        }
    }

    /**
     * Function to check if an element is deferred
     *
     * @static
     * @param {AnalyticsElement} element
     * @returns {Boolean}
     */
    static isElementDeferred (element) {
        let deferred = false;
        if (Constants.ELEMENT_DATA_DEFER in element && element[Constants.ELEMENT_DATA_DEFER] !== null) {
            deferred = element[Constants.ELEMENT_DATA_DEFER];
        }
        return deferred;
    }

    /**
     * Function to check if an element is deferred and processing the deferral
     *
     * @static
     * @param {AnalyticsElement} element
     * @returns {Boolean}
     */
    static isElementProcessingDeferral (element) {
        let processing = false;
        if (this.isElementDeferred(element)) {
            if (Constants.ELEMENT_DATA_PROCESSING in element && element[Constants.ELEMENT_DATA_PROCESSING] !== null) {
                processing = element[Constants.ELEMENT_DATA_PROCESSING];
            }
        }
        return processing;
    }
}

export default DeferralHelper; // JSDoc workaround for documenting classes
