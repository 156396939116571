import { Constants } from './Constants';

/**
 * Utils class for common function needed for dynamic data processing
 */
class DynamicDataUtils {

    /**
     * Method to check if object is a DynamicDataObject.
     *
     * @static
     * @param {object} obj
     * @returns {Boolean}
     */
    static isDynamicDataObject (obj) {
        let defKeys = Object.keys(Constants.DDOBJ_DEFINITION).sort();
        let objKeys = Object.keys(obj).sort();

        let isDD = true;
        if (objKeys.join() !== defKeys.join()) {
            isDD = false;
        }

        for (let k = 0; isDD && k < defKeys.length && k < objKeys.length; k++) {
            isDD = (typeof Constants.DDOBJ_DEFINITION[defKeys[k]] === typeof obj[objKeys[k]]);
        }

        return isDD;
    }
}

export default DynamicDataUtils; // JSDoc workaround for documenting classes