import { Constants } from './Constants';
import Utils from './Utils';

/**
 * Class that simulates sessionStorage object for situations when it's not available
 */
export class SessionStorageHelper {

    constructor () {
        this.data = this._getData();
        this.length = 0;
    }

    /**
     * Function to clear storage data
     */
    clear () {
        this.data = {};
        this._clearData();
        this.length = this._numKeys();
    }

    /**
     * Function to get an item from the storage data
     * @param {Sring} key
     * @returns {String}
     */
    getItem (key) {
        this.data = this._getData();
        key = encodeURIComponent(key);
        return this.data[key] === undefined ? null : this.data[key];
    }

    /**
     * Function to get a key
     * @param {String} i
     */
    key (i) {
        var ctr = 0;
        for (var k in this.data) {
            if (ctr === i) {
                return decodeURIComponent(k);
            } else {
                ctr++;
            }
        }
        return null;
    }

    /**
     * Function to remove an item from storage data
     * @param {String} key
     */
    removeItem (key) {
        key = encodeURIComponent(key);
        delete this.data[key];
        this._setData(this.data);
        this.length = this._numKeys();
    }

    /**
     * Set an item with key to a value
     * @param {String} key
     * @param {String|Number} value
     */
    setItem (key, value) {
        key = encodeURIComponent(key);
        this.data[key] = String(value);
        this._setData(this.data);
        this.length = this._numKeys();
    }

    /**
     * Sets data
     * @private
     * @param {Object} data
     */
    _setData(data) {
        try {
            data = Utils.JSONStringify(data);
            window.name = data;
        } catch (e) {
            window.name = '';
        }
    }

    /**
     * Clears data
     * @private
     */
    _clearData() {
        window.name = '';
    }

    /**
     * Gets data
     * @private
     * @returns {Object}
     */
    _getData() {
        var data = window.name;
        var retVal = {};
        try {
            if (data) {
                retVal = Utils.JSONParse(data);
            }
        } catch (e) {}
        return retVal;
    }

    /**
     * Gets number of keys
     * @private
     * @returns {Number}
     */
    _numKeys() {
        var n = 0;
        for (var k in this.data) {
            if (this.data.hasOwnProperty(k)) {
                n += 1;
            }
        }
        return n;
    }
}

/**
 * Utils class for storage methods
 *
 * @class
 */
class StorageUtils {

    /**
     * Static method to return an object that implements the Storage interface.
     * Usually it will be the window.sessionStorage object, but in certain cases
     * that will not be available so we set up a helper object to simulates the
     * sessionStorage behavior.
     *
     * @static
     * @method
     * @returns {Storage|SessionStorageHelper|null}
     */
    static getSessionStorageObject () {
        try {
            if (typeof Storage !== Constants.JS_TYPE_UNDEFINED && typeof window.sessionStorage !== Constants.JS_TYPE_UNDEFINED && window.sessionStorage) {
                return window.sessionStorage;
            }
        } catch (e) {}

        return new SessionStorageHelper();
    }
}

export default StorageUtils; // JSDoc workaround for documenting classes