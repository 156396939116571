import configurator from 'core-js/configurator';
configurator({
    useNative: ['Promise']
});

import 'core-js/stable/array';
import 'core-js/stable/object';
import 'core-js/stable/promise';
import 'core-js/stable/dom-collections';
import 'core-js/stable/function';
import 'core-js/stable/number';
import 'core-js/stable/string';
//import 'core-js/stable/regexp'; // This polyfill was causing an issue with String.split(RegExp) where it would split every character, so always use native

import AnalyticsLib from './AnalyticsLib';
import ConsoleTestService from './AnalyticsServices/ConsoleService';
import AdobeAnalyticsLaunch from './AnalyticsServices/AdobeAnalytics';

// Expose the analytics services that are built in to the library
window.ConsoleTestService = ConsoleTestService;
window.AdobeAnalyticsLaunch = AdobeAnalyticsLaunch;

// Expose the instance of the AnalyticsLib externally through the window object
// so users can access it on their page.
window._wkUsageAnalyticsLib = new AnalyticsLib();
