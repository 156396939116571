import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import UsageElement from './UsageElement';
import DynamicDataProcessor from '../DynamicDataProcessor';
import Utils from '../Utils';
/**
 * Class for tracking media elements
 * @extends UsageElement
 */
class MediaElement extends UsageElement {

    /**
     * Constructor for MediaElement
     * @param {Object} elementConfig
     */
    constructor (elementConfig) {
        super('MediaElement', elementConfig);
    }

    /**
     * Function to hanldle the media element
     *
     * @async
     * @param {ObservableElement} observableElement
     * @returns {Promise}
     */
    async handle (observableElement) {
        await super.handle(observableElement);

        if (!this._checkPreCondition()) {
            return;
        }

        const context = this;

        if (Constants.ELEMENT_DATA_SELECTOR in this._config && this._config[Constants.ELEMENT_DATA_SELECTOR] !== null) {
            let trackStart = false;
            let trackFinish = false;
            let trackSegments = false;
            let numSegments = 0;

            let mediaOptions = null;
            if (Constants.ELEMENT_DATA_MEDIA_OPTIONS in this._config) {
                mediaOptions = this._config[Constants.ELEMENT_DATA_MEDIA_OPTIONS];

                if (Constants.ELEMENT_DATA_MEDIA_OPTIONS_TRACK_START in mediaOptions && mediaOptions[Constants.ELEMENT_DATA_MEDIA_OPTIONS_TRACK_START] !== null) {
                    trackStart = mediaOptions[Constants.ELEMENT_DATA_MEDIA_OPTIONS_TRACK_START];
                }
                if (Constants.ELEMENT_DATA_MEDIA_OPTIONS_TRACK_FINISH in mediaOptions && mediaOptions[Constants.ELEMENT_DATA_MEDIA_OPTIONS_TRACK_FINISH] !== null) {
                    trackFinish = mediaOptions[Constants.ELEMENT_DATA_MEDIA_OPTIONS_TRACK_FINISH];
                }
                if (Constants.ELEMENT_DATA_MEDIA_OPTIONS_TRACK_SEGMENTS in mediaOptions && mediaOptions[Constants.ELEMENT_DATA_MEDIA_OPTIONS_TRACK_SEGMENTS] !== null) {
                    trackSegments = mediaOptions[Constants.ELEMENT_DATA_MEDIA_OPTIONS_TRACK_SEGMENTS];
                }
                if (Constants.ELEMENT_DATA_MEDIA_OPTIONS_SEGMENTS in mediaOptions && mediaOptions[Constants.ELEMENT_DATA_MEDIA_OPTIONS_SEGMENTS] !== null) {
                    numSegments = mediaOptions[Constants.ELEMENT_DATA_MEDIA_OPTIONS_SEGMENTS];
                }
            }

            const keyStr = this._lib.getElementKeyString(this._config);
            const playKeyStr = keyStr + Constants.MEDIA_KEY_STRING_SUFFIX_PLAY;
            const endedKeyStr = keyStr + Constants.MEDIA_KEY_STRING_SUFFIX_ENDED;
            const timeupdateKeyStr = keyStr + Constants.MEDIA_KEY_STRING_SUFFIX_TIMEUPDATE;

            const elements = await this._getHTMLElements(this._config[Constants.ELEMENT_DATA_SELECTOR]);

            if (elements.length) {
                this._processInitDynamicData();

                let pageElementStates = {};
                for (let x = 0; x < elements.length; x++) {
                    const thisMediaElementId = keyStr + '_' + x;
                    const thisMediaElement = elements[x];
                    pageElementStates[thisMediaElementId] = { trackedStart: false, trackedFinish: false, milestoneTimes: [], currentSegment: 0 };

                    let thisDuration = 0;
                    if (Constants.HTMLMEDIAELEMENT_PROPERTY_DURATION in thisMediaElement && thisMediaElement[Constants.HTMLMEDIAELEMENT_PROPERTY_DURATION] !== null) {
                        thisDuration = thisMediaElement[Constants.HTMLMEDIAELEMENT_PROPERTY_DURATION];
                    }

                    if (trackStart) {
                        const playEventHandler = function (event) {
                            if (!pageElementStates[thisMediaElementId].trackedStart) {
                                pageElementStates[thisMediaElementId].trackedStart = true;

                                const copiedElement = context._copyConfig();
                                let copiedStartData = null;
                                if (Constants.ELEMENT_DATA_DATA in copiedElement) {
                                    if (Constants.ELEMENT_DATA_START_DATA in copiedElement[Constants.ELEMENT_DATA_DATA]) {
                                        copiedStartData = copiedElement[Constants.ELEMENT_DATA_DATA][Constants.ELEMENT_DATA_START_DATA];
                                    }
                                }

                                if (copiedStartData) {
                                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Media.PLAYBACK_STARTED);

                                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.START_PROCESSING.format(
                                        Constants.DYNAMIC_DATA_TYPE_MEDIA));
                                    let processTypeArgs = {};
                                    processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES] = context._lib.getPageValues();
                                    processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_EVENT] = event;
                                    DynamicDataProcessor.process(copiedStartData, Constants.DYNAMIC_DATA_TYPE_MEDIA, processTypeArgs);
                                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.FINISH_PROCESSING.format(
                                        Constants.DYNAMIC_DATA_TYPE_MEDIA));

                                    context._trackData(copiedStartData);
                                } else {
                                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Media.START_DATA_NOT_CONFIGURED);
                                }
                            }
                        };

                        if (this._lib.getHandlers(playKeyStr).length < elements.length) {
                            this._lib.addHandler(Constants.EVENT_LISTENER_TYPE_PLAY, playKeyStr, thisMediaElement, playEventHandler);
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.ADDED_HANDLER.format(
                                Constants.EVENT_LISTENER_TYPE_PLAY, ConsoleStrings.Element.TYPE_MEDIA, this._config[Constants.ELEMENT_DATA_SELECTOR]));
                        } else {
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.HANDLERS_ALREADY_ADDED.format(Constants.EVENT_LISTENER_TYPE_PLAY));
                        }
                    }

                    if (trackFinish) {
                        const endedEventHandler = function (event) {
                            if (!pageElementStates[thisMediaElementId].trackedFinish) {
                                pageElementStates[thisMediaElementId].trackedFinish = true;

                                const copiedElement = context._copyConfig();
                                let copiedFinishData = null;
                                if (Constants.ELEMENT_DATA_DATA in copiedElement) {
                                    if (Constants.ELEMENT_DATA_FINISH_DATA in copiedElement[Constants.ELEMENT_DATA_DATA]) {
                                        copiedFinishData = copiedElement[Constants.ELEMENT_DATA_DATA][Constants.ELEMENT_DATA_FINISH_DATA];
                                    }
                                }

                                if (copiedFinishData) {
                                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Media.PLAYBACK_FINISHED);

                                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.START_PROCESSING.format(
                                        Constants.DYNAMIC_DATA_TYPE_MEDIA));
                                    let processTypeArgs = {};
                                    processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES] = context._lib.getPageValues();
                                    processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_EVENT] = event;
                                    DynamicDataProcessor.process(copiedFinishData, Constants.DYNAMIC_DATA_TYPE_MEDIA, processTypeArgs);
                                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.FINISH_PROCESSING.format(
                                        Constants.DYNAMIC_DATA_TYPE_MEDIA));

                                    context._trackData(copiedFinishData);
                                } else {
                                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Media.FINISH_DATA_NOT_CONFIGURED);
                                }
                            }
                        };

                        if (this._lib.getHandlers(endedKeyStr).length < elements.length) {
                            this._lib.addHandler(Constants.EVENT_LISTENER_TYPE_ENDED, endedKeyStr, thisMediaElement, endedEventHandler);
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.ADDED_HANDLER.format(
                                Constants.EVENT_LISTENER_TYPE_ENDED, ConsoleStrings.Element.TYPE_MEDIA, this._config[Constants.ELEMENT_DATA_SELECTOR]));
                        } else {
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.HANDLERS_ALREADY_ADDED.format(Constants.EVENT_LISTENER_TYPE_ENDED));
                        }
                    }

                    if (trackSegments && (typeof numSegments === Constants.JS_TYPE_NUMBER) && (numSegments > 1)) {
                        for (let s = 0; s < numSegments-1; s++) {
                            pageElementStates[thisMediaElementId].milestoneTimes[s] = thisDuration * ((s+1)/numSegments);
                        }

                        const timeUpdateHandler = function (event) {
                            if (Constants.HTMLMEDIAELEMENT_PROPERTY_CURRENTTIME in thisMediaElement && thisMediaElement[Constants.HTMLMEDIAELEMENT_PROPERTY_CURRENTTIME] !== null) {
                                for (let c = pageElementStates[thisMediaElementId].currentSegment; c < pageElementStates[thisMediaElementId].milestoneTimes.length; c++) {
                                    if (thisMediaElement[Constants.HTMLMEDIAELEMENT_PROPERTY_CURRENTTIME] >= pageElementStates[thisMediaElementId].milestoneTimes[c]) {
                                        const copiedElement = context._copyConfig();
                                        let copiedSegmentData = null;
                                        if (Constants.ELEMENT_DATA_DATA in copiedElement && Constants.ELEMENT_DATA_SEGMENT_DATA in copiedElement[Constants.ELEMENT_DATA_DATA]) {
                                            copiedSegmentData = copiedElement[Constants.ELEMENT_DATA_DATA][Constants.ELEMENT_DATA_SEGMENT_DATA];
                                        }

                                        if (copiedSegmentData) {
                                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Media.PLAYBACK_MILESTONE);

                                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.START_PROCESSING.format(
                                                Constants.DYNAMIC_DATA_TYPE_MEDIA));
                                            let processTypeArgs = {};
                                            processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES] = context._lib.getPageValues();
                                            processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_EVENT] = event;
                                            DynamicDataProcessor.process(copiedSegmentData, Constants.DYNAMIC_DATA_TYPE_MEDIA, processTypeArgs);
                                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.FINISH_PROCESSING.format(
                                                Constants.DYNAMIC_DATA_TYPE_MEDIA));

                                            context._trackData(copiedSegmentData);
                                        } else {
                                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Media.SEGMENT_DATA_NOT_CONFIGURED);
                                        }

                                        pageElementStates[thisMediaElementId].currentSegment++;
                                    } else {
                                        break;
                                    }
                                }
                            }
                        };

                        if (this._lib.getHandlers(timeupdateKeyStr).length < elements.length) {
                            this._lib.addHandler(Constants.EVENT_LISTENER_TYPE_TIMEUPDATE, timeupdateKeyStr, thisMediaElement, timeUpdateHandler);
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.ADDED_HANDLER.format(
                                Constants.EVENT_LISTENER_TYPE_TIMEUPDATE, ConsoleStrings.Element.TYPE_MEDIA, this._config[Constants.ELEMENT_DATA_SELECTOR]));
                        } else {
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.HANDLERS_ALREADY_ADDED.format(Constants.EVENT_LISTENER_TYPE_TIMEUPDATE));
                        }
                    } else {
                        if (trackSegments && !(Constants.ELEMENT_DATA_MEDIA_OPTIONS_SEGMENTS in mediaOptions)) {
                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.Media.TRACK_SEGMENTS_NO_SEGMENTS_SET);
                        } else if (trackSegments && numSegments < 2) {
                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.Media.TRACK_SEGMENTS_SEGMENTS_TOO_FEW);
                        }
                    }
                }
            }
        }
    }

    _trackData (dataElement) {
        let trackingEventType = Constants.EVENT_TYPE_GENERAL;
        if (Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE in this._config && this._config[Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE] !== null) {
            trackingEventType = this._config[Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE];
        }

        if (this._checkCondition()) {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.MAKING_TRACK_EVENT_CALLS.format(trackingEventType, Utils.JSONStringify(dataElement)));
            this._lib.makeTrackEventCalls(trackingEventType, dataElement);
        }
    }
}

export default MediaElement; // JSDoc workaround for documenting classes
