/**
 * Interface for defining an analytics service
 *
 * @interface IAnalyticsService
 */
class IAnalyticsService {

    constructor (classname) {
        this._classname = classname;

        if (this._classname === undefined) {
            this._classname = 'IAnalyticsService';
        }

        if (this._classname === 'IAnalyticsService') {
            throw new TypeError('Cannot instantiate interface IAnalyticsService');
        }

        if (this.init === undefined) {
            throw new TypeError('IAnalyticsService derived classes must implement "init"');
        }
        if (this.trackEvent === undefined) {
            throw new TypeError('IAnalyticsService derived classes must implement "trackEvent"');
        }
    }

    /**
     * Called at the beginning of the page load to initialize the analytics service
     *
     * NOTE: function name to implement is "init", "init_" is for documentation purposes only
     *
     * @memberof IAnalyticsService
     * @param {AnalyticsServiceConfig} config Configuration object for the AnalyticsService object
     * @param {AnalyticsServiceArgs} args Extra arguments to pass in to function.
     * @returns {void}
     */
    init_ (config, args) {} //eslint-disable-line

    /**
     * Called when an event needs to be tracked
     *
     * NOTE: function name to implement is "trackEvent", "trackEvent"_ is for documentation purposes only
     *
     * @memberof IAnalyticsService
     * @param {string} event The event type to track
     * @param {AnalyticsServiceArgs} args The arguments to pass in to function.
     * @returns {void}
     */
    trackEvent_ (event, args) {} //eslint-disable-line
}

export default IAnalyticsService; // JSDoc workaround for documenting classes
