import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import DynamicDataTypeSelector from './DynamicDataTypeSelector';

/**
 * Used to handle CSS type selections in dynamic data objects
 * @extends DynamicDataTypeSelector
 */
class CSSSelector extends DynamicDataTypeSelector {

    /**
     * Constructor for CSSSelector
     * @param {Object} options
     */
    constructor (options) {
        super('CSSSelector', options);
    }

    /**
     * The main function to get the value of this dynamic data object
     *
     * @returns {String|Number}
     */
    get () {
        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.CSSSelector.ATTEMPTING_TO_FIND_ELEMENTS.format(this._selector));

        let valStr = '';
        if (this._selector !== null) {
            let elements = [];
            try {
                elements = document.querySelectorAllDeep(this._selector);
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.FOUND_ELEMENTS.format(this._selector, elements.length));
            } catch (e) {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.FAILED_TO_QUERY_SELECTOR.format(this._selector));
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.ERROR, e.stack);
            }

            if (this._valueType === Constants.DYNAMIC_DATA_SINGLE_VAL) {
                valStr = this._getSingleValue(elements);
            } else if (this._valueType === Constants.DYNAMIC_DATA_MULTI_VAL) {
                valStr = this._getMultiValue(elements);
            } else if (this._valueType === Constants.DYNAMIC_DATA_COUNT_VAL) {
                valStr = this._getCountValue(elements);
            } else if (this._valueType === Constants.DYNAMIC_DATA_BOOLEAN_VAL) {
                valStr = this._getBooleanValue(elements);
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.UNSUPPORTED_VALUE_TYPE.format(this._valueType));
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.MUST_HAVE_SELECTOR);
        }

        return this._applyModifiers(valStr);
    }

    /**
     * Get the single value for this selector
     *
     * @private
     * @param {NodeList} elements
     * @returns {String}
     */
    _getSingleValue (elements) {
        let valStr = '';
        if (this._value !== null) {
            if (elements.length > 0) {
                const element = elements[0];
                if (this._value in element) {
                    valStr = element[this._value];
                } else if (element.hasAttribute(this._value)) {
                    valStr = element.getAttribute(this._value);
                } else {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.ATTRIBUTE_NOT_FOUND.format(this._value));
                }

                if (typeof valStr === Constants.JS_TYPE_STRING) {
                    valStr = valStr.trim();
                }
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.VALUETYPE_SINGLE_REQUIRES_VALUE);
        }
        return valStr;
    }

    /**
     * Get the multi value for this selector
     *
     * @private
     * @param {NodeList} elements
     * @returns {String}
     */
    _getMultiValue (elements) {
        let valStr = '';
        if (this._value !== null) {
            if (elements.length > 0) {
                if (this._value in elements[0]) {
                    valStr = String(elements[0][this._value]).trim();
                } else if (elements[0].hasAttribute(this._value)) {
                    valStr = String(elements[0].getAttribute(this._value)).trim();
                } else {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.ATTRIBUTE_NOT_FOUND.format(this._value));
                }
                for (let i = 1; i < elements.length; i++) {
                    if (this._value in elements[i]) {
                        valStr += (this._multiValSep + String(elements[i][this._value]).trim());
                    } else if (elements[i].hasAttribute(this._value)) {
                        valStr += (this._multiValSep + String(elements[i].getAttribute(this._value)).trim());
                    } else {
                        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.ATTRIBUTE_NOT_FOUND.format(this._value));
                    }
                }
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.VALUETYPE_MULTI_REQUIRES_VALUE);
        }
        return valStr;
    }

    /**
     * Get the count value for this selector
     *
     * @private
     * @param {NodeList} elements
     * @returns {Number}
     */
    _getCountValue (elements) {
        return elements.length;
    }

    /**
     * Get the boolean value for this selector
     *
     * @private
     * @param {NodeList} elements
     * @returns {Boolean}
     */
    _getBooleanValue (elements) {
        return elements.length ? true : false;
    }
}

export default CSSSelector; // JSDoc workaround for documenting classes
