import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import DynamicDataTypeSelector from './DynamicDataTypeSelector';

/**
 * Used to handle URL parameter type selections in dynamic data objects
 * @extends DynamicDataTypeSelector
 */
class URLParamSelector extends DynamicDataTypeSelector {

    /**
     * Constructor for URLParamSelector
     * @param {Object} options
     */
    constructor (options) {
        super('URLParamSelector', options);
    }

    /**
     * The main function to get the value of this dynamic data object
     *
     * @returns {String|Number}
     */
    get () {
        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.URLParamSelector.ATTEMPTING_TO_FIND_PARAM.format(this._selector));

        let valStr = '';
        if (this._selector !== null) {
            let queryString = window.location.search.slice(1);
            let parameters = this._getUrlParams(queryString);

            if (this._valueType === Constants.DYNAMIC_DATA_SINGLE_VAL) {
                if (this._selector in parameters) {
                    valStr = parameters[this._selector];
                }
            } else if (this._valueType === Constants.DYNAMIC_DATA_BOOLEAN_VAL) {
                if (this._selector in parameters) {
                    if (Constants.DYNAMIC_DATA_URLPARAM_BOOLEAN_STRING_TRUE_EQUIVALENCE.includes(parameters[this._selector].toLowerCase())) {
                        valStr = true;
                    } else {
                        valStr = false;
                    }
                } else {
                    valStr = false;
                }
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.UNSUPPORTED_VALUE_TYPE.format(this._valueType));
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.MUST_HAVE_SELECTOR);
        }

        return this._applyModifiers(valStr);
    }

    /**
     * Get the parameters object from the query string
     *
     * @private
     * @param {String} queryString
     * @returns {Object}
     */
    _getUrlParams (queryString) {
        let parameters = {};
        if (queryString) {
            queryString = queryString.split('#')[0];
            let paramsArray = queryString.split('&');
            for (let p = 0; p < paramsArray.length; p++) {
                let [name, val] = paramsArray[p].split('=');
                if (typeof val === Constants.JS_TYPE_UNDEFINED) {
                    val = 'true';
                }

                parameters[name] = val;
            }
        }
        return parameters;
    }
}

export default URLParamSelector; // JSDoc workaround for documenting classes
