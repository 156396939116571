
import { Constants } from './Constants';
import { ConsoleStrings } from './ConsoleStrings';
import Logger from './Logger';
import Utils from './Utils';
import DynamicDataUtils from './DynamicDataUtils';
import PageValueSelector from './DynamicDataSelectorTypes/PageValueSelector';
import SystemSelector from './DynamicDataSelectorTypes/SystemSelector';
import CSSSelector from './DynamicDataSelectorTypes/CSSSelector';
import URLParamSelector from './DynamicDataSelectorTypes/URLParamSelector';
import PropertiesSelector from './DynamicDataSelectorTypes/PropertiesSelector';
import TargetSelector from './DynamicDataSelectorTypes/TargetSelector';
import CounterSelector from './DynamicDataSelectorTypes/CounterSelector';

/**
 * Class that handles processing of dynamic data. Its main 'process' method is what does
 * the processing by taking an object and a type as input and then processes accordingly.
 */
class DynamicDataProcessor {
    /**
     * Function to process the object that was input that replaces any dynamic data objects
     * within that object of the specified type with its actual value.
     *
     * @param {Object} object
     * @param {string} type
     * @param {Object} typeArgsObj
     */
    static process (object, type, typeArgsObj = {}) {
        let logger = Logger.getLogger();

        logger.trace(ConsoleStrings.DynamicData.Processor.START_PROCESSING.format(type));
        this._updateObjectWithDynamicData(object, type, typeArgsObj);
        logger.trace(ConsoleStrings.DynamicData.Processor.FINISH_PROCESSING);
    }

    /**
     * Function to clear dynamic data objects from an input object.
     * Should be used before tracking multi elements to clean up any DD
     * objects that may have been set up and not processed.
     *
     * @static
     * @param {Object} object
     */
    static clearUnprocessedObjects (object) {
        let logger = Logger.getLogger();

        let objPaths = [];
        objPaths = objPaths.concat(this._getObjectPaths(object));

        for (let objPathIndex = 0; objPathIndex < objPaths.length; objPathIndex++) {
            let curPath = objPaths[objPathIndex];
            let curObj = null;
            for (let pathIndex = 0; pathIndex < curPath.length; pathIndex++) {
                if (curObj !== null) {
                    curObj = curObj[curPath[pathIndex]];
                } else {
                    curObj = object[curPath[pathIndex]];
                }
            }

            if (curObj !== null) {
                if (DynamicDataUtils.isDynamicDataObject(curObj)) {
                    logger.warn(ConsoleStrings.DynamicData.Processor.CLEARING_OBJECT.format(curObj[Constants.DDOBJ_DYNAMIC_DATA_TYPE], curPath.toString()));
                    let workingElementData = object;
                    let pathArr = curPath;
                    while (pathArr.length > 1) {
                        workingElementData = workingElementData[pathArr.shift()];
                    }
                    workingElementData[pathArr.shift()] = '';
                }
            }
        }
    }

    /**
     * Gets the paths within the object that are DD objects.
     * If no ddType is passed in, all types are retrieved.
     *
     * @private
     * @static
     * @param {Object} object
     * @param {string} ddType
     * @return {string[][]}
     */
    static _getObjectPaths (object, ddType) {
        let paths = [];

        // Traverse the object and find DynamicDataObject objects, finding deeper objects
        // first in order to prepare for scenarios where properties reference other properties
        function search(o, p) {
            for (let k in o) {
                if (o.hasOwnProperty(k)) {
                    p.push(k);
                    if (typeof(o[k]) === Constants.JS_TYPE_OBJECT && o[k] !== null) {
                        search(o[k], p);
                    }

                    // is object && is not-null && is DD object && (is of input DD type || no DD type input)
                    if (typeof(o[k]) === Constants.JS_TYPE_OBJECT && o[k] !== null && DynamicDataUtils.isDynamicDataObject(o[k]) &&
                        (o[k][Constants.DDOBJ_DYNAMIC_DATA_TYPE].split(Constants.DYNAMIC_DATA_TYPE_MULTI_SEPARATOR)[0] === ddType) ||
                        typeof ddType === Constants.JS_TYPE_UNDEFINED) {
                        paths.push(p.slice());
                    }
                    p.pop();
                }
            }
        }

        if (typeof(object) === Constants.JS_TYPE_OBJECT) {
            search(object, []);
        }

        return paths;
    }

    /**
     * Update the given object with dynamic data generated via configuration within object
     *
     * @private
     * @static
     * @param {Object} object
     * @param {string} ddType
     * @param {Object} typeArgsObj
     * @returns {void}
     */
    static _updateObjectWithDynamicData (object, ddType, typeArgsObj) {
        let logger = Logger.getLogger();

        let objPaths = this._getObjectPaths(object, ddType);
        for (let objPathIndex = 0; objPathIndex < objPaths.length; objPathIndex++) {
            let curPath = objPaths[objPathIndex];
            let curObj = null;
            for (let pathIndex = 0; pathIndex < curPath.length; pathIndex++) {
                if (curObj !== null) {
                    curObj = curObj[curPath[pathIndex]];
                } else {
                    curObj = object[curPath[pathIndex]];
                }
            }

            if (curObj !== null) {
                if (DynamicDataUtils.isDynamicDataObject(curObj)) {
                    let valStr = '';
                    let thisData = {};

                    let curDDType = curObj[Constants.DDOBJ_DYNAMIC_DATA_TYPE];
                    let selectorType = curObj[Constants.DDOBJ_SELECTOR_TYPE];
                    let selectorOptions = curObj[Constants.DDOBJ_SELECTOR_OPTIONS];

                    // Check multi and multiclick for selector match before getting data and continue loop if mismatch
                    let multiContinue = true;
                    if (curDDType.indexOf(Constants.DYNAMIC_DATA_TYPE_MULTI_CLICK) === 0) {
                        if (Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_MULTI_SELECTOR in typeArgsObj) {
                            let multiSelector = typeArgsObj[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_MULTI_SELECTOR];
                            let ddObjMultiObj = [];
                            ddObjMultiObj.push(curDDType.substring(0, curDDType.indexOf(Constants.DYNAMIC_DATA_TYPE_MULTI_SEPARATOR)));
                            ddObjMultiObj.push(curDDType.substring(curDDType.indexOf(Constants.DYNAMIC_DATA_TYPE_MULTI_SEPARATOR)+1));
                            if (ddObjMultiObj.length === 2) {
                                let ddObjMultiSelector = ddObjMultiObj[1];
                                if (multiSelector === ddObjMultiSelector) {
                                    multiContinue = false;
                                }
                            }
                        }

                        if (multiContinue) {
                            continue;
                        }
                    } else if (curDDType.indexOf(Constants.DYNAMIC_DATA_TYPE_MULTI) === 0) {
                        if (Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_MULTI_SELECTOR in typeArgsObj) {
                            let multiSelector = typeArgsObj[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_MULTI_SELECTOR];
                            let ddObjMultiObj = [];
                            ddObjMultiObj.push(curDDType.substring(0, curDDType.indexOf(Constants.DYNAMIC_DATA_TYPE_MULTI_SEPARATOR)));
                            ddObjMultiObj.push(curDDType.substring(curDDType.indexOf(Constants.DYNAMIC_DATA_TYPE_MULTI_SEPARATOR)+1));
                            if (ddObjMultiObj.length === 2) {
                                const ddObjMultiSelector = ddObjMultiObj[1];
                                if (multiSelector === ddObjMultiSelector) {
                                    multiContinue = false;
                                }
                            }
                        }

                        if (multiContinue) {
                            continue;
                        }
                    }

                    // Setup selector object
                    let selector = null;
                    logger.trace(ConsoleStrings.DynamicData.Processor.SELECTOR_TYPE.format(selectorType));
                    logger.trace(ConsoleStrings.DynamicData.Processor.SELECTOR_OPTIONS.format(Utils.JSONStringify(selectorOptions)));
                    if (selectorType === Constants.DYNAMIC_DATA_PAGEVALUE) {
                        let pageVals = null;
                        if (Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES in typeArgsObj) {
                            pageVals = typeArgsObj[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES];
                        }
                        selector = new PageValueSelector(selectorOptions, pageVals);
                    } else if (selectorType === Constants.DYNAMIC_DATA_SYSTEM) {
                        let timing = null;
                        if (Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_TIMING in typeArgsObj) {
                            timing = typeArgsObj[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_TIMING];
                        }
                        selector = new SystemSelector(selectorOptions, timing);
                    } else if (selectorType === Constants.DYNAMIC_DATA_CSS) {
                        selector = new CSSSelector(selectorOptions);
                    } else if (selectorType === Constants.DYNAMIC_DATA_URLPARAM) {
                        selector = new URLParamSelector(selectorOptions);
                    } else if (selectorType === Constants.DYNAMIC_DATA_PROPERTIES) {
                        selector = new PropertiesSelector(selectorOptions, thisData[Constants.DYNAMIC_DATA_OBJ_DATA_PATH]);
                    } else if (selectorType === Constants.DYNAMIC_DATA_TARGET) {
                        let event = null;
                        if (Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_EVENT in typeArgsObj) {
                            event = typeArgsObj[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_EVENT];
                        }
                        selector = new TargetSelector(selectorOptions, event);
                    } else if (selectorType === Constants.DYNAMIC_DATA_COUNTER) {
                        selector = new CounterSelector(selectorOptions);
                    } else {
                        logger.warn(ConsoleStrings.DynamicData.Processor.UNSUPPORTED_SELECTOR.format(selectorType));
                    }

                    // Use selector object (currently just gets the data)
                    if (selector) {
                        valStr = selector.get();
                    }

                    // Finally put the data in the object
                    let workingElementData = object;
                    const pathArr = curPath;
                    while (pathArr.length > 1) {
                        workingElementData = workingElementData[pathArr.shift()];
                    }
                    workingElementData[pathArr.shift()] = valStr;
                }
            }
        }
    }
}

export default DynamicDataProcessor; // JSDoc workaround for documenting classes
