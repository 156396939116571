import { Constants } from './Constants';

/**
 * Class to manage global counters
 */
class CounterManager {
    /**
     * Object to keep track of active counters
     *
     * @static
     * @memberof CounterManager
     */
    static COUNTERS = {};

    /**
     * Function to check if there is an active counter with a given name
     *
     * @static
     * @param {String} name
     */
    static hasCounter (name) {
        return (name in this.COUNTERS);
    }

    /**
     * Function to get the current value of a counter
     *
     * @static
     * @param {String} name
     */
    static getCounterValue (name) {
        if (this.hasCounter(name)) {
            return this.COUNTERS[name].count;
        } else {
            return 0;
        }
    }

    /**
     * Function to create a counter
     *
     * @static
     * @param {String} name
     * @param {Object} options
     */
    static createCounter (name, options) {
        if (!this.hasCounter(name)) {
            this.COUNTERS[name] = {};
            this.COUNTERS[name].name = name;
            this.COUNTERS[name].type = null;
            this.COUNTERS[name].count = 0;
            this.COUNTERS[name].options = options;

            this._startCounter(this.COUNTERS[name]);
        }
    }

    /**
     * Function to stop a counter
     *
     * @static
     * @param {String} name
     */
    static stopCounter (name) {
        if (this.hasCounter(name)) {
            if (this.COUNTERS[name].type === Constants.COUNTERS_TYPE_INTERVAL) {
                clearInterval(this.COUNTERS[name]._interval);
                this.COUNTERS[name] = null;
                delete this.COUNTERS[name];
            } else if (this.COUNTERS[name].type === Constants.COUNTERS_TYPE_ACTION) {
                let action = null;
                let actionSelector = null;
                let options = this.COUNTERS[name].options;
                if (Constants.COUNTERS_OPTIONS_UPDATE in options) {
                    let updateOptions = options[Constants.COUNTERS_OPTIONS_UPDATE];
                    action = updateOptions[Constants.COUNTERS_OPTIONS_ACTION];
                    actionSelector = updateOptions[Constants.COUNTERS_OPTIONS_SELECTOR];
                }

                let actionElements = document.querySelectorAllDeep(actionSelector);
                for (let e = 0; e < actionElements.length; e++) {
                    actionElements[e].removeEventListener(action, this.COUNTERS[name]._actionCallback);
                }
                this.COUNTERS[name] = null;
                delete this.COUNTERS[name];
            }
        }
    }

    /**
     * Function to update counters
     *
     * @static
     */
    static updateCounters () {
        for (let c in this.COUNTERS) {
            let thisCounter = this.COUNTERS[c];
            if (thisCounter && thisCounter.type === Constants.COUNTERS_TYPE_ACTION) {
                let name = thisCounter.name;
                let action = null;
                let actionSelector = null;
                let options = thisCounter.options;
                let selector = options[Constants.COUNTERS_OPTIONS_SELECTOR];
                if (Constants.COUNTERS_OPTIONS_UPDATE in options) {
                    let updateOptions = options[Constants.COUNTERS_OPTIONS_UPDATE];
                    action = updateOptions[Constants.COUNTERS_OPTIONS_ACTION];
                    actionSelector = updateOptions[Constants.COUNTERS_OPTIONS_SELECTOR];
                }

                let actionElements = document.querySelectorAllDeep(actionSelector);
                if (actionElements.length) {
                    if (thisCounter._actionCallback) {
                        for (let e = 0; e < actionElements.length; e++) {
                            actionElements[e].removeEventListener(action, thisCounter._actionCallback);
                        }
                    }

                    this._addActionCounterHandlers(name, selector, action, actionElements);
                }
            }
        }
    }

    /**
     * Function to start a counter
     *
     * @private
     * @static
     * @param {Object} counter
     */
    static _startCounter (counter) {
        if (this.hasCounter(counter.name)) {
            let options = counter.options;

            let counterSelector = null;
            let updateOptions = null;
            if (Constants.COUNTERS_OPTIONS_SELECTOR in options) {
                counterSelector = options[Constants.COUNTERS_OPTIONS_SELECTOR];
            }
            if (Constants.COUNTERS_OPTIONS_UPDATE in options) {
                updateOptions = options[Constants.COUNTERS_OPTIONS_UPDATE];
            }

            let created = false;
            if (counterSelector && updateOptions) {
                if (Constants.COUNTERS_OPTIONS_ACTION in updateOptions && Constants.COUNTERS_OPTIONS_SELECTOR in updateOptions) {
                    let action = updateOptions[Constants.COUNTERS_OPTIONS_ACTION];
                    let actionSelector = updateOptions[Constants.COUNTERS_OPTIONS_SELECTOR];
                    if (action && actionSelector) {
                        if (this._startActionCounter(counter.name, counterSelector, action, actionSelector)) {
                            created = true;
                        }
                    }
                } else if (Constants.COUNTERS_OPTIONS_INTERVAL in updateOptions) {
                    let interval = updateOptions[Constants.COUNTERS_OPTIONS_INTERVAL];
                    if (interval) {
                        if (this._startIntervalCounter(counter.name, counterSelector, interval)) {
                            created = true;
                        }
                    }
                }
            }

            if (!created) {
                this.COUNTERS[counter.name] = null;
                delete this.COUNTERS[counter.name];
            }
        }
    }

    /**
     * Function to start an action counter
     *
     * @private
     * @static
     * @param {String} name
     * @param {String} selector
     * @param {String} action
     * @param {String} actionSelector
     */
    static _startActionCounter (name, selector, action, actionSelector) {
        let created = false;
        if (this.hasCounter(name)) {
            this.COUNTERS[name].type = Constants.COUNTERS_TYPE_ACTION;
            let actionElements = document.querySelectorAllDeep(actionSelector);
            if (actionElements.length) {
                this._addActionCounterHandlers(name, selector, action, actionElements);
            }
            created = true;
        }
        return created;
    }

    /**
     * Function to add handlers to action counters
     *
     * @private
     * @static
     * @param {String} name
     * @param {String} selector
     * @param {String} action
     * @param {String} actionElements
     */
    static _addActionCounterHandlers (name, selector, action, actionElements) {
        for (let e = 0; e < actionElements.length; e++) {
            actionElements[e].addEventListener(action, this.COUNTERS[name]._actionCallback = function () {
                let countElements = document.querySelectorAllDeep(selector);
                CounterManager.COUNTERS[name].count = countElements.length;
            });
        }
    }

    /**
     * Function to start an interval counter
     *
     * @private
     * @static
     * @param {String} name
     * @param {String} selector
     * @param {String} interval
     */
    static _startIntervalCounter (name, selector, interval) {
        let created = false;
        if (this.hasCounter(name)) {
            this.COUNTERS[name].type = Constants.COUNTERS_TYPE_INTERVAL;
            CounterManager.COUNTERS[name]._interval = setInterval(function () {
                let elements = document.querySelectorAllDeep(selector);
                CounterManager.COUNTERS[name].count = elements.length;
            }, interval);
            created = true;
        }
        return created;
    }
}

export default CounterManager; // JSDoc workaround for documenting classes
