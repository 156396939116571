import { Constants } from './Constants';

/**
 * Class with utility functions
 */
class Utils {

    /**
     * Function that calls JSON.stringify with a custom replacer method
     *
     * @static
     * @param {Object} obj
     * @param {Number} space
     */
    static JSONStringify (obj, space) {
        return JSON.stringify(obj, this._stringifyReplacer, space);
    }

    /**
     * Function that calls JSON.parse with a custom replacer method
     *
     * @static
     * @param {Object} obj
     */
    static JSONParse (obj) {
        return JSON.parse(obj, this._parseReplacer);
    }

    /**
     * A Utils.JSONStringify replacer function that preserves certain values
     *
     * @static
     * @private
     */
    static _stringifyReplacer (key, value) {
        if (value !== value) {
            return 'NaN';
        }

        if (value === Infinity) {
            return 'Infinity';
        }

        if (value === -Infinity) {
            return '-Infinity';
        }

        return value;
    }

    /**
     * A JSON.parse replacer function that gets original values back for certain values
     *
     * @static
     * @private
     */
    static _parseReplacer (key, value) {
        if (value === 'NaN') {
            return NaN;
        }

        if (value === 'Infinity') {
            return Infinity;
        }

        if (value === '-Infinity') {
            return -Infinity;
        }

        return value;
    }

    /**
     * Get an equivalent boolean value from any value passed in. If value is in the
     * FALSE_VALUES_ARRAY, false is returned, otherwise true is returned.
     *
     * @static
     * @param {*} val
     * @returns {boolean}
     */
    static getBoolean (val) {
        return (Constants.FALSE_VALUES_ARRAY.includes(val) ? false : true);
    }

    /**
     * Copies an object
     *
     * @static
     * @param {object} obj
     * @returns {object}
     */
    static copyObject (obj) {
        let copiedObj = null;
        if (typeof obj !== Constants.JS_TYPE_UNDEFINED && obj !== null) {
            try {
                copiedObj = Utils.JSONParse(Utils.JSONStringify(obj));
            } catch (e) {
            }
        }
        return copiedObj;
    }

    /**
     * Returns true if passed in node is within a Shadow DOM, false if not.
     *
     * @static
     * @param {Node} node
     * @returns {Boolean}
     */
    static isInShadowDOM (node) {
        let parent = (node && node.parentNode);
        while (parent) {
            if (parent.toString() === '[object ShadowRoot]' || this._isShadyRoot(parent)) {
                return true;
            }
            parent = parent.parentNode;
        }
        return false;
    }

    /**
     * Check for "shady" root, which is the Web Components polyfill we use in IE
     *
     * @static
     * @private
     * @param {Node} node
     * @returns {Boolean}
     */
    static _isShadyRoot (node) {
        return node && node.host && node.host.__shady && node.host.__shady.root;
    }

    /**
     * Function to take an input string and return the same string after iterating
     * through the string by character. This is going to be rarely used, but one case
     * is where the Babel runtime was replacing a class' .keys() function call with
     * the instance method .keys() function in the JavaScript language. So we can call
     * the function by passing the function name in to this function and calling with
     * the return value of it so the transpiler doesn't replace it inline. Very hacky,
     * but it works for now.
     *
     * @static
     * @param {String} str
     * @returns {String}
     */
    static returnSameString (str) {
        let retStr = '';
        for (let c of str) {
            retStr += c;
        }
        return retStr;
    }
}

export default Utils; // JSDoc workaround for documenting classes
