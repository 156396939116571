import { Constants } from './Constants';

/**
 * Logger class to write info to console with configurable level
 */
class Logger {
    /**
     * @property {number} _level
     * @property {boolean} _outputSuffix
     *
     * @constructor
     * @param {number} level The log level to use with this Logger instance
     * @param {boolean} suffixOn Whether or not we should output suffix line number and location
     */
    constructor (level, suffixOn) {
        this._level = typeof level !== Constants.JS_TYPE_UNDEFINED ? level : Constants.LOGGER_LEVELS_ENUM.NORMAL;
        this._outputSuffix = typeof suffixOn !== Constants.JS_TYPE_UNDEFINED ? suffixOn : false;
    }

    /**
     * @description Singleton method to get the Logger instance
     *
     * @static
     * @param {number} level
     * @param {boolean} suffixOn
     * @returns {Logger}
     */
    static getLogger (level, suffixOn) {
        if (typeof level === Constants.JS_TYPE_UNDEFINED) {
            level = Constants.LOGGER_LEVELS_ENUM.NORMAL;
        }
        if (typeof suffixOn === Constants.JS_TYPE_UNDEFINED) {
            suffixOn = false;
        }

        if (typeof window[Constants.LOGGER_VAR] === Constants.JS_TYPE_UNDEFINED) {
            window[Constants.LOGGER_VAR] = new Logger(level, suffixOn);
        }
        return window[Constants.LOGGER_VAR];
    }

    /**
     * @description Logger function for info level messages
     *
     * @param {string} msg The message to log
     * @returns {void}
     */
    info (msg) {
        if (this._level & Constants.LOGGER_LEVELS_ENUM.INFO) {
            console.info(this._getPrefixString('INFO') + msg + this._getSuffixString());
        }
    }

    /**
     * @description Logger function for warn level messages
     *
     * @param {string} msg The message to log
     * @returns {void}
     */
    warn (msg) {
        if (this._level & Constants.LOGGER_LEVELS_ENUM.WARN) {
            console.warn(this._getPrefixString('WARN') + msg + this._getSuffixString()); // NOSONAR Console logging should not be used
        }
    }

    /**
     * @description Logger function for error level messages
     *
     * @param {string} msg The message to log
     * @returns {void}
     */
    error (msg) {
        if (this._level & Constants.LOGGER_LEVELS_ENUM.ERROR) {
            console.error(this._getPrefixString('ERROR') + msg + this._getSuffixString()); // NOSONAR Console logging should not be used
        }
    }

    /**
     * @description Logger function for debug level messages
     *
     * @param {string} msg The message to log
     * @returns {void}
     */
    debug (msg) {
        if (this._level & Constants.LOGGER_LEVELS_ENUM.DEBUG) {
            console.log(this._getPrefixString('DEBUG') + msg + this._getSuffixString()); // NOSONAR Console logging should not be used
        }
    }

    /**
     * @description Logger function for debug2 level messages
     *
     * @param {string} msg The message to log
     * @returns {void}
     */
    trace (msg) {
        if (this._level & Constants.LOGGER_LEVELS_ENUM.TRACE) {
            console.log(this._getPrefixString('TRACE') + msg + this._getSuffixString()); // NOSONAR Console logging should not be used
        }
    }

    /**
     * @description Private function to get prefix string to prepend to message
     *
     * @private
     * @param {string} levelStr
     * @returns {string}
     */
    _getPrefixString (levelStr) {
        const now = new Date();
        const timeStr = ('0' + now.getHours()).slice(-2) + ':' +
                        ('0' + now.getMinutes()).slice(-2) + ':' +
                        ('0' + now.getSeconds()).slice(-2) + ':' +
                        ('00' + now.getMilliseconds()).slice(-3);
        return '[' + Constants.PACKAGE_NAME + ':' + timeStr + ':' + levelStr + '] ';
    }

    /**
     * @description Private function to get suffix string to append to message
     *
     * @private
     * @returns {string}
     */
    _getSuffixString () {
        if (this._outputSuffix) {
            let stackLineArray = [];
            let err = new Error();
            if (Constants.ERROR_PROPERTY_STACK in err && typeof err.stack !== Constants.JS_TYPE_UNDEFINED) {
                stackLineArray = err.stack.split('\n');
            }
            let callerLine = '';
            if (stackLineArray.length >= 4) {
                callerLine = stackLineArray[3];
            }
            return (callerLine ? ('\n\t' + callerLine.trim()) : '');
        } else {
            return '';
        }
    }
}

export default Logger; // JSDoc workaround for documenting classes
