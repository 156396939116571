/** @module PrototypeAdditionsDOM */
import Utils from "./Utils";

/**
 * Function to load the prototype additions to the DOM
 * @function load
 */
export function load () {
    // Taken from query-selector-shadow-dom (TODO: import directly from there if ever move to webpack)
    /**
     * @author Georgegriff@ (George Griffiths)
     * License Apache-2.0
     */
    function querySelectorAllDeep(selector, root = document) {
        return _querySelectorDeep(selector, true, root);
    }

    function querySelectorDeep(selector, root = document) {
        return _querySelectorDeep(selector, false, root);
    }

    function _querySelectorDeep(selector, findMany, root) {
        let lightElement = root.querySelector(selector);

        if (document.head.createShadowRoot || document.head.attachShadow) {
            // no need to do any special if selector matches something specific in light-dom
            if (!findMany && lightElement) {
                return lightElement;
            }

            // split on commas because those are a logical divide in the operation
            const selectionsToMake = splitByCharacterUnlessQuoted(selector, ',');

            return selectionsToMake.reduce((acc, minimalSelector) => {
                // if not finding many just reduce the first match
                if (!findMany && acc) {
                    return acc;
                }
                minimalSelector = removeSpacesInsideBracketsFromMinimalSelector(minimalSelector);
                // do best to support complex selectors and split the query
                const splitSelector = splitByCharacterUnlessQuoted(minimalSelector
                    //remove white space at start of selector
                    .replace(/^\s+/g, '')
                    .replace(/\s*([>+~]+)\s*/g, '$1'), ' ')
                    // filter out entry white selectors
                    .filter((entry) => !!entry);
                const possibleElementsIndex = splitSelector.length - 1;
                const possibleElements = collectAllElementsDeep(splitSelector[possibleElementsIndex], root);
                const findElements = findMatchingElement(splitSelector, possibleElementsIndex, root);
                if (findMany) {
                    acc = acc.concat(possibleElements.filter(findElements));
                    return acc;
                } else {
                    acc = possibleElements.find(findElements);
                    return acc || null;
                }
            }, findMany ? [] : null);
        } else {
            if (!findMany) {
                return lightElement;
            } else {
                return root.querySelectorAll(selector);
            }
        }
    }

    // This is used when the negative lookbehind regex doesn't work in browsers that don't
    // support them, like Safari and IE11.
    function getDoubleQuotedStringMatchObj (s) {
        let res = [];
        let tmp = "";
        let in_quotes = false;
        let in_entity = false;
        for (let i = 0; i < s.length; i++) {
            if (s[i] === '\\' && in_entity  === false) {
                in_entity = true;
                if (in_quotes === true) {
                    tmp += s[i];
                }
            } else if (in_entity === true) { // add a match
                in_entity = false;
                if (in_quotes === true) {
                    tmp += s[i];
                }
            } else if (s[i] === '"' && in_quotes === false) { // start a new match
                in_quotes = true;
                tmp += s[i];
            } else if (s[i] === '"'  && in_quotes === true) { // append char to match and add to results
                tmp += s[i];
                res.push(tmp);
                if (!('index' in res)) {
                    res.index = s.indexOf(tmp);
                }
                tmp = "";
                in_quotes = false;
            } else if (in_quotes === true) { // append a char to the match
                tmp += s[i];
            }
        }
        return res;
    }

    function removeSpacesInsideBracketsFromMinimalSelector (minimalSelector) {
        let preBracketStringResult = minimalSelector.match(/\[[^\]]+\]/);
        let bracketedSubResult = minimalSelector.match(/\[[^\]]+\]/g);
        if (preBracketStringResult && bracketedSubResult && bracketedSubResult.length) {
            let retStrArray = [minimalSelector.slice(0, preBracketStringResult.index)];
            for (let x = 0; x < bracketedSubResult.length; x++) {
                let workingStr = bracketedSubResult[x]; // bracketed substring
                let quotedSubPreResult = null;
                try {
                    let reStr = Utils.returnSameString('(["\']).*?(?<!\\\\)(\\\\\\\\)*\\1');
                    let regex = new RegExp(reStr);
                    quotedSubPreResult = workingStr.match(regex); // match any quoted string inside for later use
                } catch (e) {
                    quotedSubPreResult = getDoubleQuotedStringMatchObj(workingStr);
                }
                workingStr = workingStr.replace(/\s/g, ''); // remove all whitespace in the brackets
                if (quotedSubPreResult && quotedSubPreResult.length) {
                    let quotedSubPostResult = null;
                    try {
                        let reStr = Utils.returnSameString('(["\']).*?(?<!\\\\)(\\\\\\\\)*\\1');
                        let regex = new RegExp(reStr);
                        quotedSubPostResult = workingStr.match(regex); // match any quoted strings currently in string
                    } catch (e) {
                        quotedSubPostResult = getDoubleQuotedStringMatchObj(workingStr);
                    }
                    if (quotedSubPostResult && quotedSubPostResult.length) {
                        // add to final string, a string containing:
                        //   beginning of input string up to bracket beginning +
                        //   the working string until the quote starts +
                        //   the original quoted string +
                        retStrArray.push(workingStr.slice(0, quotedSubPostResult.index));
                        retStrArray.push(quotedSubPreResult[0]);
                        retStrArray.push(workingStr.slice(quotedSubPostResult.index + quotedSubPostResult[0].length));
                    }
                } else {
                    // no quotes, just add the whole working string
                    retStrArray.push(workingStr);
                }
                let thisMatchEndIndex = minimalSelector.indexOf(bracketedSubResult[x]) + bracketedSubResult[x].length;
                let nextMatchIndex = null;
                if (x+1 < bracketedSubResult.length) {
                    nextMatchIndex = minimalSelector.indexOf(bracketedSubResult[x+1]);
                }
                if (nextMatchIndex) {
                    retStrArray.push(minimalSelector.slice(thisMatchEndIndex, nextMatchIndex));
                } else {
                    retStrArray.push(minimalSelector.slice(thisMatchEndIndex));
                }
            }
            return retStrArray.join('');
        }
        return minimalSelector;
    }

    function findMatchingElement(splitSelector, possibleElementsIndex, root) {
        return (element) => {
            let position = possibleElementsIndex;
            let parent = element;
            let foundElement = false;
            while (parent && !isDocumentNode(parent)) {
                const foundMatch = parent.matches(splitSelector[position]);
                if (foundMatch && position === 0) {
                    foundElement = true;
                    break;
                }
                if (foundMatch) {
                    position--;
                }
                parent = findParentOrHost(parent, root);
            }
            return foundElement;
        };

    }

    function splitByCharacterUnlessQuoted(selector, character) {
        return selector.match(/\\?.|^$/g).reduce((p, c) => {
            if (c === '"' && !p.sQuote) {
                p.quote ^= 1;
                p.a[p.a.length - 1] += c;
            } else if (c === '\'' && !p.quote) {
                p.sQuote ^= 1;
                p.a[p.a.length - 1] += c;

            } else if (!p.quote && !p.sQuote && c === character) {
                p.a.push('');
            } else {
                p.a[p.a.length - 1] += c;
            }
            return p;
        }, { a: [''] }).a;
    }

    /**
     * Checks if the node is a document node or not.
     * @param {Node} node
     * @returns {Document|DocumentFragment}
     */
    function isDocumentNode(node) {
        return node.nodeType === Node.DOCUMENT_FRAGMENT_NODE || node.nodeType === Node.DOCUMENT_NODE;
    }

    function findParentOrHost(element, root) {
        const parentNode = element.parentNode;
        return (parentNode && parentNode.host && parentNode.nodeType === 11) ? parentNode.host : parentNode === root ? null : parentNode;
    }

    /**
     * Finds all elements on the page, inclusive of those within shadow roots.
     * @param {string=} selector Simple selector to filter the elements by. e.g. 'a', 'div.main'
     * @return {!Array<string>} List of anchor hrefs.
     * @author ebidel@ (Eric Bidelman)
     * License Apache-2.0
     */
    function collectAllElementsDeep(selector = null, root) {
        const allElements = [];

        const findAllElements = function(nodes) {
            for (let i = 0, el; el = nodes[i]; ++i) {
                allElements.push(el);
                // If the element has a shadow root, dig deeper.
                if (el.shadowRoot) {
                    findAllElements(el.shadowRoot.querySelectorAll('*'));
                }
            }
        };
        if(root.shadowRoot) {
            findAllElements(root.shadowRoot.querySelectorAll('*'));
        }
        findAllElements(root.querySelectorAll('*'));

        return selector ? allElements.filter(el => el.matches(selector)) : allElements;
    }

    Element.prototype.querySelectorDeep = function (selector) {
        return querySelectorDeep(selector, this);
    };

    Element.prototype.querySelectorAllDeep = function (selector) {
        return querySelectorAllDeep(selector, this);
    };

    Document.prototype.querySelectorDeep = function (selector) {
        return querySelectorDeep(selector, this);
    };

    Document.prototype.querySelectorAllDeep = function (selector) {
        return querySelectorAllDeep(selector, this);
    };

    document.querySelectorDeep = function (selector) {
        return querySelectorDeep(selector);
    };

    document.querySelectorAllDeep = function (selector) {
        return querySelectorAllDeep(selector);
    };
}
