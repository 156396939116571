import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import UsageElement from './UsageElement';
import Utils from '../Utils';

/**
 * Class for tracking page elements
 * @extends UsageElement
 */
class PageElement extends UsageElement {

    /**
     * Constructor for PageElement
     * @param {Object} elementConfig
     * @param {Boolean} track
     */
    constructor (elementConfig, track = false) {
        super('PageElement', elementConfig);
        this._track = track;
    }

    /**
     * Function to hanldle the page element
     *
     * @async
     * @param {ObservableElement} observableElement
     * @returns {Promise}
     */
    async handle (observableElement) {
        await super.handle(observableElement);

        if (!this._checkPreCondition()) {
            return;
        }

        let trackPage = true;
        if (this._track !== undefined && this._track === true) {
            if (Constants.ELEMENT_DATA_SELECTOR in this._config && this._config[Constants.ELEMENT_DATA_SELECTOR] !== null) {
                let element = null;
                try {
                    element = this._querySelectorAll(this._config[Constants.ELEMENT_DATA_SELECTOR]);
                } catch (e) {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.FAILED_TO_QUERY_SELECTOR.format(this._config[Constants.ELEMENT_DATA_SELECTOR]));
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.ERROR, e.stack);
                }

                if (element.length > 0) {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Page.SELECTOR_FOUND);
                } else {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Page.SELECTOR_NOT_FOUND);
                    trackPage = false;
                }
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Page.NO_SELECTOR);
            }
        }

        // Call analytics service method to track page
        if (trackPage && (this._track !== undefined && this._track === true)) {
            if (this._checkCondition()) {
                let data = null;
                if (Constants.ELEMENT_DATA_DATA in this._config && this._config[Constants.ELEMENT_DATA_DATA] !== null) {
                    this._processInitDynamicData();

                    data = this._config[Constants.ELEMENT_DATA_DATA];

                    let trackingEventType = Constants.EVENT_TYPE_PAGE;
                    if (Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE in this._config && this._config[Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE] !== null) {
                        trackingEventType = this._config[Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE];
                    }

                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.MAKING_TRACK_EVENT_CALLS.format(trackingEventType, Utils.JSONStringify(data)));
                    this._lib.makeTrackEventCalls(trackingEventType, data);
                } else {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.NO_DATA_NOT_TRACKING.format(ConsoleStrings.Element.TYPE_PAGE));
                }
            }
        }
    }
}

export default PageElement; // JSDoc workaround for documenting classes
