import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import UsageElement from './UsageElement';
import DeferralHelper from '../DeferralHelper';
import Utils from '../Utils';
import DynamicDataProcessor from '../DynamicDataProcessor';

/**
 * Class for tracking multi elements
 * @extends UsageElement
 */
class MultiElement extends UsageElement {

    /**
     * Constructor for MultiElement
     * @param {Object} elementConfig
     */
    constructor (elementConfig) {
        super('MultiElement', elementConfig);
        this._config[Constants.ELEMENT_DATA_DEFER] = true; // MultiElements are deferred by nature, config doesn't necessarily need to set it
    }

    /**
     * Function to hanldle the multi element
     *
     * @async
     * @param {ObservableElement} observableElement
     * @returns {Promise}
     */
    async handle (observableElement) {
        await super.handle(observableElement);

        const context = this;

        const keyStr = this._lib.getElementKeyString(this._config);

        this._processInitDynamicData();

        if (Constants.ELEMENT_DATA_SELECTOR in this._config && this._config[Constants.ELEMENT_DATA_SELECTOR] !== null) {
            const multiElements = this._config[Constants.ELEMENT_DATA_SELECTOR];
            if (Array.isArray(multiElements)) {
                if (multiElements.length > 0) {
                    const thisElement = multiElements[0];

                    let thisType = null;
                    if (Constants.ELEMENT_DATA_TYPE in thisElement && thisElement[Constants.ELEMENT_DATA_TYPE] !== null) {
                        thisType = thisElement[Constants.ELEMENT_DATA_TYPE];
                    }

                    let thisSelector = null;
                    if (Constants.ELEMENT_DATA_SELECTOR in thisElement && thisElement[Constants.ELEMENT_DATA_SELECTOR] !== null) {
                        thisSelector = thisElement[Constants.ELEMENT_DATA_SELECTOR];
                    }

                    if (thisType && thisSelector) {
                        let pageElements = null;
                        try {
                            pageElements = this._querySelectorAll(thisSelector);
                        } catch (e) {
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.FAILED_TO_QUERY_SELECTOR.format(thisSelector));
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.ERROR, e.stack);
                        }

                        // If this is a page in the multi list, gather potential dynamic data
                        if (pageElements.length > 0) {
                            if (Constants.ELEMENT_DATA_DATA in this._config) {
                                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.START_PROCESSING.format(Constants.DYNAMIC_DATA_TYPE_MULTI));
                                let processTypeArgs = {};
                                processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES] = this._lib.getPageValues();
                                processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_MULTI_SELECTOR] = thisSelector;
                                DynamicDataProcessor.process(this._config[Constants.ELEMENT_DATA_DATA], Constants.DYNAMIC_DATA_TYPE_MULTI, processTypeArgs);
                                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.FINISH_PROCESSING.format(Constants.DYNAMIC_DATA_TYPE_MULTI));
                            }
                        }

                        // If this is a page in the multi list and it's the final element of the multi list, perform tracking functions.
                        // Otherwise defer this element.
                        if (pageElements.length > 0 && multiElements.length === 1) {
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Multi.FOUND_FINAL_ELEMENT);
                            if (thisType === Constants.ELEMENT_DATA_MULTI_ELEMENT_TYPE_HTML) {
                                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Multi.FOUND_ON_PAGE_TRACKING);
                                this._trackData(this._config);
                            } else if (thisType === Constants.ELEMENT_DATA_MULTI_ELEMENT_TYPE_CLICKABLE) {
                                if (this._lib.getHandlers(keyStr).length < pageElements.length) {
                                    const clickHandler = function (event) {
                                        const copiedElement = context._copyConfig();

                                        context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Multi.CLICK_FINAL_ELEMENT);
                                        if (Constants.ELEMENT_DATA_DATA in copiedElement) {
                                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.START_PROCESSING.format(
                                                Constants.DYNAMIC_DATA_TYPE_MULTI_CLICK));
                                            let processTypeArgs = {};
                                            processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES] = context._lib.getPageValues();
                                            processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_EVENT] = event;
                                            processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_MULTI_SELECTOR] = thisSelector;
                                            DynamicDataProcessor.process(copiedElement[Constants.ELEMENT_DATA_DATA], Constants.DYNAMIC_DATA_TYPE_MULTI_CLICK, processTypeArgs);
                                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.FINISH_PROCESSING.format(
                                                Constants.DYNAMIC_DATA_TYPE_MULTI_CLICK));
                                        }

                                        context._trackData(copiedElement);

                                        // For the case where we stay on the same page, since we have processed the click, remove the listeners so it isn't processed twice
                                        for (let x = 0; x < pageElements.length; x++) {
                                            context._lib.removeHandler(Constants.EVENT_LISTENER_TYPE_CLICK, keyStr, pageElements[x]);
                                        }
                                    };

                                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Multi.CLICKABLE_MULTI_ADDING_HANDLER);
                                    for (let x = 0; x < pageElements.length; x++) {
                                        this._lib.addHandler(Constants.EVENT_LISTENER_TYPE_CLICK, keyStr, pageElements[x], clickHandler);
                                    }
                                } else {
                                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE,
                                        ConsoleStrings.Element.HANDLERS_ALREADY_ADDED.format(Constants.EVENT_LISTENER_TYPE_CLICK));
                                }
                            } else {
                                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Multi.UNSUPPORTED_MULTI_ELEMENT.format(thisType));
                            }
                        } else {
                            const copiedElement = context._copyConfig();

                            if (pageElements.length > 0) {
                                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Multi.FOUND_CURRENT_ELEMENT);

                                if (thisType === Constants.ELEMENT_DATA_MULTI_ELEMENT_TYPE_HTML) {
                                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Multi.HTML_REMOVING_FROM_LIST_SETUP_DEFERRED);
                                    copiedElement[Constants.ELEMENT_DATA_SELECTOR].shift();
                                    DeferralHelper.setupDeferredCall(copiedElement);
                                } else if (thisType === Constants.ELEMENT_DATA_MULTI_ELEMENT_TYPE_CLICKABLE) {
                                    if (this._lib.getHandlers(keyStr).length < pageElements.length) {
                                        const clickHandler = function (event) {
                                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Multi.CLICK_REMOVING_FROM_LIST_SETUP_DEFERRED);
                                            if (Constants.ELEMENT_DATA_DATA in copiedElement) {
                                                context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.START_PROCESSING.format(
                                                    Constants.DYNAMIC_DATA_TYPE_MULTI_CLICK));
                                                let processTypeArgs = {};
                                                processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES] = context._lib.getPageValues();
                                                processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_EVENT] = event;
                                                processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_MULTI_SELECTOR] = thisSelector;
                                                DynamicDataProcessor.process(copiedElement[Constants.ELEMENT_DATA_DATA], Constants.DYNAMIC_DATA_TYPE_MULTI_CLICK, processTypeArgs);
                                                context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.FINISH_PROCESSING.format(
                                                    Constants.DYNAMIC_DATA_TYPE_MULTI_CLICK));
                                            }

                                            copiedElement[Constants.ELEMENT_DATA_SELECTOR].shift();
                                            DeferralHelper.setupDeferredCall(copiedElement);

                                            // For the case where we stay on the same page, since we have processed the click, remove the listeners so it isn't processed twice
                                            for (let x = 0; x < pageElements.length; x++) {
                                                context._lib.removeHandler(Constants.EVENT_LISTENER_TYPE_CLICK, keyStr, pageElements[x]);
                                            }
                                        };

                                        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Multi.CLICKABLE_MULTI_ADDING_HANDLER);
                                        for (let x = 0; x < pageElements.length; x++) {
                                            this._lib.addHandler(Constants.EVENT_LISTENER_TYPE_CLICK, keyStr, pageElements[x], clickHandler);
                                        }
                                    } else {
                                        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.HANDLERS_ALREADY_ADDED.format(
                                            Constants.EVENT_LISTENER_TYPE_CLICK));
                                    }
                                } else {
                                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Multi.UNSUPPORTED_MULTI_ELEMENT.format(thisType));
                                }
                            } else {
                                if (DeferralHelper.isElementProcessingDeferral(copiedElement)) {
                                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Multi.IN_PROGRESS_NOT_FOUND_DEFERRED);
                                    DeferralHelper.setupDeferredCall(copiedElement);
                                } else {
                                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Multi.FIRST_ELEMENT_NOT_FOUND_SKIPPING);
                                }
                            }
                        }
                    } else {
                        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Multi.MULTI_ELEMENT_IMPROPER_TYPE);
                    }
                } else {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Multi.DEFERRED_NO_ELEMENTS_CANNOT_PROCESS);
                }
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Config.Element.MUST_BE_ARRAY);
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Multi.MUST_HAVE_ELEMENTS);
        }
    }

    /**
     * Tracks data for this element by making the trackEvent calls
     *
     * @param {analyticsElement} analyticsElement
     * @returns {void}
     */
    _trackData (analyticsElement) {
        let data = null;
        if (Constants.ELEMENT_DATA_DATA in analyticsElement && analyticsElement[Constants.ELEMENT_DATA_DATA] !== null) {
            data = analyticsElement[Constants.ELEMENT_DATA_DATA];

            let trackingEventType = Constants.EVENT_TYPE_GENERAL;
            if (Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE in analyticsElement && analyticsElement[Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE] !== null) {
                trackingEventType = analyticsElement[Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE];
            }

            if (this._checkCondition()) {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.MAKING_TRACK_EVENT_CALLS.format(trackingEventType, Utils.JSONStringify(data)));
                this._lib.makeTrackEventCalls(trackingEventType, data);
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.NO_DATA_NOT_TRACKING.format(ConsoleStrings.Element.TYPE_MULTI));
        }
    }
}

export default MultiElement; // JSDoc workaround for documenting classes