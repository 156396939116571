import { Constants } from '../Constants';
import UserActionElement from './UserActionElement';

/**
 * Base class for clickable elements
 * @extends UserActionElement
 */
class ClickableElement extends UserActionElement {
    /**
     * Constructor for ClickableElement
     * @param {Object} elementConfig
     */
    constructor (elementConfig) {
        super('ClickableElement', elementConfig, Constants.EVENT_LISTENER_TYPE_CLICK);
    }
}

export default ClickableElement; // JSDoc workaround for documenting classes
