import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import IAnalyticsService from './IAnalyticsService';
import Logger from '../Logger';
import Utils from '../Utils';
import DynamicDataProcessor from '../DynamicDataProcessor';
import StorageUtils from '../StorageUtils';

/**
 * AdobeAnalyticsLaunch implements IAnalyticsService to be used with the
 * WK usage analytics library on web pages to include details necessary with
 * Adobe Analytics Launch analytics system.
 *
 * @extends IAnalyticsService
 */
class AdobeAnalyticsLaunch extends IAnalyticsService {
    /**
     * Constructor for AdobeAnalyticsLaunch
     *
     * @property {AnalyticsServiceConfig} _config
     * @property {Logger} _logger
     */
    constructor () {
        super('AdobeAnalyticsLaunch');

        this._fullyConfigured = false;
        this._config = null;
        this._logger = Logger.getLogger();
        this.AnalyticsLib = null;
    }

    /**
     * Called at the beginning of the page load to initialize the analytics service
     *
     * @param {AnalyticsServiceConfig} config Configuration object for the AnalyticsService object
     * @param {AnalyticsServiceArgs} args Extra arguments to pass in to function.
     * @returns {void}
     */
    async init (config /*, args */) {
        if (!process.env.UNIT_TEST || (global && global.ANALYTICS_LIB_TEST)) {
            this.AnalyticsLib = (await import('../AnalyticsLib')).default;
        }

        let configured = true;

        this._config = config;

        if (!(Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_TRACKING_OBJ in this._config)) {
            this._logger.error(ConsoleStrings.AnalyticsService.AdobeAnalytics.TRACKING_OBJ_MISSING);
            configured = false;
        }

        if (window[this._config[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_TRACKING_OBJ]] === null) {
            this._logger.error(ConsoleStrings.AnalyticsService.AdobeAnalytics.TRACKING_OBJ_NULL);
            configured = false;
        }

        if (!(Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_DATA_OBJ in this._config)) {
            this._logger.error(ConsoleStrings.AnalyticsService.AdobeAnalytics.DATA_OBJ_MISSING);
            configured = false;
        }

        if (configured) {
            this._fullyConfigured = true;
            this._setupAdobeGlobals();
        }
    }

    /**
     * Called when an event needs to be tracked
     *
     * @param {string} event The event type to track
     * @param {AnalyticsServiceArgs} args The arguments to pass in to function.
     * @returns {void}
     */
    trackEvent (event, args) {
        if (this._fullyConfigured) {
            let data = null;
            if (Constants.ADOBE_ANALYTICS_LAUNCH_PROPERTY in args) {
                data = args[Constants.ADOBE_ANALYTICS_LAUNCH_PROPERTY];

                // Track data
                if (event === Constants.EVENT_TYPE_PAGE) {
                    this._trackData(data, event);
                } else if (event === Constants.EVENT_TYPE_GENERAL) {
                    this._trackData(data, event);
                } else {
                    this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.UNSUPPORTED_EVENT.format(event));
                }
            } else {
                this._logger.warn(ConsoleStrings.AnalyticsService.AdobeAnalytics.DATA_MISCONFIGURED);
            }
        } else {
            this._logger.warn(ConsoleStrings.AnalyticsService.AdobeAnalytics.CONFIG_INCOMPLETE);
        }
    }

    /**
     * Called when satellite lib track method must be called
     *
     * @memberof AdobeAnalyticsLaunch
     * @private
     * @function _trackData
     * @instance
     * @param {AnalyticsServiceArgs} args The arguments to pass in to function.
     * @param {string} event The event type for this tracking call
     * @returns {void}
     */
    _trackData (args /*, event*/) {
        if (Constants.ADOBE_ANALYTICS_LAUNCH_TRACKING_DATA_TRACKING_ID in args && args[Constants.ADOBE_ANALYTICS_LAUNCH_TRACKING_DATA_TRACKING_ID] !== null) {
            if (Constants.ADOBE_ANALYTICS_LAUNCH_TRACKING_DATA_OBJ in args && args[Constants.ADOBE_ANALYTICS_LAUNCH_TRACKING_DATA_OBJ] !== null) {
                if (typeof(window[this._config[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_TRACKING_OBJ]]) !== Constants.JS_TYPE_UNDEFINED
                    && window[this._config[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_TRACKING_OBJ]] !== null) {

                    let trackingId = args[Constants.ADOBE_ANALYTICS_LAUNCH_TRACKING_DATA_TRACKING_ID];

                    // Set the data object to the configured data
                    window[this._config[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_DATA_OBJ]] = args[Constants.ADOBE_ANALYTICS_LAUNCH_TRACKING_DATA_OBJ];

                    // Make the track call in to the Adobe Launch object
                    this._logger.debug(ConsoleStrings.AnalyticsService.AdobeAnalytics.TRACKING.format(trackingId));
                    this._logger.debug(ConsoleStrings.AnalyticsService.AdobeAnalytics.TRACKING_DATA.format(
                        Utils.JSONStringify(window[this._config[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_DATA_OBJ]], 4)));

                    if (trackingId === Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_ID) {
                        this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.COMMON_UAL_RULE_CALLED);
                        this._processCommonUalRule();
                    } else {
                        this.AnalyticsLib.getLib().insertGlobalVariables(window[this._config[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_DATA_OBJ]]);
                        window[this._config[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_TRACKING_OBJ]].track(trackingId);
                    }
                } else {
                    this._logger.warn(ConsoleStrings.AnalyticsService.AdobeAnalytics.TRACKIN_OBJ_REQUIRED.format(
                        this._config[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_TRACKING_OBJ]));
                }
            } else {
                this._logger.warn(ConsoleStrings.AnalyticsService.AdobeAnalytics.NO_DATA_TO_TRACK);
            }
        } else {
            this._logger.warn(ConsoleStrings.AnalyticsService.AdobeAnalytics.NO_ID_TO_TRACK);
        }
    }

    /**
     * Calls track for the common-ual-rule if a call isn't already in progress, and waits if there is one.
     *
     * @memberof AdobeAnalyticsLaunch
     * @private
     * @function _trackData
     * @instance
     * @param {AdobeAnalyticsLaunch} context The class instance of the caller
     * @returns {void}
     */
    _processCommonUalRule () {
        let trackingObj = window[this._config[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_TRACKING_OBJ]];
        let dataObj = window[this._config[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_DATA_OBJ]];

        let appMeasurementTracker = null;
        if (Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_APP_MEASUREMENT_TRACKER in this._config) {
            appMeasurementTracker = window[this._config[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_APP_MEASUREMENT_TRACKER]];
        }

        if (!appMeasurementTracker) {
            this._logger.warn(ConsoleStrings.AnalyticsService.AdobeAnalytics.APP_MEASUREMENT_REQUIRED);
            return;
        }

        const storageObj = StorageUtils.getSessionStorageObject();
        let runningVar = storageObj.getItem(Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_RUNNING);
        this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.RUNNING_VAR_SET_TO.format(runningVar));
        if (!Utils.getBoolean(runningVar)) {
            this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.RUNNING_VAR_NOT_SET_CALLING_TRACK);
            storageObj.setItem(Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_RUNNING, Constants.TRUE_STRING);

            try {
                if (dataObj.hasOwnProperty(Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_LAUNCH_CONFIG) &&
                    dataObj[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_LAUNCH_CONFIG]) {
                    let launchConfig = dataObj[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_LAUNCH_CONFIG];
                    let linkTrackVars = [];
                    let linkTrackEvents = [];

                    // events
                    this._processCommonUALRuleEvents(launchConfig, appMeasurementTracker, linkTrackVars, linkTrackEvents);

                    // eVars
                    this._processCommonUALRuleEvars(launchConfig, appMeasurementTracker, linkTrackVars, linkTrackEvents, trackingObj);

                    // props
                    this._processCommonUALRuleProps(launchConfig, appMeasurementTracker, linkTrackVars, linkTrackEvents);

                    // beacon
                    this._sendCommonUALRuleBeacon(launchConfig, appMeasurementTracker, linkTrackVars, linkTrackEvents);

                    this._logger.debug(ConsoleStrings.AnalyticsService.AdobeAnalytics.CUR_CALLING_TRACK);
                    trackingObj.track(Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_ID);
                } else {
                    this._logger.warn(ConsoleStrings.AnalyticsService.AdobeAnalytics.LAUNCH_CONFIG_REQUIRED);
                }
            } catch (e) {
                this._logger.warn(ConsoleStrings.AnalyticsService.AdobeAnalytics.UAL_RULE_EXCEPTION_CAUGHT.format(e));
                this._logger.error(e.stack);
            } finally {
                storageObj.removeItem(Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_RUNNING);
            }
        } else {
            this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.RUNNING_VAR_SET_WAITING);
            let context = this;
            setTimeout(function () {
                context._processCommonUalRule();
            }, Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_RETRY_TIME);
        }
    }

    /**
     * Function to set up the global values to use when using the common-ual-rule
     *
     * @private
     */
    _setupAdobeGlobals () {
        // Get initial dynamic data for globals
        if (Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS in this._config && this._config[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS] !== null) {
            this._logger.trace(ConsoleStrings.DynamicData.START_PROCESSING_GLOBALS.format(Constants.DYNAMIC_DATA_TYPE_GLOBAL));
            let processTypeArgs = {};
            processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES] = this.AnalyticsLib.getLib().getPageValues();
            DynamicDataProcessor.process(this._config[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS], Constants.DYNAMIC_DATA_TYPE_GLOBAL, processTypeArgs);
            this._logger.trace(ConsoleStrings.DynamicData.FINISH_PROCESSING_GLOBALS.format(Constants.DYNAMIC_DATA_TYPE_GLOBAL));
        }
    }

    /**
     * Inserts globals of input type in to the value object
     *
     * @private
     * @param {Object} launchConfig
     * @param {String} valueType
     * @param {Object} valueObj
     */
    _insertAdobeGlobals (launchConfig, valueType, valueObj) {
        let globals;
        if (Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS in this._config && this._config[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS]) {
            globals = this._config[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS];
        }

        let pageView;
        let beacon;
        if (launchConfig.hasOwnProperty(Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_BEACON)) {
            beacon = launchConfig[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_BEACON];
            if (beacon.hasOwnProperty(Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_PAGE_VIEW)) {
                pageView = beacon[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_PAGE_VIEW];
            }
        }

        if (globals && beacon && typeof pageView !== Constants.JS_TYPE_UNDEFINED) {
            if (valueType in globals && globals[valueType]) {
                let globalValues = globals[valueType];

                // Insert common
                if (Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS_COMMON in globalValues) {
                    let commonGlobalValues = globalValues[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS_COMMON];
                    for (let e in commonGlobalValues) {
                        if (commonGlobalValues.hasOwnProperty(e) && commonGlobalValues[e]) {
                            valueObj[e] = commonGlobalValues[e];
                        }
                    }
                }

                // Insert page or link
                if (pageView) {
                    if (Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS_PAGE in globalValues) {
                        let pageGlobalValues = globalValues[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS_PAGE];
                        for (let e in pageGlobalValues) {
                            if (pageGlobalValues.hasOwnProperty(e) && pageGlobalValues[e]) {
                                valueObj[e] = pageGlobalValues[e];
                            }
                        }
                    }
                } else {
                    if (Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS_LINK in globalValues) {
                        let linkGlobalValues = globalValues[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_GLOBALS_LINK];
                        for (let e in linkGlobalValues) {
                            if (linkGlobalValues.hasOwnProperty(e) && linkGlobalValues[e]) {
                                valueObj[e] = linkGlobalValues[e];
                            }
                        }
                    }
                }

                // Get AnalyticsLib globals that may have come in from Adobe globals
                this.AnalyticsLib.getLib().insertGlobalVariables(valueObj);
            }
        }
    }

    /**
     * Process the events for common-ual-rule
     *
     * @private
     * @param {Object} launchConfig
     * @param {AppMeasurementTracker} appMeasurementTracker
     * @param {String[]} linkTrackVars
     * @param {String[]} linkTrackEvents
     */
    _processCommonUALRuleEvents (launchConfig, appMeasurementTracker, linkTrackVars, linkTrackEvents) {
        let events = {};
        if (Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_EVENTS in launchConfig) {
            events = launchConfig[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_EVENTS];
        }

        // Insert global events before processing
        this._insertAdobeGlobals(launchConfig, Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_EVENTS, events);

        if (Object.keys(events).length) {
            linkTrackVars.push(Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_EVENTS);
            for (let e in events) {
                this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.CUR_PROCESSING_EVENT.format(e));
                if (events.hasOwnProperty(e)) {
                    linkTrackEvents.push(e);
                    let thisEventArray = events[e];
                    if (!Array.isArray(thisEventArray)) {
                        thisEventArray = [thisEventArray];
                    }
                    this._processCommonUALRuleEventsArray(e, thisEventArray, appMeasurementTracker);
                }
            }
            this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.VALUE_SET_TO.format(
                Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_EVENTS, appMeasurementTracker[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_EVENTS]));
        }
    }

    /**
     * Process a single events array
     *
     * @private
     * @param {String} eventName
     * @param {Array} eventsArray
     * @param {AppMeasurementTracker} appMeasurementTracker
     */
    _processCommonUALRuleEventsArray (eventName, eventsArray, appMeasurementTracker) {
        let setEvent = false;
        let eString = null;
        for (let x = 0; x < eventsArray.length && !setEvent; x++) {
            let curEvent = eventsArray[x];
            eString = eventName;
            if ((typeof curEvent === Constants.JS_TYPE_BOOLEAN && curEvent === true) ||
                (typeof curEvent !== Constants.JS_TYPE_BOOLEAN && typeof curEvent !== Constants.JS_TYPE_UNDEFINED && curEvent !== '')) {
                setEvent = true;
                if (typeof curEvent !== Constants.JS_TYPE_BOOLEAN) {
                    if (curEvent.hasOwnProperty(Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_SET) &&
                        typeof curEvent[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_SET] === Constants.JS_TYPE_BOOLEAN) {
                        setEvent = curEvent[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_SET];
                        if (curEvent.hasOwnProperty(Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DATA)) {
                            eString += (Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_EVENT_DATA_INDICATOR +
                                        curEvent[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DATA]);
                        }
                    } else {
                        eString += (Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_EVENT_DATA_INDICATOR + curEvent);
                    }
                }
            }
        }

        if (setEvent) {
            this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.DETERMINED_TO_BE_SET.format(eventName));
            if (appMeasurementTracker[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_EVENTS]) {
                let eArray = appMeasurementTracker[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_EVENTS].split(
                    Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DELIMETER);
                if (eArray.indexOf(eventName) === -1 &&
                    appMeasurementTracker[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_EVENTS].indexOf(
                        eventName + Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_EVENT_DATA_INDICATOR) === -1) {
                    eArray.push(eString);
                }
                appMeasurementTracker[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_EVENTS] = eArray.join();
            } else {
                appMeasurementTracker[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_EVENTS] = eString;
            }
        } else {
            this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.DETERMINED_TO_NOT_BE_SET.format(eventName));
        }
    }

    /**
     * Process the eVars for common-ual-rule
     *
     * @private
     * @param {Object} launchConfig
     * @param {AppMeasurementTracker} appMeasurementTracker
     * @param {String[]} linkTrackVars
     * @param {String[]} linkTrackEvents
     */
    _processCommonUALRuleEvars (launchConfig, appMeasurementTracker, linkTrackVars, linkTrackEvents, trackingObj) {
        let eVars = {};
        if (Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_EVARS in launchConfig) {
            eVars = launchConfig[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_EVARS];
        }

        // Insert global eVars before processing
        this._insertAdobeGlobals(launchConfig, Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_EVARS, eVars);

        if (Object.keys(eVars).length) {
            for (let e in eVars) {
                this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.CUR_PROCESSING_EVAR.format(e));
                if (eVars.hasOwnProperty(e) && eVars[e]) {
                    linkTrackVars.push(e);
                    let thisEvarArray = eVars[e];
                    if (!Array.isArray(thisEvarArray)) {
                        thisEvarArray = [thisEvarArray];
                    }
                    this._processCommonUALRuleEvarsArray(e, thisEvarArray, appMeasurementTracker, trackingObj);
                }
            }
        }
    }

    /**
     * Process a single events array
     *
     * @private
     * @param {String} eVarName
     * @param {Array} eVarsArray
     * @param {AppMeasurementTracker} appMeasurementTracker
     * @param {Object} trackingObj
     */
    _processCommonUALRuleEvarsArray (eVarName, eVarsArray, appMeasurementTracker, trackingObj) {
        let setEvar = false;
        for (let x = 0; x < eVarsArray.length && !setEvar; x++) {
            let curEvar = eVarsArray[x];
            if (curEvar.hasOwnProperty(Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_SET)) {
                setEvar = curEvar[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_SET];
                if (setEvar === true) {
                    this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.DETERMINED_TO_BE_SET.format(eVarName));
                    if (curEvar.hasOwnProperty(Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DATA)) {
                        this._processCommonUALRuleSetEvarData(eVarName, curEvar[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DATA], appMeasurementTracker, trackingObj);
                    }
                } else {
                    this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.DETERMINED_TO_NOT_BE_SET.format(eVarName));
                }
            } else {
                this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.DETERMINED_TO_BE_SET.format(eVarName));
                this._processCommonUALRuleSetEvarData(eVarName, curEvar, appMeasurementTracker, trackingObj);
                setEvar = true;
            }
        }
    }

    /**
     * Set the eVar on the measurement tracker with its data
     *
     * @private
     * @param {String} eVarName
     * @param {String} eVarData
     * @param {AppMeasurementTracker} appMeasurementTracker
     * @param {Object} trackingObj
     */
    _processCommonUALRuleSetEvarData (eVarName, eVarData, appMeasurementTracker, trackingObj) {
        let theData = eVarData;

        if (typeof eVarData === Constants.JS_TYPE_STRING &&
            eVarData.charAt(0) === Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DATA_ELEMENT_INDICATOR &&
            eVarData.charAt(eVarData.length-1) === Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DATA_ELEMENT_INDICATOR) {
            // use stored data elements if available
            theData = trackingObj.getVar(eVarData.substring(1, eVarData.length-1));
        }

        appMeasurementTracker[eVarName] = theData;
        this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.VALUE_SET_TO.format(eVarName, eVarData));
    }

    /**
     * Process the props for common-ual-rule
     *
     * @param {Object} launchConfig
     * @param {AppMeasurementTracker} appMeasurementTracker
     * @param {String[]} linkTrackVars
     * @param {String[]} linkTrackEvents
     */
    _processCommonUALRuleProps (launchConfig, appMeasurementTracker, linkTrackVars /*, linkTrackEvents*/) {
        let props = {};
        if (Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_PROPS in launchConfig) {
            props = launchConfig[Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_PROPS];
        }

        // Insert global props before processing
        this._insertAdobeGlobals(launchConfig, Constants.ADOBE_ANALYTICS_LAUNCH_CONFIG_PROPS, props);

        if (Object.keys(props).length) {
            for (let p in props) {
                this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.CUR_PROCESSING_PROP.format(p));
                if (props.hasOwnProperty(p) && props[p]) {
                    linkTrackVars.push(p);
                    let thisPropArray = props[p];
                    if (!Array.isArray(thisPropArray)) {
                        thisPropArray = [thisPropArray];
                    }
                    this._processCommonUALRulePropsArray(p, thisPropArray, appMeasurementTracker);
                }
            }
        }
    }

    /**
     * Process a single events array
     *
     * @private
     * @param {String} propName
     * @param {Array} propsArray
     * @param {AppMeasurementTracker} appMeasurementTracker
     */
    _processCommonUALRulePropsArray (propName, propsArray, appMeasurementTracker) {
        let setProp = false;
        for (let x = 0; x < propsArray.length && !setProp; x++) {
            let curProp = propsArray[x];
            if (curProp.hasOwnProperty(Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_SET)) {
                setProp = curProp[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_SET];
                if (setProp === true) {
                    this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.DETERMINED_TO_BE_SET.format(propName));
                    if (curProp.hasOwnProperty(Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DATA)) {
                        appMeasurementTracker[propName] = curProp[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DATA];
                    }
                } else {
                    this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.DETERMINED_TO_NOT_BE_SET.format(propName));
                }
            } else {
                this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.DETERMINED_TO_BE_SET.format(propName));
                appMeasurementTracker[propName] = curProp;
                setProp = true;
            }

            if (setProp) {
                this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.VALUE_SET_TO.format(propName, appMeasurementTracker[propName]));
            }
        }
    }

    /**
     * Send the beacon for the common-ual-rule
     *
     * @private
     * @param {Object} launchConfig
     * @param {AppMeasurementTracker} appMeasurementTracker
     * @param {String[]} linkTrackVars
     * @param {String[]} linkTrackEvents
     */
    _sendCommonUALRuleBeacon(launchConfig, appMeasurementTracker, linkTrackVars, linkTrackEvents) {
        if (launchConfig.hasOwnProperty(Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_BEACON)) {
            let beacon = launchConfig[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_BEACON];
            if (beacon.hasOwnProperty(Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_PAGE_VIEW) &&
                beacon[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_PAGE_VIEW]) {
                this._logger.debug(ConsoleStrings.AnalyticsService.AdobeAnalytics.PAGE_VIEW_BEACON);
                appMeasurementTracker.t();
            } else {
                appMeasurementTracker[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_LINKTRACKVARS] = linkTrackVars.join();
                appMeasurementTracker[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_LINKTRACKEVENTS] = linkTrackEvents.join();

                this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.VALUE_SET_TO.format(
                    Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_LINKTRACKVARS,
                    appMeasurementTracker[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_LINKTRACKVARS]));

                this._logger.trace(ConsoleStrings.AnalyticsService.AdobeAnalytics.VALUE_SET_TO.format(
                    Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_LINKTRACKEVENTS,
                    appMeasurementTracker[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_TRACKER_LINKTRACKEVENTS]));

                let linkType = Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DEFAULT_LINK_TYPE;
                if (beacon.hasOwnProperty(Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_LINK_TYPE)) {
                    linkType = beacon[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_LINK_TYPE];
                }
                let linkName = Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_DEFAULT_LINK_NAME;
                if (beacon.hasOwnProperty(Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_LINK_NAME)) {
                    linkName = beacon[Constants.ADOBE_ANALYTICS_LAUNCH_COMMON_UAL_RULE_LINK_NAME];
                }

                this._logger.debug(ConsoleStrings.AnalyticsService.AdobeAnalytics.LINK_TRACK_BEACON.format(linkType, linkName));
                appMeasurementTracker.tl(true, linkType, linkName, null, null);
            }
        }
    }
}

export default AdobeAnalyticsLaunch; // JSDoc workaround for documenting classes