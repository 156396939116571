import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import UsageElement from './UsageElement';
import DynamicDataProcessor from '../DynamicDataProcessor';
import Utils from '../Utils';
import DeferralHelper from '../DeferralHelper';

/**
 * Class handling event timing
 * @extends UsageElement
 */
class EventTimingElement extends UsageElement {

    /**
     * Constructor for EventTimingElement
     * @param {Object} elementConfig
     */
    constructor (elementConfig) {
        super('EventTimingElement', elementConfig);

        this._startRan = false;
        this._endRan = false;
        this._startTime = 0;
    }

    /**
     * Function to hanldle the eventtiming element
     *
     * @async
     * @param {ObservableElement} observableElement
     * @returns {Promise}
     */
    async handle (observableElement) {
        await super.handle(observableElement);

        let context = this;

        if (!this._checkPreCondition()) {
            return;
        }

        this._processInitDynamicData();

        let deferred = DeferralHelper.isElementDeferred(this._config);
        let processing = DeferralHelper.isElementProcessingDeferral(this._config);

        const keyStr = this._lib.getElementKeyString(this._config);
        const startKeyStr = keyStr + '_start';
        const endKeyStr = keyStr + '_end';

        let startEventType = null;
        let endEventType = null;

        let startElementName = null;
        let endElementName = null;

        let startEvent = null;
        let endEvent = null;

        if (Constants.ELEMENT_DATA_EVENT_TIMING_START in this._config) {
            let startConfig = this._config[Constants.ELEMENT_DATA_EVENT_TIMING_START];
            if (Constants.ELEMENT_DATA_EVENT_TIMING_TYPE in startConfig) {
                startEventType = startConfig[Constants.ELEMENT_DATA_EVENT_TIMING_TYPE];
            }
            if (Constants.ELEMENT_DATA_EVENT_TIMING_ELEMENT in startConfig) {
                startElementName = startConfig[Constants.ELEMENT_DATA_EVENT_TIMING_ELEMENT];
            }
            if (Constants.ELEMENT_DATA_EVENT_TIMING_EVENT in startConfig) {
                startEvent = startConfig[Constants.ELEMENT_DATA_EVENT_TIMING_EVENT];
            }

            if (!startEventType || !startElementName || !startEvent) {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.EventTiming.MISCONFIGURED_MISSING_SETTINGS.format(
                    Constants.ELEMENT_DATA_EVENT_TIMING_START));
                return;
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.EventTiming.MISCONFIGURED.format(Constants.ELEMENT_DATA_EVENT_TIMING_START));
            return;
        }

        if (Constants.ELEMENT_DATA_EVENT_TIMING_END in this._config) {
            let endConfig = this._config[Constants.ELEMENT_DATA_EVENT_TIMING_END];
            if (Constants.ELEMENT_DATA_EVENT_TIMING_TYPE in endConfig) {
                endEventType = endConfig[Constants.ELEMENT_DATA_EVENT_TIMING_TYPE];
            }
            if (Constants.ELEMENT_DATA_EVENT_TIMING_ELEMENT in endConfig) {
                endElementName = endConfig[Constants.ELEMENT_DATA_EVENT_TIMING_ELEMENT];
            }
            if (Constants.ELEMENT_DATA_EVENT_TIMING_EVENT in endConfig) {
                endEvent = endConfig[Constants.ELEMENT_DATA_EVENT_TIMING_EVENT];
            }

            if (!endEventType || !endElementName || !endEvent) {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.EventTiming.MISCONFIGURED_MISSING_SETTINGS.format(
                    Constants.ELEMENT_DATA_EVENT_TIMING_END));
                return;
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.EventTiming.MISCONFIGURED.format(Constants.ELEMENT_DATA_EVENT_TIMING_END));
            return;
        }

        // Get start elements
        let startElements = await this._getElement(startEventType, startElementName);
        if (startElements.length === 0) {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.EventTiming.UNABLE_TO_FIND_ELEMENT.format(Constants.ELEMENT_DATA_EVENT_TIMING_START));

            // If we have no start elements return if not deferred or if deferred and in start state (need elements to continue)
            if (!deferred || (deferred && !processing)) {
                return;
            }
        }

        // Get end elements
        let endElements = null;
        if (!deferred || (deferred && processing)) {
            endElements = await this._getElement(endEventType, endElementName);
            if (endElements.length === 0) {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.EventTiming.UNABLE_TO_FIND_ELEMENT.format(Constants.ELEMENT_DATA_EVENT_TIMING_END));

                // No elements and not deferred, return
                if (!deferred) {
                    return;
                }

                // No elements, deferred and in processing state, set up deferral and return (need elements to continue)
                if (deferred && processing) {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.EventTiming.ELEMENT_DEFERRED_FURTHER);
                    DeferralHelper.setupDeferredCall(this._config, true);
                    return;
                }
            }
        }

        let endEventFunc = async function (event) {
            context._endRan = true;
            context._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.EventTiming.PROCESSING_HANDLER.format(
                endKeyStr, endEventType, endElementName, endEvent
            ));

            context._removeEventHandlers(endEvent, endKeyStr, endElements);

            if (deferred) {
                context._startTime = context._config[Constants.ELEMENT_DATA_EVENT_TIMING_DEFERRED_START_TIME];
            }

            let theTiming = Date.now() - context._startTime;
            const copiedElement = context._copyConfig();

            let data = null;
            if (Constants.ELEMENT_DATA_DATA in copiedElement && copiedElement[Constants.ELEMENT_DATA_DATA] !== null) {
                context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.START_PROCESSING.format(Constants.DYNAMIC_DATA_TYPE_EVENTTIMING));
                let processTypeArgs = {};
                processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES] = context._lib.getPageValues();
                processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_EVENT] = event;
                processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_TIMING] = theTiming;
                DynamicDataProcessor.process(copiedElement[Constants.ELEMENT_DATA_DATA], Constants.DYNAMIC_DATA_TYPE_EVENTTIMING, processTypeArgs);
                context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.FINISH_PROCESSING.format(Constants.DYNAMIC_DATA_TYPE_EVENTTIMING));
                data = copiedElement[Constants.ELEMENT_DATA_DATA];
            }

            if (context._checkCondition()) {
                if (data) {
                    let trackingEventType = Constants.EVENT_TYPE_GENERAL;
                    if (Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE in copiedElement && copiedElement[Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE] !== null) {
                        trackingEventType = copiedElement[Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE];
                    }

                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.MAKING_TRACK_EVENT_CALLS.format(trackingEventType, Utils.JSONStringify(data)));
                    context._lib.makeTrackEventCalls(trackingEventType, data);
                } else {
                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.NO_DATA_NOT_TRACKING.format(context._type));
                }
            }
        };

        let startEventFunc = async function (/*event*/) {
            context._startRan = true;
            context._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.EventTiming.PROCESSING_HANDLER.format(
                startKeyStr, startEventType, startElementName, startEvent
            ));

            context._startTime = Date.now();

            context._removeEventHandlers(startEvent, startKeyStr, startElements);

            if (deferred) {
                const copiedElement = context._copyConfig();
                copiedElement[Constants.ELEMENT_DATA_EVENT_TIMING_DEFERRED_START_TIME] = context._startTime;
                context._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.EventTiming.ELEMENT_DEFERRED);
                DeferralHelper.setupDeferredCall(copiedElement);
            } else {
                // Element is not deferred, so we need to set up the end handler here
                if (context._lib.getHandlers(endKeyStr).length === 0) {
                    context._addEventHandlers(endEvent, endKeyStr, endElementName, endElements, endEventFunc);
                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.EventTiming.HANDLER_ADDED.format(endKeyStr));
                } else {
                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.EventTiming.HANDLERS_ALREADY_ADDED.format(
                        endKeyStr, endEventType, endElementName, endEvent
                    ));
                }
            }
        };

        // Set up start handler if the element is not a deferred element or if it is deferred and is not current processing
        if (this._lib.getHandlers(startKeyStr).length === 0) {
            if (!deferred || (deferred && !processing)) {
                this._addEventHandlers(startEvent, startKeyStr, startElementName, startElements, startEventFunc);
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.EventTiming.HANDLER_ADDED.format(startKeyStr));
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.EventTiming.HANDLERS_ALREADY_ADDED.format(
                startKeyStr, startEventType, startElementName, startEvent
            ));
        }

        // Set up the end handler only if the element is deferred and currently processing
        // The not deferred case gets set up in start handler
        if (this._lib.getHandlers(endKeyStr).length === 0) {
            if (deferred && processing) {
                this._addEventHandlers(endEvent, endKeyStr, endElementName, endElements, endEventFunc);
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.EventTiming.HANDLER_ADDED.format(endKeyStr));
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.EventTiming.HANDLERS_ALREADY_ADDED.format(
                endKeyStr, endEventType, endElementName, endEvent
            ));
        }

        this._checkForAlreadyFiredEvents(startEvent, startElementName, startEventFunc, endEvent, endElementName, endEventFunc);
    }

    /**
     * Function to handle any cases that may already have fired events
     *
     * @param {String} startEvent
     * @param {String} startElementName
     * @param {Function} startEventFunc
     * @param {String} endEvent
     * @param {String} endElementName
     * @param {Function} endEventFunc
     */
    _checkForAlreadyFiredEvents(startEvent, startElementName, startEventFunc /*, endEvent, endElementName, endEventFunc*/) {
        // If window.load event fired and it's the start event and start hasn't been run yet, run it now
        if (startEvent === Constants.ELEMENT_DATA_EVENT_TIMING_LOAD_EVENT &&
            startElementName === Constants.ELEMENT_DATA_EVENT_TIMING_WINDOW_ELEMENT &&
            this._startRan === false &&
            this._lib.hasLoadEventFired()
        ) {
            startEventFunc();
        }
    }

    /**
     * Function to get the object to attach event to. Could be a top level object like window or
     * document, or an HTMLEelement.
     *
     * @private
     * @param {string} type
     * @param {string} name
     * @return {Promise<Object[]>}
     */
    async _getElement (type, name) {
        if (type === Constants.ELEMENT_DATA_EVENT_TIMING_TYPE_GLOBAL) {
            return [ window[name] ];
        } else if (type === Constants.ELEMENT_DATA_EVENT_TIMING_TYPE_HTML) {
            return await this._getHTMLElements(name);
        } else {
            return null;
        }
    }

    /**
     * Function to add event handlers to the configured object
     *
     * @private
     * @param {string} event
     * @param {string} keyStr
     * @param {string} elementName
     * @param {Object} elements
     * @param {Fucntion} func
     * @return {void}
     */
    _addEventHandlers (event, keyStr, elementName, elements, func) {
        if (typeof elements.length === Constants.JS_TYPE_NUMBER) {
            for (let e = 0; e < elements.length; e++) {
                this._lib.addHandler(event, keyStr, elements[e], func);
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.ADDED_HANDLER.format(event, Constants.ELEMENT_TYPE_EVENTTIMING, elementName));
            }
        }
    }

    /**
     * Function to remove event handlers from the configured object
     *
     * @private
     * @param {string} event
     * @param {string} keyStr
     * @param {Object[]} elements
     * @return {void}
     */
    _removeEventHandlers (event, keyStr, elements) {
        for (let x = 0; x < elements.length; x++) {
            this._lib.removeHandler(event, keyStr, elements[x]);
        }
    }
}

export default EventTimingElement; // JSDoc workaround for documenting classes