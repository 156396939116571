import { ConsoleStrings } from '../ConsoleStrings';
import IAnalyticsService from './IAnalyticsService';
import Logger from '../Logger';
import Utils from '../Utils';

/**
 * ConsoleTestService implements IAnalyticsService and is basically
 * a "mock" service to be used while developing or debugging a configuration
 * for a web site. This class implements the IAnalyticsService interface
 * and just outputs to the console what was input to each method. It can be
 * useful while testing out configuration that uses complex dynamic data
 * structures to retrieve data from pages.
 *
 * @extends IAnalyticsService
 */
class ConsoleTestService extends IAnalyticsService  {

    /**
     * Constructor for ConsoleTestService
     */
    constructor () {
        super('ConsoleTestService');

        this._logger = Logger.getLogger();
    }

    /**
     * Called at the beginning of the page load to initialize the analytics service
     *
     * @param {AnalyticsServiceConfig} config Configuration object for the AnalyticsService object
     * @param {Object} args Extra arguments to pass in to function.
     * @returns {void}
     */
    init (config, args) {
        this._logger.info(ConsoleStrings.AnalyticsService.ConsoleService.INIT_CALLED); // NOSONAR Console logging should not be used
        this._logger.info(ConsoleStrings.AnalyticsService.ConsoleService.CONFIG.format(Utils.JSONStringify(config))); // NOSONAR Console logging should not be used
        this._logger.info(ConsoleStrings.AnalyticsService.ConsoleService.ARGS.format(Utils.JSONStringify(args))); // NOSONAR Console logging should not be used
    }

    /**
     * Called when an event needs to be tracked
     *
     * @param {string} event The event type to track
     * @param {Object} args The arguments to pass in to function.
     * @returns {void}
     */
    trackEvent (event, args) {
        this._logger.info(ConsoleStrings.AnalyticsService.ConsoleService.TRACK_EVENT_CALLED); // NOSONAR Console logging should not be used
        this._logger.info(ConsoleStrings.AnalyticsService.ConsoleService.EVENT.format(event)); // NOSONAR Console logging should not be used
        this._logger.info(ConsoleStrings.AnalyticsService.ConsoleService.ARGS.format(Utils.JSONStringify(args))); // NOSONAR Console logging should not be used
    }
}

export default ConsoleTestService; // JSDoc workaround for documenting classes