import { load as loadPolyfills } from './Polyfills';
import { load as loadPrototypeAdditions } from './PrototypeAdditions';
import { load as loadPrototypeAdditionsDOM } from './PrototypeAdditionsDOM';

import './PageEvents';
import './CommonDocs';

import { Constants } from './Constants';
import { ConsoleStrings } from './ConsoleStrings';
import DeferralHelper from './DeferralHelper';
import MutationProcessor from './MutationProcessor';
import DynamicDataProcessor from './DynamicDataProcessor';
import CounterManager from './CounterManager';
import StorageUtils from './StorageUtils';
import Logger from './Logger';
import Utils from './Utils';
import ClickableElement from './UsageElementTypes/ClickableElement';
import SubmitableElement from './UsageElementTypes/SubmitableElement';
import PageElement from './UsageElementTypes/PageElement';
import HtmlElement from './UsageElementTypes/HtmlElement';
import MultiElement from './UsageElementTypes/MultiElement';
import ObservableElement from './UsageElementTypes/ObservableElement';
import MediaElement from './UsageElementTypes/MediaElement';
import WindowEventElement from './UsageElementTypes/WindowEventElement';
import EventTimingElement from './UsageElementTypes/EventTimingElement';
import IAnalyticsService from './AnalyticsServices/IAnalyticsService';

/**
 * A class that handles scanning pages for elements to track and retrieving
 * data to send with tracking calls.
 */
class AnalyticsLib {
    /**
     * @property {AnalyticsLibConfig} _config
     * @property {Object} _listenersAdded
     * @property {InputPageValues} _inputPageValues
     * @property {InputInputOptions} _inputOptions
     * @property {Logger} _logger
     * @property {IAnalyticsService[]} _analyticsServiceList
     * @property {AnalyticsElementWatchListObject[]} _analyticsElementWatchList
     * @property {boolean} _analyzePageCalled
     * @property {boolean} _scanStarted
     * @property {boolean} _scanFinished
     * @property {boolean} _preScanStarted
     * @property {boolean} _preScanFinished
     * @property {boolean} _checkInProgress
     * @property {boolean} _DOMContentLoadedEventFired
     * @property {boolean} _loadEventFired
     * @property {boolean} _globalVariablesSetup
     *
     * Constructor for AnalyticsLib
     */
    constructor () {
        this._config;
        this._listenersAdded;
        this._inputPageValues;
        this._inputOptions;
        this._logger;
        this._analyticsServiceList;
        this._analyticsElementWatchList;
        this._analyzePageCalled;
        this._scanStarted;
        this._scanFinished;
        this._preScanStarted;
        this._preScanFinished;
        this._checkInProgress;
        this._DOMContentLoadedEventFired;
        this._loadEventFired;
        this._globalVariablesSetup;
    }

    /**
     * Function to get the lib instance for the current page
     *
     * @static
     * @returns {AnalyticsLib}
    */
    static getLib () {
        if (window._wkUsageAnalyticsLib) {
            return window._wkUsageAnalyticsLib;
        } else {
            return null;
        }
    }

    /**
     * A function that should be called at the begining of a page to set
     * anything up that can happen before the page fully loads. This is
     * the only public method in the class and as such should be the only
     * method a user calls.
     *
     * @param {AnalyzePageArgs} args
     * @returns {void}
     */
    analyzePage (args) {
        this._initialize();

        this._logger.debug(ConsoleStrings.AnalyticsLib.AnalyzePage.ENTER_FUNCTION.format(Utils.JSONStringify(args)));

        this._initAnalyticsService();

        if (this._analyticsServiceList.length > 0) {
            if (typeof args !== Constants.JS_TYPE_UNDEFINED && args !== null && Constants.ANALYZE_PAGE_INPUT_PAGEVALUES in args) {
                this._inputPageValues = args[Constants.ANALYZE_PAGE_INPUT_PAGEVALUES];
                delete args[Constants.ANALYZE_PAGE_INPUT_PAGEVALUES];
            }

            if (typeof args !== Constants.JS_TYPE_UNDEFINED && args !== null && Constants.ANALYZE_PAGE_INPUT_OPTIONS in args) {
                this._inputOptions = args[Constants.ANALYZE_PAGE_INPUT_OPTIONS];
                delete args[Constants.ANALYZE_PAGE_INPUT_OPTIONS];
            }

            this._setupGlobalVariables();
            this._setupGlobalCounters();
        } else {
            this._logger.warn(ConsoleStrings.AnalyticsLib.AnalyzePage.NO_SERVICE_NOT_ANALYZING);
        }

        this._analyzePageCalled = true;
    }

    /**
     * Public function used in config files to make it easier to properly set up the config object.
     *
     * @param {Object} config
     * @returns {void}
     */
    configSetup (config) {
        if (typeof window._wkUsageAnalyticsLibConfig === Constants.JS_TYPE_UNDEFINED) {
            // If the main config variable hasn't been set up yet, set the input config object to it
            window._wkUsageAnalyticsLibConfig = config;
        } else {
            // If the main config variable has been set up, go through each key in
            // the input config object and either merge or replace in the main variable
            for (const newKey in config) {
                // If the key is already in the main variable, merge/replace depending on which key
                if (newKey in window._wkUsageAnalyticsLibConfig) {
                    switch (newKey) {
                        // analyticsServices copies from new config to main var, overwriting anything it encounters
                        case Constants.CONFIG_ANALYTICS_SERVICES:
                        {
                            for (const newServiceKey in config[Constants.CONFIG_ANALYTICS_SERVICES]) {
                                window._wkUsageAnalyticsLibConfig[Constants.CONFIG_ANALYTICS_SERVICES][newServiceKey] =
                                    config[Constants.CONFIG_ANALYTICS_SERVICES][newServiceKey];
                            }
                            break;
                        }

                        // loggerOptions copies from new config to main var, overwriting anything it encounters
                        case Constants.CONFIG_LOGGER_OPTIONS:
                        {
                            for (const newLoggerKey in config[Constants.CONFIG_LOGGER_OPTIONS]) {
                                window._wkUsageAnalyticsLibConfig[Constants.CONFIG_LOGGER_OPTIONS][newLoggerKey] = config[Constants.CONFIG_LOGGER_OPTIONS][newLoggerKey];
                            }
                            break;
                        }

                        // elements merges the arrays from the new config and the main config var
                        case Constants.CONFIG_ELEMENTS:
                        {
                            if (Array.isArray(window._wkUsageAnalyticsLibConfig[Constants.CONFIG_ELEMENTS]) && Array.isArray(config[Constants.CONFIG_ELEMENTS])) {
                                window._wkUsageAnalyticsLibConfig[Constants.CONFIG_ELEMENTS] = [
                                    ...window._wkUsageAnalyticsLibConfig[Constants.CONFIG_ELEMENTS],
                                    ...config[Constants.CONFIG_ELEMENTS]
                                ];
                            }
                            break;
                        }

                        default:
                        {
                            break;
                        }
                    }
                } else {
                    // If the key is not already in the main var, add it now
                    window._wkUsageAnalyticsLibConfig[newKey] = config[newKey];
                }
            }
        }
    }

    /**
     * Function for users to direclty check the page by initiating a rescan of the page.
     *
     * This is intended to be used in asynchronous application pages when new content has been
     * loaded so that the app can instruct UAL to rescan the page and handle configured elements.
     *
     * @async
     * @param {AnalyzePageArgs} args
     * @returns {Promise}
     */
    async check (args) {
        let context = this;

        return new Promise(async function (resolve /*, reject*/) {
            // Make sure no other calls to check are in progress
            let interval = setInterval(async function () {
                if (context._checkInProgress === false) {
                    context._checkInProgress = true;
                    clearInterval(interval);

                    context._logger.debug(ConsoleStrings.AnalyticsLib.Check.CHECK_PROCESSING.format(Utils.JSONStringify(args)));

                    if (context._analyzePageCalled) {
                        if (typeof args !== Constants.JS_TYPE_UNDEFINED && args !== null && Constants.ANALYZE_PAGE_INPUT_PAGEVALUES in args) {
                            context._inputPageValues = args[Constants.ANALYZE_PAGE_INPUT_PAGEVALUES];
                            delete args[Constants.ANALYZE_PAGE_INPUT_PAGEVALUES];
                        }

                        if (typeof args !== Constants.JS_TYPE_UNDEFINED && args !== null && Constants.ANALYZE_PAGE_INPUT_OPTIONS in args) {
                            context._inputOptions = args[Constants.ANALYZE_PAGE_INPUT_OPTIONS];
                            delete args[Constants.ANALYZE_PAGE_INPUT_OPTIONS];
                        }

                        await context.scanPage();
                    } else {
                        context._logger.trace(ConsoleStrings.AnalyticsLib.AnalyzePage.NOT_CALLED_NO_SCAN);
                    }

                    context._logger.trace(ConsoleStrings.AnalyticsLib.Check.CHECK_FINISHED_PROCESSING);

                    context._checkInProgress = false;
                    resolve();
                } else {
                    context._logger.trace(ConsoleStrings.AnalyticsLib.Check.CHECK_NOT_FINISHED);
                }
            }, Constants.CHECK_INTERVAL);
        });
    }

    /**
     * Function that initializes AnalyticsLib member variables
     *
     * @private
     * @returns {void}
     */
    _initialize () {
        loadPolyfills();
        loadPrototypeAdditions();
        loadPrototypeAdditionsDOM();

        this._config = window._wkUsageAnalyticsLibConfig;

        let configUndefined = false;
        if (typeof this._config === Constants.JS_TYPE_UNDEFINED) {
            configUndefined = true;
            this._config = {};
        }

        this._listenersAdded = {};

        this._inputPageValues = {};
        this._inputOptions = null;

        this._logger = null;
        let loggerOptions = {};
        if (Constants.CONFIG_LOGGER_OPTIONS in this._config) {
            loggerOptions = this._config[Constants.CONFIG_LOGGER_OPTIONS];
        }

        let suffixOn = false;
        if (Constants.CONFIG_LOG_SUFFIX_ON in loggerOptions && loggerOptions[Constants.CONFIG_LOG_SUFFIX_ON]) {
            suffixOn = true;
        }

        let level = Constants.LOGGER_LEVELS_ENUM.NORMAL;
        if (Constants.CONFIG_LOG_LEVEL in loggerOptions) {
            const levelStr = loggerOptions[Constants.CONFIG_LOG_LEVEL].toUpperCase();
            if (levelStr in Constants.LOGGER_LEVELS_ENUM) {
                level = Constants.LOGGER_LEVELS_ENUM[levelStr];
            }
        }

        // Check sessionStorage for user override of log level (wk.ual.logLevel)
        const storageObj = StorageUtils.getSessionStorageObject();
        if (storageObj) {
            let userLogLevelOverride = storageObj.getItem(Constants.SESSION_STORAGE_LOG_LEVEL);
            if (userLogLevelOverride) {
                userLogLevelOverride = userLogLevelOverride.toUpperCase();
                if (userLogLevelOverride in Constants.LOGGER_LEVELS_ENUM) {
                    level = Constants.LOGGER_LEVELS_ENUM[userLogLevelOverride];
                }
            }
        }

        this._logger = Logger.getLogger(level, suffixOn);

        // Now that we have a Logger, output a warning if configuration object is bad.
        if (configUndefined) {
            this._logger.warn(ConsoleStrings.Config.Object.UNDEFINED_OR_NOT_PARSEABLE);
        }

        // Before doing anything with the elements in memory, get a unique hash code for it so it can be identified later
        if (Constants.CONFIG_ELEMENTS in this._config && this._config[Constants.CONFIG_ELEMENTS] !== null) {
            if (Array.isArray(this._config[Constants.CONFIG_ELEMENTS])) {
                this._logger.trace(ConsoleStrings.AnalyticsLib.Initialize.START_GENERATING_HASH);
                for (let elIndex = 0; elIndex < this._config[Constants.CONFIG_ELEMENTS].length; elIndex++) {
                    const curElement = this._config[Constants.CONFIG_ELEMENTS][elIndex];
                    if (typeof curElement !== Constants.JS_TYPE_UNDEFINED && curElement !== null) {
                        curElement[Constants.ELEMENT_DATA_HASH_ID] = Utils.JSONStringify(curElement).hashCode();
                    }
                }
                this._logger.trace(ConsoleStrings.AnalyticsLib.Initialize.FINISH_GENERATING_HASH);
            }
        }

        // Set to empty array for now, will initialize when user calls analyzePage so custom services can be supported
        this._analyticsServiceList = [];

        this._analyticsElementWatchList = [];

        this._analyzePageCalled = false;
        this._scanStarted = false;
        this._scanFinished = false;
        this._preScanStarted = false;
        this._preScanFinished = false;
        this._checkInProgress = false;
        this._DOMContentLoadedEventFired = false;
        this._loadEventFired = false;
        this._globalVariablesSetup = false;
    }

    /**
     * A function to initialize the _analyticsServiceList member variable.
     *
     * @private
     * @returns {void}
     */
    _initAnalyticsService () {
        if (typeof(this._config) !== Constants.JS_TYPE_UNDEFINED
            && Constants.CONFIG_ANALYTICS_SERVICES in this._config
            && this._config[Constants.CONFIG_ANALYTICS_SERVICES] !== null) {
            try {
                let configuredServices = this._config[Constants.CONFIG_ANALYTICS_SERVICES];
                if (typeof configuredServices === Constants.JS_TYPE_OBJECT) {
                    let serviceNum = 0;
                    for (const s in configuredServices) {
                        const curService = s;
                        this._analyticsServiceList.push(new window[curService]());

                        if (!(this._analyticsServiceList[serviceNum] instanceof IAnalyticsService)) {
                            this._logger.warn(ConsoleStrings.AnalyticsLib.InitService.NOT_DERIVED_FROM_INTERFACE.format(curService));
                            throw new TypeError('Analytics service object is not derived from IAnalyticsService.');
                        }

                        this._analyticsServiceList[serviceNum].init(configuredServices[curService], null);
                        serviceNum++;
                    }
                } else {
                    this._logger.warn(ConsoleStrings.AnalyticsLib.InitService.ANALYTICS_SERVICES_NOT_OBJ);
                }
            } catch (e) {
                if (e instanceof TypeError) {
                    this._logger.warn('A configured analytics service is not properly derived from IAnalyticsService.');
                }

                this._logger.error(e.stack);
                this._analyticsServiceList = [];
            }
        } else {
            this._logger.error(ConsoleStrings.AnalyticsLib.InitService.CANNOT_FUNCTION_WITHOUT_SERVICE);
        }
    }

    /**
     * A function that is called at the 'load' event firing which is
     * when the page is loaded. It attaches events to specified elements and
     * calls desired track call for the page.
     *
     * @async
     * @param {boolean} track
     * @returns {Promise}
     */
    async scanPage (track) {
        this._scanStarted = true;

        this._logger.debug(ConsoleStrings.AnalyticsLib.ScanPage.START_SCAN);

        if (this._analyticsServiceList.length > 0) {
            // Process any deferred calls we have
            await DeferralHelper.processDeferredCalls();

            // Process the configured elements that need to be processed in the "load" handler
            await this.handleElements(track);
        } else {
            this._logger.warn(ConsoleStrings.AnalyticsLib.ScanPage.NO_SERVICE_NO_SCAN);
        }

        this._scanFinished = true;
    }

    /**
     * A function that is called at the 'DOMContentLoaded' event firing which is
     * when the page is partially loaded. It attaches handlers to specified elements.
     *
     * @private
     * @async
     * @param {boolean} track
     * @returns {Promise}
     */
    async _preScanPage () {
        this._preScanStarted = true;

        this._logger.debug(ConsoleStrings.AnalyticsLib.PreScanPage.START_PRESCAN);

        if (this._analyticsServiceList.length > 0) {
            // Preprocess deferred calls to check for in progress multi items that may have click handlers to set up
            await DeferralHelper.preProcessDeferredCalls();

            // Reset the mutation observer
            this._resetObserver();

            // Process elements that can be handled early
            await this._handlePreScanElements();
        } else {
            this._logger.warn(ConsoleStrings.AnalyticsLib.PreScanPage.NO_SERVICE_NO_PRESCAN);
        }

        this._preScanFinished = true;
    }

    /**
     * Function that resets the mutation observer
     *
     * @private
     * @returns {void}
     */
    _resetObserver () {
        this._logger.trace(ConsoleStrings.MutationObserver.RESET_OBSERVER);
        this._analyticsElementWatchList = [];
        MutationProcessor.reset();
    }

    /**
     * Function to get an identifying key string for an AnalyticsElement
     *
     * @param {AnalyticsElement} analyticsElement
     * @returns {string}
     */
    getElementKeyString (analyticsElement) {
        if (Constants.ELEMENT_DATA_HASH_ID in analyticsElement) {
            return analyticsElement[Constants.ELEMENT_DATA_HASH_ID].toString();
        } else {
            return Constants.ELEMENT_DATA_HASH_ID_NO_ID;
        }
    }

    /**
     * Function to handle elements in the pre-scan
     *
     * @private
     * @async
     * @returns {Promise}
     */
    async _handlePreScanElements () {
        // Go through elements and attach events to any found
        if (Constants.CONFIG_ELEMENTS in this._config && this._config[Constants.CONFIG_ELEMENTS] !== null) {
            this._logger.trace(ConsoleStrings.AnalyticsLib.PreScanPage.PROCESSING_ELEMENTS);
            if (Array.isArray(this._config[Constants.CONFIG_ELEMENTS])) {
                const elementPromiseArray = [];
                for (var index = 0; index < this._config[Constants.CONFIG_ELEMENTS].length; index++) {
                    const curElement = this._config[Constants.CONFIG_ELEMENTS][index];
                    if (typeof curElement !== Constants.JS_TYPE_UNDEFINED && curElement !== null) {
                        if (!(Constants.ELEMENT_DATA_TYPE in curElement) || curElement[Constants.ELEMENT_DATA_TYPE] === null) {
                            this._logger.trace(ConsoleStrings.Config.Element.MUST_HAVE_TYPE);
                            continue;
                        }

                        // Get the type, and if multi, get the initial multiselector type
                        let type = curElement[Constants.ELEMENT_DATA_TYPE];
                        if (type === Constants.ELEMENT_TYPE_MULTI && Constants.ELEMENT_DATA_SELECTOR in curElement) {
                            const multiSelector = curElement[Constants.ELEMENT_DATA_SELECTOR];
                            if (multiSelector.length > 0 && Constants.ELEMENT_DATA_TYPE in multiSelector[0]) {
                                type = multiSelector[0][Constants.ELEMENT_DATA_TYPE];
                            }
                        }

                        // If it's an element type that is in our pre-scan list, handle it now
                        if (Constants.PRE_SCAN_ELEMENT_TYPES.indexOf(type) !== -1) {
                            elementPromiseArray.push(this.handleElement(curElement));
                        }
                    } else {
                        this._logger.trace(ConsoleStrings.Config.Element.NULL_UNDEFINED);
                    }
                }

                try {
                    await Promise.all(elementPromiseArray);
                } catch (e) {
                    this._logger.error(e.stack);
                }

                this._logger.trace(ConsoleStrings.AnalyticsLib.PreScanPage.PROCESSING_ELEMENTS_FINISH);
            } else {
                this._logger.trace(ConsoleStrings.Config.Element.MUST_BE_ARRAY);
            }
        } else {
            this._logger.trace(ConsoleStrings.Config.Element.NO_ELEMENTS);
        }
    }

    /**
     * A function that handles the configured elements (other than early elements) by looping through the config
     * object and starting the individual asynchronous element handling tasks.
     *
     * @private
     * @async
     * @param {boolean} track
     * @returns {Promise}
     */
    async handleElements (track) {
        // Go through elements and attach events to any found
        if (Constants.CONFIG_ELEMENTS in this._config && this._config[Constants.CONFIG_ELEMENTS] !== null) {
            this._logger.debug(ConsoleStrings.AnalyticsLib.HandleElements.PROCESSING_ELEMENTS);
            if (Array.isArray(this._config[Constants.CONFIG_ELEMENTS])) {
                const elementPromiseArray = [];
                for (var index = 0; index < this._config[Constants.CONFIG_ELEMENTS].length; index++) {
                    const curElement = this._config[Constants.CONFIG_ELEMENTS][index];
                    if (typeof curElement !== Constants.JS_TYPE_UNDEFINED && curElement !== null) {
                        if (!(Constants.ELEMENT_DATA_TYPE in curElement) || curElement[Constants.ELEMENT_DATA_TYPE] === null) {
                            this._logger.trace(ConsoleStrings.Config.Element.MUST_HAVE_TYPE);
                            continue;
                        }
                        elementPromiseArray.push(this.handleElement(curElement, track));
                    } else {
                        this._logger.trace(ConsoleStrings.Config.Element.NULL_UNDEFINED);
                    }
                }

                try {
                    await Promise.all(elementPromiseArray);
                } catch (e) {
                    this._logger.error(e.stack);
                }

                this._logger.debug(ConsoleStrings.AnalyticsLib.HandleElements.PROCESSING_ELEMENTS_FINISH);
            } else {
                this._logger.trace(ConsoleStrings.Config.Element.MUST_BE_ARRAY);
            }
        } else {
            this._logger.trace(ConsoleStrings.Config.Element.NO_ELEMENTS);
        }
    }

    /**
     * A function that does the work for handling an element in an async function
     * that returns a Promise which is awaitable.
     *
     * @async
     * @param {AnalyticsElement} analyticsElement
     * @param {boolean} track
     * @param {ObservableElement} observableElement
     * @returns {Promise}
     */
    async handleElement (analyticsElement, track = false, observableElement = null) {
        const type = analyticsElement[Constants.ELEMENT_DATA_TYPE];

        let elementLabel = this.getLoggingElementPrefix(analyticsElement);

        if (observableElement) {
            this._resetHandlers(analyticsElement);
        }

        this._logger.debug(ConsoleStrings.AnalyticsLib.HandleElements.START_HANDLING_ELEMENT.format(elementLabel));

        let element = null;
        if (type === Constants.ELEMENT_TYPE_CLICKABLE) {
            element = new ClickableElement(analyticsElement);
        } else if (type === Constants.ELEMENT_TYPE_SUBMITABLE) {
            element = new SubmitableElement(analyticsElement);
        } else if (type === Constants.ELEMENT_TYPE_PAGE) {
            element = new PageElement(analyticsElement, track);
        } else if (type === Constants.ELEMENT_TYPE_HTML) {
            element = new HtmlElement(analyticsElement);
        } else if (type === Constants.ELEMENT_TYPE_MULTI) {
            element = new MultiElement(analyticsElement);
        } else if (type === Constants.ELEMENT_TYPE_OBSERVABLE) {
            element = new ObservableElement(analyticsElement);
        } else if (type === Constants.ELEMENT_TYPE_MEDIA) {
            element = new MediaElement(analyticsElement);
        } else if (type === Constants.ELEMENT_TYPE_WINDOWEVENT) {
            element = new WindowEventElement(analyticsElement);
        } else if (type === Constants.ELEMENT_TYPE_EVENTTIMING) {
            element = new EventTimingElement(analyticsElement);
        } else {
            this._logger.warn(ConsoleStrings.AnalyticsLib.HandleElements.UNHANDLED_ELEMENT_TYPE.format(elementLabel, type));
        }

        if (element) {
            try {
                await element.handle(observableElement);
            } catch (e) {
                this._logger.warn(ConsoleStrings.AnalyticsLib.HandleElements.EXCEPTION_CAUGHT.format(elementLabel));
                this._logger.error(e.stack);
            }
        }

        this._logger.debug(ConsoleStrings.AnalyticsLib.HandleElements.FINISH_HANDLING_ELEMENT.format(elementLabel));
    }

    /**
     * A function that iterates over the list of analytics services and makes a trackEvent
     * call on all of them with the given event type and data.
     *
     * @param {string} eventType
     * @param {Object} data
     * @returns {void}
     */
    makeTrackEventCalls (eventType, data) {
        if (data) {
            // Find any global data that needs to be entered
            this.insertGlobalVariables(data);

            // Before tracking, remove any unprocessed dynamic data objects
            DynamicDataProcessor.clearUnprocessedObjects(data);

            for (let s = 0; s < this._analyticsServiceList.length; s++) {
                let serviceName = 'Undefined Service Name';
                if (this._analyticsServiceList[s]._classname !== undefined) {
                    serviceName = this._analyticsServiceList[s]._classname;
                }

                this._logger.debug(ConsoleStrings.AnalyticsLib.TrackEvent.PROCESSING_ELEMENT_WITH_SERVICE.format(serviceName));
                this._logger.debug(ConsoleStrings.AnalyticsLib.TrackEvent.CALLING_TRACK_EVENT);
                this._logger.debug(ConsoleStrings.AnalyticsLib.TrackEvent.EVENT_TYPE.format(eventType));
                this._logger.debug(ConsoleStrings.AnalyticsLib.TrackEvent.ELEMENT_DATA.format(Utils.JSONStringify(data)));
                this._analyticsServiceList[s].trackEvent(eventType, data);
            }
        } else {
            this._logger.warn(ConsoleStrings.AnalyticsLib.TrackEvent.NO_DATA_NOT_TRACKING);
        }
    }

    /**
     * Inserts global data in to data object before tracking
     *
     * @param {Object} data
     */
    insertGlobalVariables (data) {
        if (Constants.CONFIG_GLOBALS in this._config && this._config[Constants.CONFIG_GLOBALS] !== null) {
            let globals = this._config[Constants.CONFIG_GLOBALS];
            if (Constants.CONFIG_GLOBAL_VARIABLES in globals && globals[Constants.CONFIG_GLOBAL_VARIABLES] !== null) {
                let globalVars = globals[Constants.CONFIG_GLOBAL_VARIABLES];
                for (let k in data) {
                    if (typeof data[k] === Constants.JS_TYPE_OBJECT) {
                        this.insertGlobalVariables(data[k]);
                    } else {
                        if (typeof data[k] === Constants.JS_TYPE_STRING) {
                            if (data[k].charAt(0) === Constants.GLOBAL_VARIABLES_INDICATOR &&
                                data[k].charAt(data[k].length-1) === Constants.GLOBAL_VARIABLES_INDICATOR) {
                                let thisVarName = data[k].substring(1, data[k].length-1);
                                if (thisVarName in globalVars) {
                                    data[k] = globalVars[thisVarName];
                                } else {
                                    this._logger.warn(ConsoleStrings.AnalyticsLib.TrackEvent.NO_GLOBAL_WITH_NAME_AVAILABLE.format(thisVarName));
                                    data[k] = '';
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Set up global variables by processing dynamic data in them
     *
     * @private
     * @returns {void}
     */
    _setupGlobalVariables () {
        if (!this._globalVariablesSetup) {
            // Get dynamic data for globals
            if (Constants.CONFIG_GLOBALS in this._config && this._config[Constants.CONFIG_GLOBALS] !== null) {
                let globals = this._config[Constants.CONFIG_GLOBALS];
                if (Constants.CONFIG_GLOBAL_VARIABLES in globals && globals[Constants.CONFIG_GLOBAL_VARIABLES] !== null) {
                    this._logger.trace(ConsoleStrings.DynamicData.START_PROCESSING_GLOBALS.format(Constants.DYNAMIC_DATA_TYPE_GLOBAL));
                    let processTypeArgs = {};
                    processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES] = this.getPageValues();
                    DynamicDataProcessor.process(globals[Constants.CONFIG_GLOBAL_VARIABLES], Constants.DYNAMIC_DATA_TYPE_GLOBAL, processTypeArgs);
                    this._logger.trace(ConsoleStrings.DynamicData.FINISH_PROCESSING_GLOBALS.format(Constants.DYNAMIC_DATA_TYPE_GLOBAL));

                    // Clear any unprocessed globals (logs a warning if so)
                    DynamicDataProcessor.clearUnprocessedObjects(globals[Constants.CONFIG_GLOBAL_VARIABLES]);
                }
            }
            this._globalVariablesSetup = true;
        }
    }

    _setupGlobalCounters () {
        if (Constants.CONFIG_GLOBALS in this._config && this._config[Constants.CONFIG_GLOBALS] !== null) {
            let globals = this._config[Constants.CONFIG_GLOBALS];
            if (Constants.COUNTERS_GLOBAL_PROPERTY in globals) {
                let counters = globals[Constants.COUNTERS_GLOBAL_PROPERTY];
                for (let counterName in counters) {
                    CounterManager.createCounter(counterName, counters[counterName]);
                }
            }
        }
    }

    /**
     * Function to remove handlers for a configured element
     *
     * @private
     * @param {UsageElement} element
     * @param {String} keyStr
     */
    _resetHandlers (element, keyStr = null) {
        if (Constants.ELEMENT_DATA_TYPE in element && element[Constants.ELEMENT_DATA_TYPE] !== null) {
            const type = element[Constants.ELEMENT_DATA_TYPE];
            if (Constants.ELEMENT_TYPES_USING_REMOVABLE_EVENT_HANDLERS.indexOf(type) !== -1) {
                let displayStr = this.getLoggingElementPrefix(element);
                if (keyStr === null) {
                    keyStr = this.getElementKeyString(element);
                }
                this._logger.trace(ConsoleStrings.AnalyticsLib.Handlers.RESETTING_HANDLERS.format(displayStr));

                if (keyStr in this._listenersAdded) {
                    if (type === Constants.ELEMENT_TYPE_CLICKABLE || type === Constants.ELEMENT_TYPE_SUBMITABLE) {
                        if (Constants.ELEMENT_DATA_SELECTOR in element) {
                            let elements = [];
                            try {
                                elements = document.querySelectorAllDeep(element[Constants.ELEMENT_DATA_SELECTOR]);
                            } catch (e) {
                                this._logger.warn(ConsoleStrings.Element.FAILED_TO_QUERY_SELECTOR.format(element[Constants.ELEMENT_DATA_SELECTOR]));
                                this._logger.error(e.stack);
                            }

                            let eventListenerType = null;
                            if (type === Constants.ELEMENT_TYPE_CLICKABLE) {
                                eventListenerType = Constants.EVENT_LISTENER_TYPE_CLICK;
                            } else if (type === Constants.ELEMENT_TYPE_SUBMITABLE) {
                                eventListenerType = Constants.EVENT_LISTENER_TYPE_SUBMIT;
                            }

                            if (eventListenerType) {
                                for (let e = 0; e < elements.length; e++) {
                                    this.removeHandler(eventListenerType, keyStr, elements[e]);
                                }
                            }
                        }
                    } else if (type === Constants.ELEMENT_TYPE_MEDIA) {
                        if (Constants.ELEMENT_DATA_SELECTOR in element) {
                            let elements = [];
                            try {
                                elements = document.querySelectorAllDeep(element[Constants.ELEMENT_DATA_SELECTOR]);
                            } catch (e) {
                                this._logger.warn(ConsoleStrings.Element.FAILED_TO_QUERY_SELECTOR.format(element[Constants.ELEMENT_DATA_SELECTOR]));
                                this._logger.error(e.stack);
                            }

                            let eventListenerTypePlay = Constants.EVENT_LISTENER_TYPE_PLAY;
                            let eventListenerTypeEnded = Constants.EVENT_LISTENER_TYPE_ENDED;
                            let eventListenerTypeTimeUpdate = Constants.EVENT_LISTENER_TYPE_TIMEUPDATE;

                            for (let e = 0; e < elements.length; e++) {
                                this.removeHandler(eventListenerTypePlay, keyStr + Constants.MEDIA_KEY_STRING_SUFFIX_PLAY, elements[e]);
                                this.removeHandler(eventListenerTypeEnded, keyStr + Constants.MEDIA_KEY_STRING_SUFFIX_ENDED, elements[e]);
                                this.removeHandler(eventListenerTypeTimeUpdate, keyStr + Constants.MEDIA_KEY_STRING_SUFFIX_TIMEUPDATE, elements[e]);
                            }
                        }
                    } else if (type === Constants.ELEMENT_TYPE_WINDOWEVENT) {
                        let eventType = null;
                        if (Constants.ELEMENT_DATA_WINDOW_EVENT_EVENT_TYPE in element) {
                            eventType = element[Constants.ELEMENT_DATA_WINDOW_EVENT_EVENT_TYPE];
                        }

                        if (eventType) {
                            this.removeHandler(eventType, keyStr, window);
                        }
                    }

                    // Remove any extra handlers that may still be around (for times when elements have dynamic number of handlers)
                    if (this._listenersAdded[keyStr].length) {
                        this._logger.trace(ConsoleStrings.AnalyticsLib.Handlers.RESETTING_EXTRA_HANDLERS.format(displayStr));
                        this._listenersAdded[keyStr].splice(0, this._listenersAdded[keyStr].length);
                    }
                }
            }
        }
    }

    /**
     * A function that resets handlers on all configured elements that use event handlers
     *
     * @returns {void}
     */
    resetAllHandlers () {
        // First remove from all configured elements
        const configuredElements = this._config[Constants.CONFIG_ELEMENTS];
        if (Array.isArray(configuredElements)) {
            for (let index = 0; index < configuredElements.length; index++) {
                const thisElement = configuredElements[index];
                this._resetHandlers(thisElement);
            }
        }

        // Then remove handlers from multi elements that are currently deferred
        let deferredTrackingCallsArray = DeferralHelper.getDeferredCallsArray();
        for (let d = 0; d < deferredTrackingCallsArray.length; d++) {
            const deferredElement = deferredTrackingCallsArray[d];
            const deferredElementKeyStr = this.getElementKeyString(deferredElement);

            let processing = DeferralHelper.isElementProcessingDeferral(deferredElement);

            let type = null;
            if (Constants.ELEMENT_DATA_TYPE in deferredElement && deferredElement[Constants.ELEMENT_DATA_TYPE] !== null) {
                type = deferredElement[Constants.ELEMENT_DATA_TYPE];

                if (processing && type === Constants.ELEMENT_TYPE_MULTI) {
                    if (Constants.ELEMENT_DATA_SELECTOR in deferredElement && deferredElement[Constants.ELEMENT_DATA_SELECTOR] !== null) {
                        const multiList = deferredElement[Constants.ELEMENT_DATA_SELECTOR];
                        if (Array.isArray(multiList) && multiList.length) {
                            const curMultiElement = multiList[0];
                            this._resetHandlers(curMultiElement, deferredElementKeyStr);
                        }
                    }
                }
            }
        }
    }

    /**
     * Function to remove event handlers from an HTML element. Latest added handler is
     * removed from element input to function and is also removed from handler array.
     *
     * @param {String} type
     * @param {String} key
     * @param {HTMLElement} element
     * @return {void}
     */
    removeHandler (type, key, element) {
        if (key in this._listenersAdded && this._listenersAdded[key].length) {
            let attrName = Constants.ELEMENT_HANDLER_PROPERTY_CHECK_NAME_PREFIX + key;
            if (element instanceof Element && element.getAttribute(attrName) === Constants.TRUE_STRING) {
                element.removeEventListener(type, this._listenersAdded[key].pop());

                if (this._listenersAdded[key].length === 0) {
                    element.removeAttribute(attrName);
                }
            } else if (element === window) {
                element.removeEventListener(type, this._listenersAdded[key].pop());
            } else {
                this._logger.trace(ConsoleStrings.AnalyticsLib.Handlers.REMOVE_BEFORE_SETUP);
                this._listenersAdded[key].pop();
            }
        } else {
            this._logger.trace(ConsoleStrings.AnalyticsLib.Handlers.REMOVE_WITH_NONEXISTENT_KEY);
        }
    }

    /**
     * Function to add event handlers to an HTML element
     *
     * @param {String} type
     * @param {String} key
     * @param {HTMLElement} element
     * @param {Function} handler
     * @return {void}
     */
    addHandler (type, key, element, handler) {
        element.addEventListener(type, handler);
        if (!(key in this._listenersAdded)) {
            this._listenersAdded[key] = [];
        }
        this._listenersAdded[key].push(handler);

        if (element instanceof Element) {
            let attrName = Constants.ELEMENT_HANDLER_PROPERTY_CHECK_NAME_PREFIX + key;
            element.setAttribute(attrName, Constants.TRUE_STRING);
        }
    }

    /**
     * Function to get the event handlers already added for a given key
     *
     * @param {String} key
     * @returns {Array.Function}
     */
    getHandlers (key) {
        if (key in this._listenersAdded) {
            return this._listenersAdded[key];
        } else {
            this._logger.trace(ConsoleStrings.AnalyticsLib.Handlers.GET_WITH_NONEXISTENT_KEY);
            return [];
        }
    }

    /**
     * Gets the page values object
     *
     * @returns {InputPageValues}
     */
    getPageValues () {
        return this._inputPageValues;
    }

    /**
     * Gets the analytics element watch list object
     *
     * @returns {Array.AnalyticsElementWatchListObject}
     */
    getAnalyticsElementWatchList () {
        return this._analyticsElementWatchList;
    }

    /**
     * Push an element to the watch list
     *
     * @param {AnalyticsElementWatchListObject} element
     * @returns {void}
     */
    addToAnalyticsElementWatchList (element) {
        this._analyticsElementWatchList.push(element);
    }

    /**
     * Remove an element from the watch list
     *
     * @param {AnalyticsElementWatchListObject} element
     * @returns {void}
     */
    removeFromAnalyticsElementWatchList (element) {
        let index = this._analyticsElementWatchList.map(function (e) {
            return e.selector;
        }).indexOf(element.selector);
        if (index !== -1) {
            this._analyticsElementWatchList.splice(index, 1);
        }
    }

    /**
     * Add an observation to the muation observer
     *
     * @param {HTMLElement} target
     * @param {Object} options
     * @returns {void}
     */
    addObservation (target, options) {
        this._logger.trace(ConsoleStrings.AnalyticsLib.Observer.SETTING_UP_WITH_OPTIONS.format(Utils.JSONStringify(options)));
        MutationProcessor.set(target, options);
    }

    /**
     * Function to generate a prefix to log for a particular element
     *
     * @param {AnalyticsElement} element
     * @returns {string} Prefix string to log based on input element
     */
    getLoggingElementPrefix (element) {
        let description = null;
        if (Constants.ELEMENT_DATA_DESCRIPTION in element) {
            description = element[Constants.ELEMENT_DATA_DESCRIPTION];
        }

        let type = null;
        if (Constants.ELEMENT_DATA_TYPE in element) {
            type = element[Constants.ELEMENT_DATA_TYPE];
        }

        let selector = null;
        if (Constants.ELEMENT_DATA_SELECTOR in element) {
            selector = element[Constants.ELEMENT_DATA_SELECTOR];
        }

        let logPrefix = '[';
        if (description) {
            logPrefix += ('<' + description + '> ');
        }
        if (type) {
            logPrefix += ('<Type: ' + type + '>');
        }
        if (selector) {
            logPrefix += ' <Selector:';
            if (Array.isArray(selector)) {
                for (let x = 0; x < selector.length; x++) {
                    if (typeof selector[x] === Constants.JS_TYPE_OBJECT) {
                        logPrefix += (' ' + Utils.JSONStringify(selector[x]));
                    } else {
                        logPrefix += (' ' + selector[x]);
                    }
                }
            } else {
                logPrefix += (' ' + selector);
            }
            logPrefix += '>';
        }
        logPrefix += ']';

        return logPrefix;
    }

    /**
     * Returns if the window load event has fired
     *
     * @returns {boolean}
     */
    hasLoadEventFired () {
        return this._loadEventFired;
    }

    /**
     * Returns if the document DOMContentLoaded event has fired
     *
     * @returns {boolean}
     */
    hasDOMContentLoadedEventFired () {
        return this._DOMContentLoadedEventFired;
    }
}

export default AnalyticsLib; // JSDoc workaround for documenting classes
