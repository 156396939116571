import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import UsageElement from './UsageElement';
import DynamicDataProcessor from '../DynamicDataProcessor';
import Utils from '../Utils';

/**
 * Class for tracking elements that are observable
 * @extends UsageElement
 */
class ObservableElement extends UsageElement {

    /**
     * Constructor for ObservableElement
     * @param {Object} elementConfig
     */
    constructor (elementConfig) {
        super('ObservableElement', elementConfig);
    }

    /**
     * Function to hanldle the deferred element
     *
     * @async
     * @param {ObservableElement} observableElement
     * @returns {Promise}
     */
    async handle (observableElement) {
        await super.handle(observableElement);

        if (!this._checkPreCondition()) {
            return;
        }

        this._initializeElementListData();

        const analyticsElementWatchList = this._lib.getAnalyticsElementWatchList();

        let target = null;
        if (Constants.ELEMENT_DATA_SELECTOR in this._config && this._config[Constants.ELEMENT_DATA_SELECTOR] !== null) {
            const observerTargetSelector = this._config[Constants.ELEMENT_DATA_SELECTOR];
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Observable.QUERY_FOR_TARGET.format(observerTargetSelector));

            let targetElement = null;
            try {
                targetElement = this._querySelector(observerTargetSelector);
            } catch (e) {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.FAILED_TO_QUERY_SELECTOR.format(observerTargetSelector));
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.ERROR, e.stack);
            }

            if (targetElement) {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Observable.FOUND_SETTING_TARGET.format(observerTargetSelector));
                target = targetElement;
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.NO_ELEMENT_FOUND_FOR.format(observerTargetSelector));
            }

            let alreadyOnWatchList = false;
            for (let x = 0 ; x < analyticsElementWatchList.length && !alreadyOnWatchList; x++) {
                const thisEl = analyticsElementWatchList[x];
                if (thisEl.selector === observerTargetSelector) {
                    alreadyOnWatchList = true;
                }
            }

            const watchListElement = { selector: observerTargetSelector, target: target, element: this._config };

            let alreadySetup = false;
            if (target) {
                // check data-ual-observable to see if this current element was set up or if it's a new element that needs to be set up now
                let setupProp = target.getAttribute(Constants.OBSERVABLE_ELEMENT_PROPERTY_CHECK_NAME);
                if (setupProp === Constants.TRUE_STRING) {
                    alreadySetup = true;
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Observable.OBSERVER_ALREADY_SET_UP_ON_ELEMENT.format(
                        Constants.OBSERVABLE_ELEMENT_PROPERTY_CHECK_NAME));
                }
            }

            let logPrefix = this._lib.getLoggingElementPrefix(this._config);

            // This case is when an observer was set up but the element has been deleted and added back and is now
            // being processed again so we need to re-add the observer, but need to remove from the watch list first
            if (alreadyOnWatchList && !alreadySetup) {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Observable.REMOVING_FROM_WATCH_LIST.format(logPrefix));
                this._lib.removeFromAnalyticsElementWatchList(watchListElement);
            }

            if (!alreadySetup) {
                if (target) {
                    // Generate hashes for observed element lists
                    if (Constants.ELEMENT_DATA_OBSERVE_ELEMENTS in this._config && this._config[Constants.ELEMENT_DATA_OBSERVE_ELEMENTS] !== null) {
                        let elementsToWatchFor = this._config[Constants.ELEMENT_DATA_OBSERVE_ELEMENTS];
                        if (Array.isArray(elementsToWatchFor)) {
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Observable.START_GENERATING_HASH);
                            for (let elIndex = 0; elIndex < elementsToWatchFor.length; elIndex++) {
                                let curElement = elementsToWatchFor[elIndex];
                                if (typeof curElement !== Constants.JS_TYPE_UNDEFINED && curElement !== null) {
                                    curElement[Constants.ELEMENT_DATA_HASH_ID] = Utils.JSONStringify(curElement).hashCode();
                                }
                            }
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Observable.FINISH_GENERATING_HASH);
                        }
                    }

                    let childList = false;
                    let attributes = false;
                    let characterData = false;
                    let processOnLoad = false;
                    if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS in this._config && this._config[Constants.ELEMENT_DATA_OBSERVER_OPTIONS] !== null) {
                        let observerOptions = this._config[Constants.ELEMENT_DATA_OBSERVER_OPTIONS];
                        if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST in observerOptions) {
                            if (typeof observerOptions[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST] === Constants.JS_TYPE_OBJECT) {
                                childList = true;
                            } else {
                                childList = observerOptions[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST];
                            }
                        }
                        if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS_ATTRIBUTES in observerOptions) {
                            if (typeof observerOptions[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_ATTRIBUTES] === Constants.JS_TYPE_OBJECT) {
                                attributes = true;
                            } else {
                                attributes = observerOptions[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_ATTRIBUTES];
                            }
                        }
                        if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHARACTER_DATA in observerOptions) {
                            if (typeof observerOptions[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHARACTER_DATA] === Constants.JS_TYPE_OBJECT) {
                                characterData = true;
                            } else {
                                characterData = observerOptions[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHARACTER_DATA];
                            }
                        }
                        if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS_PROCESS_ON_LOAD in observerOptions) {
                            processOnLoad = observerOptions[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_PROCESS_ON_LOAD];
                        }
                    }

                    if (childList || attributes || characterData) {
                        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Observable.ADDING_TO_WATCH_LIST.format(logPrefix));
                        this._lib.addToAnalyticsElementWatchList(watchListElement);
                        this._lib.addObservation(target, { subtree: true, childList: childList, attributes: attributes, characterData: characterData });

                        // set data-ual-observable to true so we can check if same element has this set up later on
                        target.setAttribute(Constants.OBSERVABLE_ELEMENT_PROPERTY_CHECK_NAME, Constants.TRUE_STRING);

                        if (processOnLoad) {
                            await this.processElementList();
                        }
                    } else {
                        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Observable.OBSERVER_MUST_SET_SOMETHING_TO_OBSERVE);
                    }
                } else {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Observable.NO_TARGET_FOUND);
                }
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Observable.OBSERVER_WITH_SELECTOR_ALREADY_SETUP.format(observerTargetSelector));
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Observable.NO_TARGET_SELECTOR);
        }
    }

    /**
     * Process the ObservableElement's element list
     *
     * @async
     * @returns {Promise}
     */
    async processElementList () {
        const keyStr = this._lib.getElementKeyString(this._config);
        let displayStr = keyStr;
        if (Constants.ELEMENT_DATA_DESCRIPTION in this._config && this._config[Constants.ELEMENT_DATA_DESCRIPTION] !== null) {
            displayStr = this._config[Constants.ELEMENT_DATA_DESCRIPTION];
        }

        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.MutationObserver.OnMutation.PROCESSING_OBSERVER.format(
            ConsoleStrings.MutationObserver.LABEL, displayStr));

        if (Constants.ELEMENT_DATA_OBSERVE_ELEMENTS in this._config && this._config[Constants.ELEMENT_DATA_OBSERVE_ELEMENTS] !== null) {
            let elementPromiseArray = [];
            const elementsToProcess = this._config[Constants.ELEMENT_DATA_OBSERVE_ELEMENTS];
            for (let x = 0; x < elementsToProcess.length; x++) { // 'observable' element's 'elements' list
                const curElementToProcess = elementsToProcess[x];
                const curElementToProcessKeyStr = this._lib.getElementKeyString(curElementToProcess);
                let curElementToProcessDisplayStr = curElementToProcessKeyStr;
                if (Constants.ELEMENT_DATA_DESCRIPTION in curElementToProcess && curElementToProcess[Constants.ELEMENT_DATA_DESCRIPTION] !== null) {
                    curElementToProcessDisplayStr = curElementToProcess[Constants.ELEMENT_DATA_DESCRIPTION];
                }

                let curElementToProcessType = curElementToProcess[Constants.ELEMENT_DATA_TYPE];
                if (Constants.UNSUPPORTED_OBSERVABLE_ELEMENT_TYPES.indexOf(curElementToProcessType) === -1) {
                    if (Constants.ELEMENT_DATA_SELECTOR in curElementToProcess) {
                        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.MutationObserver.OnMutation.HANDLING_ELEMENT.format(
                            ConsoleStrings.MutationObserver.LABEL, curElementToProcessDisplayStr));

                        // Have to copy element here so multiple mutations can track different data
                        const copiedElement = Utils.copyObject(curElementToProcess);
                        let logPrefix = this._lib.getLoggingElementPrefix(copiedElement);
                        if (Constants.ELEMENT_DATA_DATA in copiedElement) {
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Observable.START_PROCESSING_DYNAMIC_DATA.format(
                                logPrefix, Constants.DYNAMIC_DATA_TYPE_OBSERVE));
                            let processTypeArgs = {};
                            processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES] = this._lib.getPageValues();
                            DynamicDataProcessor.process(copiedElement[Constants.ELEMENT_DATA_DATA], Constants.DYNAMIC_DATA_TYPE_OBSERVE, processTypeArgs);
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Observable.FINISH_PROCESSING_DYNAMIC_DATA.format(
                                logPrefix, Constants.DYNAMIC_DATA_TYPE_OBSERVE));
                        }
                        elementPromiseArray.push(this._lib.handleElement(copiedElement, false, this));
                    }
                } else {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.MutationObserver.OnMutation.TYPE_UNSUPPORTED.format(
                        ConsoleStrings.MutationObserver.LABEL, curElementToProcessType));
                }
            }

            try {
                await Promise.all(elementPromiseArray);
            } catch (e) {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.ERROR, e.stack);
            }
        }

        this._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.MutationObserver.OnMutation.PROCESSING_OBSERVER_FINISH.format(
            ConsoleStrings.MutationObserver.LABEL, displayStr));
    }

    _initializeElementListData () {
        if (Constants.ELEMENT_DATA_OBSERVE_ELEMENTS in this._config && this._config[Constants.ELEMENT_DATA_OBSERVE_ELEMENTS] !== null) {
            const elements = this._config[Constants.ELEMENT_DATA_OBSERVE_ELEMENTS];
            for (let x = 0; x < elements.length; x++) {
                let curElement = elements[x];
                let elementLabel = this._lib.getLoggingElementPrefix(curElement);

                // Get the init dynamic data here before handling element
                if (Constants.ELEMENT_DATA_DATA in curElement) {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Observable.START_PROCESSING_DYNAMIC_DATA.format(
                        elementLabel, Constants.DYNAMIC_DATA_TYPE_INIT));
                    let processTypeArgs = {};
                    processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES] = this._lib.getPageValues();
                    DynamicDataProcessor.process(curElement[Constants.ELEMENT_DATA_DATA], Constants.DYNAMIC_DATA_TYPE_INIT, processTypeArgs);
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.Observable.FINISH_PROCESSING_DYNAMIC_DATA.format(
                        elementLabel, Constants.DYNAMIC_DATA_TYPE_INIT));
                }
            }
        }
    }
}

export default ObservableElement; // JSDoc workaround for documenting classes
