import { Constants } from '../Constants';
import UserActionElement from './UserActionElement';

/**
 * Base class for submitable elements
 * @extends UserActionElement
 */
class SubmitableElement extends UserActionElement {
    /**
     * Constructor for SubmitableElement
     * @param {Object} elementConfig
     */
    constructor (elementConfig) {
        super('SubmitableElement', elementConfig, Constants.EVENT_LISTENER_TYPE_SUBMIT);
    }
}

export default SubmitableElement; // JSDoc workaround for documenting classes