/**
 * @constant
 * @type {ConsoleStringsType}
 * @name ConsoleStrings
 * @description An object of constant strings for use throughtout the library for console output.
 *
 */
export const ConsoleStrings = {
    Config: {
        Object: {
            UNDEFINED_OR_NOT_PARSEABLE:
                'Configuration object is undefined. Either _wkUsageAnalyticsLibConfig is not defined or it was unable to be parsed. Check for errors in config object.'
        },
        Element: {
            NULL_UNDEFINED: 'Element configuration is null or undefined.',
            MUST_HAVE_TYPE: 'Element must have a type.',
            MUST_BE_ARRAY: 'elements must be an Array, not processing.',
            NO_ELEMENTS: 'No elements configured'
        }
    },
    Element: {
        TYPE_HTML: 'html',
        TYPE_MEDIA: 'media',
        TYPE_MULTI: 'multi',
        TYPE_PAGE: 'page',
        FAILED_TO_QUERY_SELECTOR: 'Failed to query with selector: {0}',
        FAILED_TO_COPY: 'Failed to copy element.',
        MAKING_TRACK_EVENT_CALLS: 'Making trackEvent calls. Event type: {0} Data: {1}',
        ELEMENT_DOES_NOT_EXIST: 'Element with selector does not exist: {0}',
        NO_DATA_NOT_TRACKING: '{0} element has no data to use with tracking, not tracking.',
        MUST_HAVE_SELECTOR: '{0} type must have a selector',
        ADDED_HANDLER: 'Added "{0}" event listener for {1}: {2}',
        HANDLERS_ALREADY_ADDED: '"{0}" event handlers already added, not adding again.',
        NO_ELEMENT_FOUND_FOR: 'No element found for: {0}',
        BaseElementClass: {
            CANNOT_HANDLE: 'Cannot handle base element type',
            WAIT_FOR_EXIST_SELECTOR: 'waitForExist has a selector, will query that while waiting: {0}',
            FOUND_ELEMENTS: 'Found element(s) with selector: {0} Found number of elements: {1}',
            NOT_FOUND_WITH_SELECTOR: 'Did not find any elements with selector: {0}',
            TIMED_OUT_WAITING_FOR_SELECTOR: 'Timed out waiting for selector: {0}',
            PRE_CONDITIONAL_ELEMENT: 'Element has pre-conditions',
            CONDITIONAL_ELEMENT: 'Element is conditional',
            UNSUPPORTED_CONDITIONAL: 'Unsupported conditional type: {0}',
            CONDITIONAL_DEFAULT_TRUE: 'Condition defaults to true.',
            CONDITION_TRUE: 'Condition is true, tracking',
            CONDITION_FALSE: 'Condition is false, not tracking',
            PRE_CONDITION_TRUE: 'Pre-condition is true, processing element',
            PRE_CONDITION_FALSE: 'Pre-condition is false, not processing element',
            CONDITIONAL_TYPE_IS: 'Conditional type is {0}',
            CSS_CONDITIONAL_REQUIRES: 'CSS condition check requires a condition and a selector.',
            CONDITION_TYPE_CSS: 'CSS',
            CONDITION_TYPE_PAGEVAL: 'page value',
            CONDITION: 'Condition: {0}',
            SELECTOR: 'Selector: {0}',
            FOUND_NUMBER_OF_ELEMENTS: 'Found number of elements: {0}',
            PAGE_VALUE_NAME: 'Page value name: {0}',
            VALUE: 'Value: {0}',
            NOT_IN_PAGE_VALUES: 'Value not in page values: {0}',
            PAGE_VALUE_CONDITIONAL_REQUIRES: 'Page value condition check requires a condition and a value selector.',
            ELEMENT_SET_TO_PERFORM_RESCAN: 'Element configured to rescan page to look for newly available elements.',
            LOOKING_FOR_RESCAN_SELECTOR: 'Looking for rescan selector: {0}',
            FOUND_RESCAN_SELECTOR_RESET_HANDLERS: 'Found rescan selector. Resetting current handlers.',
            DELETE_ELEMENT_TRUE: 'deleteElement is true, deleting now.',
            STARTING_RESCAN: 'Starting rescan of page.',
            TIME_WAITED_RESETTING: 'Waited configured amount of time. Resetting current handlers.',
            RESCAN_REQUIRES: 'Rescan needs a selector or a wait time configured to initiate rescan.'
        },
        UserAction: {
            ELEMENT_ACTION_OCCURRED: '{0} element interacted with.',
            TRACKING_DEFERRED: 'Tracking for this {0} is deferred, setting deferred data.'
        },
        Media: {
            PLAYBACK_STARTED: 'Media playback has started, tracking start.',
            PLAYBACK_FINISHED: 'Media finished playing, tracking finish.',
            PLAYBACK_MILESTONE: 'Media reached a milestone, tracking segment.',
            START_DATA_NOT_CONFIGURED: 'startData not configured, not tracking start play event.',
            FINISH_DATA_NOT_CONFIGURED: 'finishData not configured, not tracking finish play event.',
            SEGMENT_DATA_NOT_CONFIGURED: 'segmentData not configured, not tracking segment event.',
            TRACK_SEGMENTS_NO_SEGMENTS_SET: 'Requested to track segments without setting number of segments.',
            TRACK_SEGMENTS_SEGMENTS_TOO_FEW: 'Requested to track segments but set number to less than two.'
        },
        Multi: {
            FOUND_FINAL_ELEMENT: 'Found final element of multi element on page.',
            FOUND_ON_PAGE_TRACKING: 'HTML element found on page, tracking data.',
            CLICK_FINAL_ELEMENT: 'Click occurred for final multi element element, tracking data.',
            CLICKABLE_MULTI_ADDING_HANDLER: 'Clickable multi element, adding click handler to each matching element.',
            UNSUPPORTED_MULTI_ELEMENT: 'Unsupported multi element type: {0}',
            FOUND_CURRENT_ELEMENT: 'Found current element of multi list on current page.',
            HTML_REMOVING_FROM_LIST_SETUP_DEFERRED: 'HTML multi element, removing from list and setting up deferred call.',
            CLICK_REMOVING_FROM_LIST_SETUP_DEFERRED: 'Click occurred for multi element element, removing from list and setting up deferred call.',
            IN_PROGRESS_NOT_FOUND_DEFERRED: 'In progress multi element not found on current page, deferring element for future processing.',
            FIRST_ELEMENT_NOT_FOUND_SKIPPING: 'Multi element not in progress and first element not found on page, skipping.',
            MULTI_ELEMENT_IMPROPER_TYPE: 'Multi element list of elements must be of type { type: <string>, selector: <string> }',
            DEFERRED_NO_ELEMENTS_CANNOT_PROCESS: 'Muti element was deferred with no elements in list, cannot process.',
            MUST_HAVE_ELEMENTS: 'Multi element must have an elements list.'
        },
        Observable: {
            QUERY_FOR_TARGET: 'Querying for element to set as observer target: {0}',
            FOUND_SETTING_TARGET: 'Found element, setting as target for observer. selector: {0}',
            START_GENERATING_HASH: 'Generating hash codes for observed element list',
            FINISH_GENERATING_HASH: 'Finished generating hash codes for observed element list',
            START_PROCESSING_DYNAMIC_DATA: 'Processing "{1}" dynamic data on observed element list for: {0}',
            FINISH_PROCESSING_DYNAMIC_DATA: 'Finished processing "{1}" dynamic data on observed element list for: {0}',
            ADDING_TO_WATCH_LIST: 'Adding element to watch list: {0}',
            REMOVING_FROM_WATCH_LIST: 'Removing element from watch list: {0}',
            NO_TARGET_FOUND: 'No observer target found, not setting up observer.',
            OBSERVER_WITH_SELECTOR_ALREADY_SETUP: 'Observer with selector already set up. Selector: {0}',
            NO_TARGET_SELECTOR: 'Element set to observe has no target selector, not setting up observer.',
            OBSERVER_MUST_SET_SOMETHING_TO_OBSERVE: 'Observer must have childList, attributes and/or characterData set to observe. Not setting up.',
            OBSERVER_ALREADY_SET_UP_ON_ELEMENT: 'Element already has an observer on it based on property {0}',
        },
        Page: {
            SELECTOR_FOUND: 'Page selector found, will track page.',
            SELECTOR_NOT_FOUND: 'Page selector not found, will not track page.',
            NO_SELECTOR: 'Page has no selector, will track page.'
        },
        WindowEvent: {
            PROCESSING_HANDLER: 'WindowEventElement element processing handler for "{0}" event type.',
            EVENT_TYPE_REQUIRED: 'WindowEventElement can not be processed without an eventType configured.'
        },
        EventTiming: {
            MISCONFIGURED: 'Element is missing "{0}" configuration. Will not process further.',
            MISCONFIGURED_MISSING_SETTINGS: 'Element is missing configuration in the {0} property.',
            UNABLE_TO_FIND_ELEMENT: 'Not able to find {0} element with configured options. Will not process further.',
            PROCESSING_HANDLER: 'EventTiming element processing {0} handler. Type: {1} Element: {2} Event: {3}',
            HANDLERS_ALREADY_ADDED: 'Event handlers already added for EventTiming element: {0} ({1} {2} {3})',
            ELEMENT_DEFERRED: 'Element is deferred. Will set end event up on next page.',
            ELEMENT_DEFERRED_FURTHER: 'Element to end on not found, but element was deferred. Further deferring to next page.',
            HANDLER_ADDED: '{0} handler added.'
        }
    },
    Storage: {
        PARSE_ERROR: 'Error parsing sessionStorage value, removing value.',
        CAUGHT_EXCEPTION: 'Error using sessionStorage.'
    },
    AnalyticsLib: {
        AnalyzePage: {
            ENTER_FUNCTION: 'analyzePage called with: {0}',
            NO_SERVICE_NOT_ANALYZING: 'No properly initialized analytics service, will not analyze page',
            NOT_CALLED_NO_SCAN: 'analyzePage was not called, not performing page scan.',
            NOT_CALLED_NO_PRESCAN: 'analyzePage was not called, not performing page pre-scan.'
        },
        Initialize: {
            START_GENERATING_HASH: 'Generating hash codes',
            FINISH_GENERATING_HASH: 'Finished generating hash codes'
        },
        InitService: {
            NOT_DERIVED_FROM_INTERFACE: 'Configured analytics services must be derived from IAnalyticsService. Not IAnalyticsService: {0}',
            CANNOT_FUNCTION_WITHOUT_SERVICE: 'AnalyticsLib cannot function without "analyticsService" in configuration.',
            ANALYTICS_SERVICES_NOT_OBJ: 'analyticsServices config must be an object.'
        },
        ScanPage: {
            START_SCAN: 'Scanning page',
            NO_SERVICE_NO_SCAN: 'No properly initialized analytics service, will not scan page'
        },
        PreScanPage: {
            START_PRESCAN: 'Pre-scanning page',
            NO_SERVICE_NO_PRESCAN: 'No properly initialized analytics service, will not pre-scan page',
            PROCESSING_ELEMENTS: 'Processing pre-scan elements',
            PROCESSING_ELEMENTS_FINISH: 'Finished processing pre-scan elements.',
            NO_PRE_PROCESS: 'Not a type to pre-process.'
        },
        HandleElements:{
            PROCESSING_ELEMENTS: 'Processing elements',
            PROCESSING_ELEMENTS_FINISH: 'Finished processing elements.',
            START_HANDLING_ELEMENT: 'Handling element: {0}',
            FINISH_HANDLING_ELEMENT: 'Finished handling: {0}',
            UNHANDLED_ELEMENT_TYPE: '{0} Unhandled element type: {1}',
            EXCEPTION_CAUGHT: '{0} Exception thrown while handling element.'
        },
        TrackEvent: {
            PROCESSING_ELEMENT_WITH_SERVICE: 'Processing element with analytics service: {0}',
            CALLING_TRACK_EVENT: 'Calling analytics service trackEvent method',
            EVENT_TYPE: '  Event type: {0}',
            ELEMENT_DATA: '  Element data: {0}',
            NO_DATA_NOT_TRACKING: 'Analytics element has no data to use with tracking, not tracking.',
            NO_GLOBAL_WITH_NAME_AVAILABLE: 'There is no global variable "{0}" to set in data to be tracked. Setting to empty string.'
        },
        Handlers: {
            REMOVE_WITH_NONEXISTENT_KEY: "Attempted to remove handler with a key that is not in handler list or on a key's list which was empty.",
            REMOVE_BEFORE_SETUP: 'Attempted to remove handler from element that has no UAL handlers on it. Removing handler from list.',
            GET_WITH_NONEXISTENT_KEY: 'Attempted to get handlers with a key that is not in handlers list. Returning empty list.',
            RESETTING_HANDLERS: 'Resetting handlers for element: {0}',
            RESETTING_EXTRA_HANDLERS: 'Element had more handlers set than current page has. Clearing all remaining handlers for {0}'
        },
        Deferred: {
            SETTING_UP: 'Setting up deferred call for: {0}',
            DEFERRED_DATA: 'Deferred data sessionStorage string: {0}',
            NO_SUPPORTED: 'Cannot use deferred tracking call without browser that supports sessionStorage',
            PRE_PROCESSING_CALLS: 'Pre-processing deferred tracking calls',
            CALL_TYPE: 'Deferred call {0} is type: {1}',
            EXCEPTION_CAUGHT: 'Exception thrown while handling element.',
            NO_TYPE: 'No type in deferred element, cannot process.',
            FINISHED_PREPROCESSING: 'Finished pre-processing deferred tracking calls',
            FINISHED_PROCESSING: 'Finished processing deferred tracking calls',
            NOTHING_TO_PRE_PROCESS: 'No deferred tracking calls to pre-process',
            NOTHING_TO_PROCESS: 'No deferred tracking calls to process',
            NO_STORAGE_AVAILABLE_PRE_PROCESS: 'Local browser storage not available. Not pre-processing deferred tracking calls.',
            NO_STORAGE_AVAILABLE_PROCESS: 'Local browser storage not available. Not processing deferred tracking calls.',
            PROCESSING: 'Processing deferred tracking calls'
        },
        Events: {
            LOAD_FIRED: '"load" event fired',
            START_LOAD_PROCESSING: 'Start "load" event processing',
            FINISH_LOAD_PROCESSING: 'Finished "load" event processing',
            LOAD_PRESCAN_NOT_FINISHED: 'In "load" event and pre-scan not finished, waiting.',
            START_DOM_CONTENT_PROCESSING: 'Start "DOMContentLoaded" event processing',
            FINISH_DOM_CONTENT_PROCESSING: 'Finished "DOMContentLoaded" event processing',
            START_UNLOAD_PROCESSING: 'Start "unload" event processing',
            FINISH_UNLOAD_PROCESSING: 'Finished "unload" event processing',
            NO_SCAN_UNLOAD_SCAN_NOW: 'Did not scan before "unload", scanning now.'
        },
        Observer: {
            SETTING_UP_WITH_OPTIONS: 'Setting up observer with options {0}'
        },
        Check: {
            CHECK_PROCESSING: 'Call to check function will be processed. Args: {0}',
            CHECK_FINISHED_PROCESSING: 'Call to check function complete.',
            CHECK_NOT_FINISHED: 'Previous call to check function is still in progress, waiting for it to complete.'
        }
    },
    DynamicData: {
        START_PROCESSING: 'Start processing {0} dynamic data',
        FINISH_PROCESSING: 'Finish processing {0} dynamic data',
        START_PROCESSING_GLOBALS: 'Processing globals dynamic data, {0} type.',
        FINISH_PROCESSING_GLOBALS: 'Finished processing globals dynamic data, {0} type.',
        VALUETYPE_SINGLE_REQUIRES_VALUE: 'valueType single requires a value',
        VALUETYPE_MULTI_REQUIRES_VALUE: 'valueType multi requires a value',
        UNSUPPORTED_VALUE_TYPE: 'Unsupported value type: {0}',
        MUTLIVAL_MUST_BE_ARRAY: 'multi value data must be an Array',
        FOUND_ELEMENTS: 'CSS selector "{0}" found number of elements: {1}',
        ATTRIBUTE_NOT_FOUND: 'Attribute of element not found: {0}',
        Processor: {
            START_PROCESSING: 'Starting dynamic data processing: {0}',
            FINISH_PROCESSING: 'Finished dynamic data processing.',
            CLEARING_OBJECT: 'Clearing dynamic data object that was not processed: type: {0} , path: {1}',
            SELECTOR_TYPE: 'Dynamic data selector type: {0}',
            SELECTOR_OPTIONS: 'Dynamic data selector options: {0}',
            UNSUPPORTED_SELECTOR: 'Unsupported dynamic data selector type: {0}'
        },
        BaseType: {
            UNSUPPORTED_MODIFIER_TYPE: 'Unsupported modifier type: {0}',
            CANNOT_GET: 'Cannot get from base processor type',
            MODIFIERS_MUST_BE_ARRAY: 'modifiers must be an Array of modifier tasks.',
            APPLYING_MODIFIER: 'Applying modifier {0}',
            MODIFIER_OBJECT: 'Modifier object: {0}',
            CURRENT_VALUE: 'Current value: {0}',
            MODIFIED_VALUE_IS: 'Modified value is: {0}',
            CONVERT_UNSUPPORTED_CONVERT_TO_TYPE: 'Unsupported convertTo type: {0}',
            CONVERT_MUST_HAVE_CONVERT_TO: 'Convert modifier must have a convertTo options',
            REGEX_MATCH: 'Regex match. Regex: {0} String: {1}',
            REGEX_NO_MATCH: 'Regex did not match. Regex: {0} String: {1}',
            REGEX_RESULTS: 'Results: {0}',
            REGEX_CHOSEN_GROUP: 'Choosing regex group: {0}',
            REGEX_MATCHED_DEFAULT_FIRST_GROUP: 'No group specified. Defaulting to first group matched.',
            REGEX_NO_GROUP_MATCH_USE_FULL_MATCH: 'RegEx matched no groups, using full match.',
            REGEX_MUST_CHOOSE_VALID_GROUP: 'Invalid group specified. Returning empty string.',
            REGEX_EXCEPTION_CAUGHT: 'Exception caught while running match modifier: {0}',
            REGEX_MUST_HAVE_REGEX: 'Match modifier must have a regex in the options',
            REGEX_REPLACE: 'Attempting to replace a value. Regex: {0} Replace with: {1}',
            REGEX_REPLACE_EXCEPTION_CAUGHT: 'Exception caught while running match modifier: {0}',
            REGEX_REPLACE_MUST_HAVE_REGEX: 'Replace modifier must have a regex and replace in options',
            VALUE: 'Value: {0}'
        },
        CSSSelector: {
            ATTEMPTING_TO_FIND_ELEMENTS: 'Attempting to find elements with CSS selector: {0}'
        },
        URLParamSelector: {
            ATTEMPTING_TO_FIND_PARAM: 'Looking for URL parameter: {0}'
        },
        CounterSelector: {
            RETRIEVING_COUNTER_VALUE: 'Retrieving counter value for: {0}'
        },
        Page: {
            VALUE_NOT_AVAILABLE: 'Page value not available: {0}',
            SELECTOR_HAS_SUB_OBJECT_INDICATOR: 'Selector attempting to select value from sub-object.'
        },
        System: {
            VALUE_NOT_AVAILABLE: 'System value not available: {0}'
        },
        Target: {
            VALUE_NOT_IN_TARGET: 'Configured value not in target element: {0}',
            ATTRIBUTE_OF_CLOSEST_ANCESTOR_NOT_FOUND: 'Attribute of closest ancestor not found: {0}',
            NO_TARGET_TO_FIND_ANCESTOR: 'No target element to find ancestor of.',
            NO_ANCESTOR_FOUND_WITH_SELECTOR: 'No closest ancestor found with selector: {0}',
            MISCONFIGURED_ELEMENT:
'Misconfigured target dynamic data object. Must have value and no ancestor, value/ancestor, ancestor/selector/valueType, or all four of value/ancestor/selector/valueType.',
            INVALID_PARAM_COMBO: 'Invalid parameter combination for target selector.'
        },
        Properties: {
            NO_PROP_FOUND: 'No property found: {0}',
            CANNOT_USE_UNPROCESSED_PROP: 'Cannot use property that has not evaluated to a value yet. Property: {0}',
            CALCULATION_MUST_BE_CONFIGURED: 'Calculation must have a defined calculation string',
            MUST_HAVE_SELECTOR: 'properties selectorType must have selector',
            EXCEPTION_CAUGHT: 'Exception caught while calculating.'
        }
    },
    MutationObserver: {
        LABEL: '[MutationObserver]',
        RESET_OBSERVER: 'Resetting mutation observer.',
        OnMutation: {
            CHANGE_DETECTED_PROCESSING_LIST: '{0} Detected DOM change on observed element, processing watch list for matches.',
            FOUND_ELEMENT_MATCH: '{0} Found watch list element whose target matches or is within the MutationRecord target.',
            CONDITIONS_ARRAY_EMPTY: '{0} All partial conditions satisfied, match found.',
            CONDITION_MATCHED: '{0} Partial childList condition matched "{1}" {2}',
            ATTRIBUTE_CONDITION_MATCHED: '{0} Partial attribute condition matched, element "{1}" attribute "{2}" contains "{3}"',
            NO_ELEMENT_MATCH: '{0} Did not match any mutations.',
            PROCESSING_OBSERVER: '{0} Processing observer: <{1}>',
            PROCESSING_OBSERVER_FINISH: '{0} Finished processing observer: <{1}>',
            HANDLING_ELEMENT: '{0} Handling element from watch list: <{1}>',
            TYPE_UNSUPPORTED: '{0} {1} types are not supported in an observable element list',
            CHILD_LIST_CONDITIONS_EXCEPTION_CAUGHT: '{0} Caught an exception while processing childList conditions.',
            ATTRIBUTES_CONDITIONS_EXCEPTION_CAUGHT: '{0} Caught an exception while processing attributes conditions.'
        }
    },
    AnalyticsService: {
        AdobeAnalytics: {
            TRACKING_OBJ_MISSING: "Adobe Analytics Launch configuration is missing for 'trackingObj'",
            TRACKING_OBJ_NULL: "Adobe Analytics Launch configured 'trackingObj' is null. Must include Adobe's tracking lib script file.",
            DATA_OBJ_MISSING: "Adobe Analytics Launch configuration is missing for 'dataObj'",
            UNSUPPORTED_EVENT: 'Unsupported event type: {0}',
            DATA_MISCONFIGURED: 'Configured "data" does not have AdobeAnalyticsLaunch property, cannot make tracking call.',
            CONFIG_INCOMPLETE: 'Configuration incomplete, cannot make tracking call.',
            TRACKING: 'Tracking event with call to Adobe Analytics Launch lib "track" function with tracking ID: {0}',
            TRACKING_DATA: 'Tracking data object: {0}',
            TRACKIN_OBJ_REQUIRED: 'Adobe Analytics Launch requires a tracking object instance in order to make tracking calls. {0} is undefined.',
            NO_DATA_TO_TRACK: 'No data configured in which to set Launch data layer.',
            NO_ID_TO_TRACK: 'Element has no id to track with',
            COMMON_UAL_RULE_CALLED: 'The common-ual-rule Launch rule has been called.',
            RUNNING_VAR_SET_TO: 'The wk.ual.launch.common-ual-rule.running session variable is set to: {0}',
            RUNNING_VAR_NOT_SET_CALLING_TRACK: 'The wk.ual.launch.common-ual-rule.running session variable is not set. Calling track.',
            RUNNING_VAR_SET_WAITING: 'The wk.ual.launch.common-ual-rule.running session variable is set. Waiting.',
            APP_MEASUREMENT_REQUIRED: 'The appMeasurementTracker config is required to use the common-ual-rule.',
            LAUNCH_CONFIG_REQUIRED: 'The launchConfig property is required to use the common-ual-rule.',
            CUR_PROCESSING_EVENT: 'Processing event: {0}',
            CUR_PROCESSING_EVAR: 'Processing eVar: {0}',
            CUR_PROCESSING_PROP: 'Processing prop: {0}',
            CUR_CALLING_TRACK: 'Calling track in the common-ual-rule.',
            DETERMINED_TO_BE_SET: '{0} has been determined to be set.',
            DETERMINED_TO_NOT_BE_SET: '{0} has been determined to not be set.',
            VALUE_SET_TO: '{0} has been set to {1}',
            PAGE_VIEW_BEACON: 'Page view beacon set to be called.',
            LINK_TRACK_BEACON: 'Link track beacon set to be called. Link type: {0} Link name: {1}',
            UAL_RULE_EXCEPTION_CAUGHT: 'Caught exception while processing common-ual-rule: {0}'
        },
        ConsoleService: {
            INIT_CALLED: 'ConsoleTestService::init',
            TRACK_EVENT_CALLED: 'ConsoleTestService::trackEvent',
            CONFIG: 'config: {0}',
            ARGS: 'args: {0}',
            EVENT: 'event: {0}'
        }
    }
};

if (typeof global === 'undefined') {
    global = {};
}
global.ConsoleStrings = ConsoleStrings;
