import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import DynamicDataTypeSelector from './DynamicDataTypeSelector';

/**
 * Used to handle system type selections in dynamic data objects
 * @extends DynamicDataTypeSelector
 */
class SystemSelector extends DynamicDataTypeSelector {

    /**
     * Constructor for SystemSelector
     * @param {Object} options
     * @param {Object} timing
     */
    constructor (options, timing) {
        super('SystemSelector', options);
        this._systemValues = this._setupSystemValues(options, timing);
    }

    /**
     * The main function to get the value of this dynamic data object
     *
     * @returns {String|Number}
     */
    get () {
        let valStr = '';

        if (this._selector && this._selector in this._systemValues) {
            if (this._valueType && this._valueType === Constants.DYNAMIC_DATA_SINGLE_VAL) {
                valStr = this._systemValues[this._selector];
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.UNSUPPORTED_VALUE_TYPE.format(this._valueType));
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.System.VALUE_NOT_AVAILABLE.format(this._selector));
        }

        return this._applyModifiers(valStr);
    }

    /**
     * Function to set up the system values object.
     *
     * @private
     * @param {string} options
     * @param {Number} timing
     * @returns {Object}
     */
    _setupSystemValues (options, timing) {
        const values = {};

        // Set up loadtime
        if (window && ('performance' in window) &&
            ('timing' in window.performance) &&
            ('domContentLoadedEventEnd' in window.performance.timing) &&
            ('navigationStart' in window.performance.timing)) {
            values[Constants.DYNAMIC_DATA_SYSTEM_LOADTIME] = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;
        }

        // Set up timestamp
        let dateFormat = Constants.DYNAMIC_DATA_SYSTEM_TIMESTAMP_DEFAULT;
        if (Constants.DDOBJ_SELECTOR_OPTIONS_FORMAT in options && options[Constants.DDOBJ_SELECTOR_OPTIONS_FORMAT] !== null) {
            dateFormat = options[Constants.DDOBJ_SELECTOR_OPTIONS_FORMAT];
        }

        let useLocalTime = false;
        if (Constants.DDOBJ_SELECTOR_OPTIONS_LOCAL_TIME in options && options[Constants.DDOBJ_SELECTOR_OPTIONS_LOCAL_TIME] !== null) {
            useLocalTime = options[Constants.DDOBJ_SELECTOR_OPTIONS_LOCAL_TIME];
        }

        const now = new Date();
        values[Constants.DYNAMIC_DATA_SYSTEM_TIMESTAMP] = now.format(dateFormat, !useLocalTime);

        // Set up ualversion
        values[Constants.DYNAMIC_DATA_SYSTEM_UALVERSION] = Constants.METADATA_LIB_NAME_VERSION;

        // Set up timing
        values[Constants.DYNAMIC_DATA_SYSTEM_TIMING] = timing;

        return values;
    }
}

export default SystemSelector; // JSDoc workaround for documenting classes