import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import UsageElement from './UsageElement';
import DynamicDataProcessor from '../DynamicDataProcessor';
import Utils from '../Utils';

/**
 * Class handling window events
 * @extends UsageElement
 */
class WindowEventElement extends UsageElement {

    /**
     * Constructor for WindowEventElement
     * @param {Object} elementConfig
     */
    constructor (elementConfig) {
        super('WindowEventElement', elementConfig);
    }

    /**
     * Function to hanldle the windowevent element
     *
     * @async
     * @param {ObservableElement} observableElement
     * @returns {Promise}
     */
    async handle (observableElement) {
        await super.handle(observableElement);

        let context = this;

        if (!this._checkPreCondition()) {
            return;
        }

        this._processInitDynamicData();

        const keyStr = this._lib.getElementKeyString(this._config);

        let eventType = null;
        if (Constants.ELEMENT_DATA_WINDOW_EVENT_EVENT_TYPE in this._config) {
            eventType = this._config[Constants.ELEMENT_DATA_WINDOW_EVENT_EVENT_TYPE];
        }

        if (eventType) {
            // set track true and rescan false by default
            let track = true;
            let rescan = false;

            const rescanOptions = this._checkIfRescan();

            let asyncOptions = null;
            if (Constants.ELEMENT_DATA_ASYNC in this._config) {
                asyncOptions = this._config[Constants.ELEMENT_DATA_ASYNC];
            }

            if (asyncOptions) {
                // set track default to false for async link
                track = false;

                // use rescan options rescan value
                rescan = rescanOptions.rescan;

                if (Constants.ELEMENT_DATA_ASYNC_OPTION_TRACK in asyncOptions) {
                    track = asyncOptions[Constants.ELEMENT_DATA_ASYNC_OPTION_TRACK];
                }
            }

            let retainHandlers = false;
            if (Constants.ELEMENT_DATA_RETAIN_HANDLERS in this._config && this._config[Constants.ELEMENT_DATA_RETAIN_HANDLERS] !== null) {
                retainHandlers = this._config[Constants.ELEMENT_DATA_RETAIN_HANDLERS];
            }

            let windowEventFunc = async function (/*event*/) {
                context._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.WindowEvent.PROCESSING_HANDLER.format(eventType));

                if (!retainHandlers) {
                    context._lib.removeHandler(eventType, keyStr, window);
                }

                const copiedElement = context._copyConfig();

                let data = null;
                if (Constants.ELEMENT_DATA_DATA in copiedElement && copiedElement[Constants.ELEMENT_DATA_DATA] !== null) {
                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.START_PROCESSING.format(Constants.DYNAMIC_DATA_TYPE_WINDOWEVENT));
                    let processTypeArgs = {};
                    processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES] = context._lib.getPageValues();
                    DynamicDataProcessor.process(copiedElement[Constants.ELEMENT_DATA_DATA], Constants.DYNAMIC_DATA_TYPE_WINDOWEVENT, processTypeArgs);
                    context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.FINISH_PROCESSING.format(Constants.DYNAMIC_DATA_TYPE_WINDOWEVENT));
                    data = copiedElement[Constants.ELEMENT_DATA_DATA];
                }

                if (track) {
                    if (context._checkCondition()) {
                        if (data) {
                            let trackingEventType = Constants.EVENT_TYPE_GENERAL;
                            if (Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE in copiedElement && copiedElement[Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE] !== null) {
                                trackingEventType = copiedElement[Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE];
                            }

                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.MAKING_TRACK_EVENT_CALLS.format(
                                trackingEventType, Utils.JSONStringify(data)));
                            context._lib.makeTrackEventCalls(trackingEventType, data);
                        } else {
                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.NO_DATA_NOT_TRACKING.format(
                                context._type));
                        }
                    }
                }

                if (rescan) {
                    await context._performRescan(rescanOptions);
                }
            };

            if (this._lib.getHandlers(keyStr).length === 0) {
                this._lib.addHandler(eventType, keyStr, window, windowEventFunc);
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.ADDED_HANDLER.format(
                    eventType, this._type, Constants.ELEMENT_TYPE_WINDOWEVENT));
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.HANDLERS_ALREADY_ADDED.format(eventType));
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.WindowEvent.EVENT_TYPE_REQUIRED);
        }
    }
}

export default WindowEventElement; // JSDoc workaround for documenting classes
