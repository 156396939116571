import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import UsageElement from './UsageElement';
import DynamicDataProcessor from '../DynamicDataProcessor';
import Utils from '../Utils';

/**
 * Base class for elements with user actions like clickable and submitable
 * @extends UsageElement
 */
class UserActionElement extends UsageElement {

    /**
     * Constructor for UserActionElement
     *
     * @constructor
     * @param {String} classname
     * @param {Object} elementConfig
     * @param {String} listenerType
     */
    constructor (classname, elementConfig, listenerType) {
        super(classname, elementConfig);

        if (this._classname === undefined) {
            this._classname = 'UserActionElement';
        }

        if (this._classname === 'UserActionElement') {
            throw new TypeError('Cannot instantiate base class UserActionElement');
        }

        this._listenerType = listenerType;
        this._dynamicDataType = null;
    }

    /**
     * Function to hanldle the user action element
     *
     * @async
     * @param {ObservableElement} observableElement
     * @returns {Promise}
     */
    async handle (observableElement) {
        await super.handle(observableElement);

        if (!this._checkPreCondition()) {
            return;
        }

        const context = this;

        let DeferralHelper = (await import('../DeferralHelper')).default;
        let deferred = DeferralHelper.isElementDeferred(this._config);
        let processing = DeferralHelper.isElementProcessingDeferral(this._config);
        if (processing) {
            this._dynamicDataType = Constants.DYNAMIC_DATA_TYPE_DEFER;
        } else if (this._listenerType === Constants.EVENT_LISTENER_TYPE_CLICK) {
            this._dynamicDataType = Constants.DYNAMIC_DATA_TYPE_CLICK;
        } else if (this._listenerType === Constants.EVENT_LISTENER_TYPE_SUBMIT) {
            this._dynamicDataType = Constants.DYNAMIC_DATA_TYPE_SUBMIT;
        } else {
            this._dynamicDataType = null;
        }

        if (!deferred || (deferred && !processing)) {
            const keyStr = this._lib.getElementKeyString(this._config);
            if (Constants.ELEMENT_DATA_SELECTOR in this._config && this._config[Constants.ELEMENT_DATA_SELECTOR] !== null) {
                const [waitForExist, waitForSelector, waitForExistTimeout] = this._getWaitForExistConfig();

                const elements = await this._getHTMLElements(this._config[Constants.ELEMENT_DATA_SELECTOR], waitForExist, waitForSelector, waitForExistTimeout);

                if (elements.length) {
                    // set track true and rescan false by default
                    let track = true;
                    let rescan = false;

                    const rescanOptions = this._checkIfRescan();

                    let asyncOptions = null;
                    if (Constants.ELEMENT_DATA_ASYNC in this._config) {
                        asyncOptions = this._config[Constants.ELEMENT_DATA_ASYNC];
                    }

                    if (asyncOptions) {
                        // set track default to false for async link
                        track = false;

                        // use rescan options rescan value
                        rescan = rescanOptions.rescan;

                        if (Constants.ELEMENT_DATA_ASYNC_OPTION_TRACK in asyncOptions) {
                            track = asyncOptions[Constants.ELEMENT_DATA_ASYNC_OPTION_TRACK];
                        }
                    }

                    let retainHandlers = false;
                    if (Constants.ELEMENT_DATA_RETAIN_HANDLERS in this._config && this._config[Constants.ELEMENT_DATA_RETAIN_HANDLERS] !== null) {
                        retainHandlers = this._config[Constants.ELEMENT_DATA_RETAIN_HANDLERS];
                    }

                    /**
                     * Event handler attached to the found elements to handle the eventwe want to track.
                     *
                     * @function
                     * @async
                     * @param {Event} event
                     * @returns {Promise}
                     */
                    const elementActionHandler = async function (event) {
                        context._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.UserAction.ELEMENT_ACTION_OCCURRED.format(context._type));

                        const copiedElement = context._copyConfig();

                        // Get dynamic data in click handler, for data that may not be available until click occurs
                        if (Constants.ELEMENT_DATA_DATA in copiedElement && copiedElement[Constants.ELEMENT_DATA_DATA] !== null) {
                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.START_PROCESSING.format(context._dynamicDataType));
                            let processTypeArgs = {};
                            processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES] = context._lib.getPageValues();
                            processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_EVENT] = event;
                            DynamicDataProcessor.process(copiedElement[Constants.ELEMENT_DATA_DATA], context._dynamicDataType, processTypeArgs);
                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.FINISH_PROCESSING.format(context._dynamicDataType));
                        }

                        if (deferred && !processing) {
                            context._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.UserAction.TRACKING_DEFERRED.format(context._type));
                            DeferralHelper.setupDeferredCall(copiedElement);
                        } else {
                            if (track) {
                                context._track(copiedElement);
                            }
                            if (rescan) {
                                await context._performRescan(rescanOptions);
                            }
                        }

                        if (!retainHandlers) {
                            // Remove handlers if we're supposed to
                            for (let x = 0; x < elements.length; x++) {
                                context._lib.removeHandler(context._listenerType, keyStr, elements[x]);
                            }
                        }
                    };

                    // After getting options and setting up action handler, process init DD and add handlers to elements
                    if (elements.length) {
                        this._processInitDynamicData();
                    }

                    for (let e = 0; e < elements.length; e++) {
                        const element = elements[e];

                        if (this._lib.getHandlers(keyStr).length < elements.length) {
                            this._lib.addHandler(this._listenerType, keyStr, element, elementActionHandler);
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.ADDED_HANDLER.format(
                                this._listenerType, this._type, this._config[Constants.ELEMENT_DATA_SELECTOR]));
                        } else {
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.HANDLERS_ALREADY_ADDED.format(this._listenerType));
                        }
                    }
                } else {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.ELEMENT_DOES_NOT_EXIST.format(
                        (waitForExist && waitForSelector) ? waitForSelector : this._config[Constants.ELEMENT_DATA_SELECTOR]));
                }
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.MUST_HAVE_SELECTOR.format(this._type));
            }
        } else {
            const copiedElement = this._copyConfig();

            // Get deferred dynamic data
            if (Constants.ELEMENT_DATA_DATA in copiedElement && copiedElement[Constants.ELEMENT_DATA_DATA] !== null) {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.START_PROCESSING.format(this._dynamicDataType));
                let processTypeArgs = {};
                processTypeArgs[Constants.DYNAMIC_DATA_PROCESS_TYPE_ARGS_PAGE_VALUES] = this._lib.getPageValues();
                DynamicDataProcessor.process(copiedElement[Constants.ELEMENT_DATA_DATA], this._dynamicDataType, processTypeArgs);
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.FINISH_PROCESSING.format(this._dynamicDataType));
            }

            this._track(copiedElement);
        }
    }

    /**
     * Function to make tracking calls if necessary
     *
     * @private
     * @param {AnalyticsElement} element
     * @returns {void}
     */
    _track (element) {
        if (this._checkCondition()) {
            let data = element[Constants.ELEMENT_DATA_DATA];
            if (data) {
                let trackingEventType = Constants.EVENT_TYPE_GENERAL;
                if (Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE in element && element[Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE] !== null) {
                    trackingEventType = element[Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE];
                }

                this._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.MAKING_TRACK_EVENT_CALLS.format(trackingEventType, Utils.JSONStringify(data)));
                this._lib.makeTrackEventCalls(trackingEventType, data);
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.NO_DATA_NOT_TRACKING.format(this._type));
            }
        }
    }
}

export default UserActionElement; // JSDoc workaround for documenting classes
