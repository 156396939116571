import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import DynamicDataTypeSelector from './DynamicDataTypeSelector';
import CounterManager from '../CounterManager';

/**
 * Used to handle counter type selections in dynamic data objects
 * @extends DynamicDataTypeSelector
 */
class CounterSelector extends DynamicDataTypeSelector {

    /**
     * Constructor for CounterSelector
     * @param {Object} options
     */
    constructor (options) {
        super('CounterSelector', options);
    }

    /**
     * The main function to get the value of this dynamic data object
     *
     * @returns {String|Number}
     */
    get () {
        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.CounterSelector.RETRIEVING_COUNTER_VALUE.format(this._selector));

        let valStr = '';
        if (this._selector !== null) {
            if (CounterManager.hasCounter(this._selector)) {
                valStr = CounterManager.getCounterValue(this._selector);
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.MUST_HAVE_SELECTOR);
        }

        return this._applyModifiers(valStr);
    }
}

export default CounterSelector; // JSDoc workaround for documenting classes