/** @module Polyfills */
import Utils from './Utils';

/**
 * Function to load the polyfills
 * @function load
 */
export function load () {

    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector ||
                                    Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
        Element.prototype.closest = function(s) {
            var el = this;
            if (!document.documentElement.contains(el) && !Utils.isInShadowDOM(el)) {
                return null;
            }

            do {
                if (el.matches(s)) {
                    return el;
                }
                if (el.parentNode) {
                    if (el.parentNode.host) {
                        el = el.parentNode.host;
                    } else {
                        el = el.parentNode;
                    }
                } else if (el.parentElement) {
                    el = el.parentElement;
                } else {
                    el = null;
                }
            } while (el !== null && el.nodeType === 1);
            return null;
        };
    }

}
