import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import Logger from '../Logger';
import Utils from '../Utils';

/**
 * Base class for dynamic data type selector classes. The base handles getting common
 * configuration options and applying value modification since that's common
 * to all types of selectors.
 */
class DynamicDataTypeSelector {

    /**
     * Constructor for DynamicDataTypeSelector
     * @param {String} classname
     * @param {Object} options
     */
    constructor (classname, options) {
        this._classname = classname;

        if (this._classname === undefined) {
            this._classname = 'DynamicDataTypeSelector';
        }

        if (this._classname === 'DynamicDataTypeSelector') {
            throw new TypeError('Cannot instantiate base class DynamicDataTypeSelector');
        }

        this._logger = Logger.getLogger();

        this._options = options;

        this._selector = null;
        if (Constants.DDOBJ_SELECTOR_OPTIONS_SELECTOR in this._options) {
            this._selector = this._options[Constants.DDOBJ_SELECTOR_OPTIONS_SELECTOR];
        }

        this._valueType = null;
        if (Constants.DDOBJ_SELECTOR_OPTIONS_VALUE_TYPE in this._options) {
            this._valueType = this._options[Constants.DDOBJ_SELECTOR_OPTIONS_VALUE_TYPE];
        }

        this._value = null;
        if (Constants.DDOBJ_SELECTOR_OPTIONS_VALUE in this._options) {
            this._value = this._options[Constants.DDOBJ_SELECTOR_OPTIONS_VALUE];
        }

        this._multiValSep = Constants.OPTIONS_MULTIVAL_SEP_DEFAULT;
        if (Constants.DDOBJ_SELECTOR_OPTIONS_MULTIVAL_SEPARATOR in this._options) {
            this._multiValSep = this._options[Constants.DDOBJ_SELECTOR_OPTIONS_MULTIVAL_SEPARATOR];
        }

        this._ancestor = null;
        if (Constants.DDOBJ_SELECTOR_OPTIONS_ANCESTOR in this._options) {
            this._ancestor = this._options[Constants.DDOBJ_SELECTOR_OPTIONS_ANCESTOR];
        }

        this._logPrefix = '{<Type Selector: ' +
            (this._classname ? this._classname : '') +
            '> <Value Type: ' +
            (this._valueType ? this._valueType : '') +
            '> <Selector: ' +
            (this._selector ? this._selector : '') +
            '>}';
    }

    // NOTE: Ignoring funcion and statement inside it from code coverage because it
    //       it should never get here, instantiating this class throws an exception.
    /* istanbul ignore next */
    /**
     * Function to get the dynamic data represented by the object
     *
     * @returns {String|Number}
     */
    get () {
        /* istanbul ignore next */
        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.CANNOT_GET);
    }

    /**
     * Function to apply configured modifiers for the content retrieved.
     *
     * @private
     * @param {string} val
     * @param {object} options
     * @returns {string}
     */
    _applyModifiers (val) {
        let modifiedVal = val;

        let modifiers = [];
        if (Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIERS in this._options) {
            modifiers = this._options[Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIERS];
        }

        if (!Array.isArray(modifiers)) {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.BaseType.MODIFIERS_MUST_BE_ARRAY);
            modifiers = [];
        }

        for (let m = 0; m < modifiers.length; m++) {
            const modifier = modifiers[m];
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.APPLYING_MODIFIER.format(m+1));
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.MODIFIER_OBJECT.format(Utils.JSONStringify(modifier)));
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.CURRENT_VALUE.format(modifiedVal));

            let type = null;
            if (Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_TYPE in modifier) {
                type = modifier[Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_TYPE];
            }

            switch (type) {
                case Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_TYPE_CONVERT: {
                    if (Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_CONVERT_TO in modifier) {
                        let converted = true;
                        const convertTo = modifier[Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_CONVERT_TO];
                        if (convertTo === Constants.DYNAMIC_DATA_CONVERSION_TYPE_NUMBER) {
                            modifiedVal = Number(modifiedVal);
                        } else if (convertTo === Constants.DYNAMIC_DATA_CONVERSION_TYPE_STRING) {
                            modifiedVal = String(modifiedVal);
                        } else if (convertTo === Constants.DYNAMIC_DATA_CONVERSION_TYPE_BOOLEAN) {
                            modifiedVal = Utils.getBoolean(modifiedVal);
                        } else {
                            converted = false;
                        }

                        if (converted) {
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.MODIFIED_VALUE_IS.format(modifiedVal));
                        } else {
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.CONVERT_UNSUPPORTED_CONVERT_TO_TYPE.format(convertTo));
                        }
                    } else {
                        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.CONVERT_MUST_HAVE_CONVERT_TO);
                    }
                } break;

                case Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_TYPE_MATCH: {
                    if (Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REGEX in modifier) {
                        try {
                            const regex = new RegExp(modifier[Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REGEX]);
                            const results = modifiedVal.match(regex);
                            if (results && results.length) {
                                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.REGEX_MATCH.format(regex, modifiedVal));
                                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.REGEX_RESULTS.format(results));
                                let group = null;
                                if (Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REGEX_GROUP in modifier) {
                                    group = modifier[Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REGEX_GROUP];
                                }

                                if (group !== null && (group >= 1 && group < results.length)) {
                                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.REGEX_CHOSEN_GROUP.format(group));
                                    modifiedVal = results[group];
                                } else if (group !== null && (group <= 0 || group >= results.length)) {
                                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.REGEX_MUST_CHOOSE_VALID_GROUP);
                                    modifiedVal = '';
                                } else {
                                    if (results.length > 1) {
                                        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.REGEX_MATCHED_DEFAULT_FIRST_GROUP);
                                        modifiedVal = results[1];
                                    } else {
                                        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.REGEX_NO_GROUP_MATCH_USE_FULL_MATCH);
                                        modifiedVal = results[0];
                                    }
                                }
                                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.MODIFIED_VALUE_IS.format(modifiedVal));
                            } else {
                                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.REGEX_NO_MATCH.format(regex, modifiedVal));
                                modifiedVal = '';
                            }
                        } catch (e) {
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.BaseType.REGEX_EXCEPTION_CAUGHT.format(e));
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.ERROR, e.stack);
                            modifiedVal = '';
                        }
                    } else {
                        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.REGEX_MUST_HAVE_REGEX);
                    }
                } break;

                case Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_TYPE_REPLACE: {
                    if (Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REGEX in modifier && Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REPLACE in modifier) {
                        let flags = '';

                        if (Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REPLACE_GLOBAL in modifier &&
                            modifier[Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REPLACE_GLOBAL] !== null) {
                            if (modifier[Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REPLACE_GLOBAL]) {
                                flags += 'g';
                            }
                        }

                        try {
                            const regex = new RegExp(modifier[Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REGEX], flags);
                            const replace = modifier[Constants.DYNAMIC_DATA_OBJ_DATA_VALUE_MODIFIER_REPLACE];
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.REGEX_REPLACE.format(regex, replace));
                            modifiedVal = modifiedVal.replace(regex, replace);
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.MODIFIED_VALUE_IS.format(modifiedVal));
                        } catch (e) {
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.BaseType.REGEX_REPLACE_EXCEPTION_CAUGHT.format(e));
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.ERROR, e.stack);
                        }
                    } else {
                        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.REGEX_REPLACE_MUST_HAVE_REGEX);
                    }
                } break;

                default: {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.BaseType.UNSUPPORTED_MODIFIER_TYPE.format(type));
                } break;
            }
        }

        this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.BaseType.VALUE.format(modifiedVal));
        return modifiedVal;
    }

    /**
     * Logs a message for this dynamic data object
     *
     * @private
     * @param {Number} msgType
     * @param {string} msg
     */
    _logMessage (msgType, msg) {
        switch (msgType) {
            case Constants.LOGGER_LEVELS_ENUM.INFO:
                this._logger.info(this._logPrefix + ' ' + msg);
                break;

            case Constants.LOGGER_LEVELS_ENUM.WARN:
                this._logger.warn(this._logPrefix + ' ' + msg);
                break;

            case Constants.LOGGER_LEVELS_ENUM.ERROR:
                this._logger.error(this._logPrefix + ' ' + msg);
                break;

            case Constants.LOGGER_LEVELS_ENUM.TRACE:
                this._logger.trace(this._logPrefix + ' ' + msg);
                break;

            default:
                break;
        }
    }
}

export default DynamicDataTypeSelector; // JSDoc workaround for documenting classes