import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import DynamicDataTypeSelector from './DynamicDataTypeSelector';

/**
 * Used to handle properties type selections in dynamic data objects
 * @extends DynamicDataTypeSelector
 */
class PropertiesSelector extends DynamicDataTypeSelector {

    /**
     * Constructor for PropertiesSelector
     * @param {Object} options
     * @param {Object} objectPath
     */
    constructor (options, objectPath) {
        super('PropertiesSelector', options);
        this._objectPath = objectPath;
    }

    /**
     * The main function to get the value of this dynamic data object
     *
     * @returns {String|Number}
     */
    get () {
        let valStr = '';

        if (this._selector !== null) {
            if (this._valueType && this._valueType === Constants.DYNAMIC_DATA_SINGLE_VAL) {
                valStr = this._getSingleValue();
            } else if (this._valueType && this._valueType === Constants.DYNAMIC_DATA_MULTI_VAL) {
                valStr = this._getMultiValue();
            } else if (this._valueType && this._valueType === Constants.DYNAMIC_DATA_COMBINE_VAL) {
                valStr = this._getCombineValue();
            } else if (this._valueType && this._valueType === Constants.DYNAMIC_DATA_PATTERN_VAL) {
                valStr = this._getPatternValue();
            } else if (this._valueType && this._valueType === Constants.DYNAMIC_DATA_CALCULATION_VAL) {
                valStr = this._getCalculationValue();
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.UNSUPPORTED_VALUE_TYPE.format(this._valueType));
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.Properties.MUST_HAVE_SELECTOR);
        }

        return this._applyModifiers(valStr);
    }

    /**
     * Gets the single value for this selector
     *
     * @private
     * @returns {String}
     */
    _getSingleValue () {
        let valStr = '';
        const prop = this._selector;
        if (prop in this._options) {
            valStr = this._options[prop];
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE,  + prop);
        }

        if (typeof valStr === Constants.JS_TYPE_STRING) {
            valStr = valStr.trim();
        }
        return valStr;
    }

    /**
     * Gets the multi value for this selector
     *
     * @private
     * @returns {String}
     */
    _getMultiValue () {
        let valStr = '';
        const props = this._selector.split(Constants.DYNAMIC_DATA_PROPERTIES_SEPARATOR);
        if (props.length > 0) {
            if (props[0] in this._options) {
                valStr = String(this._options[props[0]]).trim();
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Properties.NO_PROP_FOUND.format(props[0]));
            }
        }
        for (let i = 1; i < props.length; i++) {
            if (props[i] in this._options && this._options[props[i]] !== '') {
                if (valStr !== '') {
                    valStr += (this._multiValSep + String(this._options[props[i]]).trim());
                } else {
                    valStr += String(this._options[props[i]]).trim();
                }
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Properties.NO_PROP_FOUND.format(props[i]));
            }
        }
        return valStr;
    }

    /**
     * Gets the combine value for this selector
     *
     * @private
     * @returns {String}
     */
    _getCombineValue () {
        let valStr = '';
        const propsValues = [];
        const cProps = this._selector.split(Constants.DYNAMIC_DATA_PROPERTIES_SEPARATOR);
        let largestProp = 0;
        for (let j = 0; j < cProps.length; j++) {
            if (cProps[j] in this._options) {
                const propsArr = this._options[cProps[j]].split(Constants.DYNAMIC_DATA_SEPARATOR);
                propsValues.push(propsArr);
                largestProp = propsArr.length > largestProp ? propsArr.length : largestProp;
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Properties.NO_PROP_FOUND.format(cProps[j]));
            }
        }

        const numOfIters = propsValues.length * largestProp;
        let propsIndex = 0;
        let propIndex = 0;

        for (let iter = 0; iter < numOfIters; iter++) {
            if (propsIndex < propsValues.length) {
                if (propIndex < propsValues[propsIndex].length) {
                    if (iter > 0) {
                        valStr += (this._multiValSep + String(propsValues[propsIndex][propIndex]).trim());
                    } else {
                        valStr += String(propsValues[propsIndex][propIndex]).trim();
                    }
                } else {
                    if (iter !== numOfIters-1) {
                        valStr += this._multiValSep;
                    }
                }
            }
            propsIndex++;
            if (propsIndex === propsValues.length) {
                propsIndex = 0;
                propIndex++;
                if (propIndex === largestProp) {
                    propIndex = 0;
                }
            }
        }
        if (typeof valStr === Constants.JS_TYPE_STRING) {
            valStr = valStr.trim();
        }
        return valStr;
    }

    /**
     * Gets the pattern value for this selector
     *
     * @private
     * @returns {String}
     */
    _getPatternValue () {
        let valStr = '';
        let properties = this._getProperties();
        let largestPropArraySize = this._getLargestPropertyArraySize(properties);

        let pattern = '';
        if (Constants.DDOBJ_SELECTOR_OPTIONS_PATTERN in this._options && this._options[Constants.DDOBJ_SELECTOR_OPTIONS_PATTERN] !== null) {
            pattern = this._options[Constants.DDOBJ_SELECTOR_OPTIONS_PATTERN];
        }

        for (let x = 0; x < largestPropArraySize; x++) {
            valStr += pattern;

            for (let curProp in properties) {
                const curPropPattern = '%' + curProp + '%';

                let propReplacer = '';
                if (properties[curProp].length > x) {
                    propReplacer = String(properties[curProp][x]).trim();
                }

                valStr = valStr.replace(curPropPattern, propReplacer);
            }
        }

        if (typeof valStr === Constants.JS_TYPE_STRING) {
            valStr = valStr.trim();
        }
        return valStr;
    }

    /**
     * Gets the calculation value for this selector
     *
     * @private
     * @returns {String|Number}
     */
    _getCalculationValue () {
        let valStr = '';
        let properties = this._getProperties();
        let largestPropArraySize = this._getLargestPropertyArraySize(properties);

        let calculation = '';
        if (Constants.DDOBJ_SELECTOR_OPTIONS_CALCULATION in this._options && this._options[Constants.DDOBJ_SELECTOR_OPTIONS_CALCULATION] !== null) {
            calculation = this._options[Constants.DDOBJ_SELECTOR_OPTIONS_CALCULATION];
        }

        let precision = -1;
        if (Constants.DDOBJ_SELECTOR_OPTIONS_PRECISION in this._options && this._options[Constants.DDOBJ_SELECTOR_OPTIONS_PRECISION] !== null) {
            precision = this._options[Constants.DDOBJ_SELECTOR_OPTIONS_PRECISION];
        }

        if (calculation) {
            for (let x = 0; x < largestPropArraySize; x++) {
                valStr += calculation;

                for (let curProp in properties) {
                    const curPropPattern = '%' + curProp + '%';

                    let propReplacer = '';
                    if (curProp in properties && properties[curProp].length > x) {
                        propReplacer = String(properties[curProp][x]).trim();
                    }

                    valStr = valStr.replace(curPropPattern, propReplacer);
                }
            }

            try {
                valStr = eval(valStr);
            } catch (e) {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.Properties.EXCEPTION_CAUGHT);
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.ERROR, e.stack);
                valStr = '';
            }

            if (!isNaN(parseFloat(valStr)) && precision >= 0) {
                valStr = parseFloat(valStr).toFixed(precision);
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Properties.CALCULATION_MUST_BE_CONFIGURED);
        }
        return valStr;
    }

    _getProperties () {
        let properties = {};
        let configuredProps = this._selector.split(Constants.DYNAMIC_DATA_PROPERTIES_SEPARATOR);
        for (let j = 0; j < configuredProps.length; j++) {
            if (configuredProps[j] in this._options) {
                if (typeof this._options[configuredProps[j]] !== Constants.JS_TYPE_OBJECT) {
                    const curPropString = this._options[configuredProps[j]].toString();
                    const propsArr = curPropString.split(Constants.DYNAMIC_DATA_SEPARATOR);
                    properties[configuredProps[j]] = propsArr;
                } else {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Properties.CANNOT_USE_UNPROCESSED_PROP.format(configuredProps[j]));
                }
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Properties.NO_PROP_FOUND.format(configuredProps[j]));
            }
        }
        return properties;
    }

    /**
     * Get the largest property array size
     *
     * @private
     * @param {Object} properties
     * @returns {Number}
     */
    _getLargestPropertyArraySize (properties) {
        let largest = 0;
        for (let p in properties) {
            largest = properties[p].length > largest ? properties[p].length : largest;
        }
        return largest;
    }
}

export default PropertiesSelector; // JSDoc workaround for documenting classes
