import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import DynamicDataTypeSelector from './DynamicDataTypeSelector';

/**
 * Used to handle target type selections in dynamic data objects
 * @extends DynamicDataTypeSelector
 */
class TargetSelector extends DynamicDataTypeSelector {

    /**
     * Constructor for TargetSelector
     * @param {Object} options
     * @param {Object} event
     */
    constructor (options, event) {
        super('TargetSelector', options);
        this._event = event;
    }

    /**
     * The main function to get the value of this dynamic data object
     *
     * @returns {String|Number}
     */
    get () {
        let valStr = '';
        const targetElement = this._event ? this._event.currentTarget : null;

        let [value, ancestor, selector, valueType] = this._setupSituation(targetElement);

        if (value && ancestor === null && selector === null && valueType === null) { //1
            valStr = this._getValue_value(value, ancestor, selector, valueType, targetElement);
        } else if (value && ancestor && selector === null && valueType === null) { //2
            valStr = this._getValue_valueAncestor(value, ancestor, selector, valueType, targetElement);
        } else if (value && ancestor && selector && valueType) { //3
            valStr = this._getValue_valueAncestorSelectorValueType(value, ancestor, selector, valueType, targetElement);
        } else if (value === null && ancestor && selector && valueType) { //4
            valStr = this._getValue_ancestorSelectorValueType(value, ancestor, selector, valueType, targetElement);
        } else if (value === null && ancestor && selector === null && valueType) { // 5
            valStr = this._getValue_ancestorValueType(value, ancestor, selector, valueType, targetElement);
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Target.MISCONFIGURED_ELEMENT);
        }

        return this._applyModifiers(valStr);
    }

    /**
     * Set up the values based on what was given
     *
     * @private
     * @param {HTMLElement} targetElement
     * @returns {Array} Array of the values determined
     */
    _setupSituation (targetElement) {
        let value = null;
        let ancestor = null;
        let selector = null;
        let valueType = null;
        let invalid = false;
        if (this._value) {
            if (this._ancestor) {
                if (this._selector) {
                    if (this._valueType) {
                        //3
                        value = this._value;
                        ancestor = this._ancestor;
                        selector = this._selector;
                        valueType = this._valueType;
                    } else {
                        // misconfigured
                        invalid = true;
                    }
                } else {
                    if (this._valueType) {
                        // misconfigured
                        invalid = true;
                    } else {
                        //2
                        value = this._value;
                        ancestor = this._ancestor;
                    }
                }
            } else {
                if (this._selector) {
                    if (this._valueType) {
                        //3
                        value = this._value;
                        ancestor = targetElement;
                        selector = this._selector;
                        valueType = this._valueType;
                    } else {
                        // misconfigured
                        invalid = true;
                    }
                } else {
                    if (this._valueType) {
                        // misconfigured
                        invalid = true;
                    } else {
                        //1
                        value = this._value;
                    }
                }
            }
        } else {
            if (this._ancestor) {
                if (this._selector) {
                    if (this._valueType) {
                        //4
                        ancestor = this._ancestor;
                        selector = this._selector;
                        valueType = this._valueType;
                    } else {
                        // misconfigured
                        invalid = true;
                    }
                } else {
                    if (this._valueType) {
                        // 5
                        ancestor = this._ancestor;
                        valueType = this._valueType;
                    } else {
                        // misconfigured
                        invalid = true;
                    }
                }
            } else {
                if (this._selector) {
                    if (this._valueType) {
                        //4
                        ancestor = targetElement;
                        selector = this._selector;
                        valueType = this._valueType;
                    } else {
                        // misconfigured
                        invalid = true;
                    }
                } else {
                    // both cases are invalid, uncomment below if both cases need to be different
                    invalid = true;
                    // if (this._valueType) {
                    //     // misconfigured
                    //     invalid = true;
                    // } else {
                    //     // misconfigured
                    //     invalid = true;
                    // }
                }
            }
        }

        if (invalid) {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.Target.INVALID_PARAM_COMBO);
        }

        return [value, ancestor, selector, valueType];
    }

    /**
     * Get value for:
     *      value only situation (1)
     *
     * @private
     * @param {String} value
     * @param {String} ancestor
     * @param {String} selector
     * @param {String} valueType
     * @param {HTMLElement} targetElement
     * @returns {String}
     */
    _getValue_value (value, ancestor, selector, valueType, targetElement) {
        let valStr = '';
        if (targetElement) {
            if (value in targetElement) {
                valStr = targetElement[value];
            } else if (targetElement.hasAttribute(value)) {
                valStr = targetElement.getAttribute(value);
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Target.VALUE_NOT_IN_TARGET.format(value));
            }
        }
        return valStr;
    }

    /**
     * Get value for:
     *      value and ancestor situation (2)
     *
     * @private
     * @param {String} value
     * @param {String} ancestor
     * @param {String} selector
     * @param {String} valueType
     * @param {HTMLElement} targetElement
     * @returns {String}
     */
    _getValue_valueAncestor (value, ancestor, selector, valueType, targetElement) {
        let valStr = '';
        if (targetElement) {
            const closestAncestor = targetElement.closest(ancestor);
            if (closestAncestor) {
                if (value in closestAncestor) {
                    valStr = closestAncestor[value];
                } else if (closestAncestor.hasAttribute(value)) {
                    valStr = closestAncestor.getAttribute(value);
                } else {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Target.ATTRIBUTE_OF_CLOSEST_ANCESTOR_NOT_FOUND.format(value));
                }

                if (typeof valStr === Constants.JS_TYPE_STRING) {
                    valStr = valStr.trim();
                }
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Target.NO_TARGET_TO_FIND_ANCESTOR);
        }
        return valStr;
    }

    /**
     * Get value for:
     *      value, ancestor, selector, valueType situation (3)
     *
     * @private
     * @param {String} value
     * @param {String} ancestor
     * @param {String} selector
     * @param {String} valueType
     * @param {HTMLElement} targetElement
     * @returns {String}
     */
    _getValue_valueAncestorSelectorValueType (value, ancestor, selector, valueType, targetElement) {
        let valStr = '';
        if (targetElement) {
            let elements = [];
            let ancestorElement = null;
            if (typeof ancestor === Constants.JS_TYPE_STRING && ancestor !== targetElement) {
                ancestorElement = targetElement.closest(ancestor);
                if (!ancestorElement) {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Target.NO_ANCESTOR_FOUND_WITH_SELECTOR.format(ancestor));
                }
            } else {
                if (ancestor === targetElement) {
                    // ancestor was set to be targetElement so use it as the implicit ancestor
                    ancestorElement = ancestor;
                }
            }

            if (ancestorElement) {
                try {
                    elements = ancestorElement.querySelectorAllDeep(selector);
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.FOUND_ELEMENTS.format(selector, elements.length));
                } catch (e) {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.FAILED_TO_QUERY_SELECTOR.format(selector));
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.ERROR, e.stack);
                }
            }

            if (valueType === Constants.DYNAMIC_DATA_SINGLE_VAL) {
                valStr = this._getValue_valueAncestorSelectorValueType_singleValue(value, ancestor, selector, valueType, elements);
            } else if (valueType === Constants.DYNAMIC_DATA_MULTI_VAL) {
                valStr = this._getValue_valueAncestorSelectorValueType_multiValue(value, ancestor, selector, valueType, elements);
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.UNSUPPORTED_VALUE_TYPE.format(valueType));
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Target.NO_TARGET_TO_FIND_ANCESTOR);
        }
        return valStr;
    }

    /**
     * Get value for:
     *      value, ancestor, selector, valueType situation (3)
     *      single value
     *
     * @private
     * @param {String} value
     * @param {String} ancestor
     * @param {String} selector
     * @param {String} valueType
     * @param {NodeList} elements
     * @returns {String}
     */
    _getValue_valueAncestorSelectorValueType_singleValue (value, ancestor, selector, valueType, elements) {
        let valStr = '';
        if (elements.length > 0) {
            const element = elements[0];
            if (value in element) {
                valStr = element[value];
            } else if (element.hasAttribute(value)) {
                valStr = element.getAttribute(value);
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Target.ATTRIBUTE_OF_CLOSEST_ANCESTOR_NOT_FOUND.format(value));
            }

            if (typeof valStr === Constants.JS_TYPE_STRING) {
                valStr = valStr.trim();
            }
        }
        return valStr;
    }

    /**
     * Get value for:
     *      value, ancestor, selector, valueType situation (3)
     *      multi value
     *
     * @private
     * @param {String} value
     * @param {String} ancestor
     * @param {String} selector
     * @param {String} valueType
     * @param {NodeList} elements
     * @returns {String}
     */
    _getValue_valueAncestorSelectorValueType_multiValue (value, ancestor, selector, valueType, elements) {
        let valStr = '';
        if (elements.length > 0) {
            if (value in elements[0]) {
                valStr = String(elements[0][value]).trim();
            } else if (elements[0].hasAttribute(value)) {
                valStr = String(elements[0].getAttribute(value)).trim();
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.ATTRIBUTE_NOT_FOUND.format(value));
            }
            for (let i = 1; i < elements.length; i++) {
                if (value in elements[i]) {
                    valStr += (this._multiValSep + String(elements[i][value]).trim());
                } else if (elements[i].hasAttribute(value)) {
                    valStr += (this._multiValSep + String(elements[i].getAttribute(value)).trim());
                } else {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.ATTRIBUTE_NOT_FOUND.format(value));
                }
            }
        }
        return valStr;
    }

    /**
     * Get value for:
     *      ancestor, selector, valueType situation (4)
     *
     * @private
     * @param {String} value
     * @param {String} ancestor
     * @param {String} selector
     * @param {String} valueType
     * @param {HTMLElement} targetElement
     * @returns {String|Number|Boolean}
     */
    _getValue_ancestorSelectorValueType (value, ancestor, selector, valueType, targetElement) {
        let valStr = '';
        let elements = [];
        if (targetElement) {
            let ancestorElement = null;
            if (typeof ancestor === Constants.JS_TYPE_STRING && ancestor !== targetElement) { // TODO: check with targetElement makes no sense if it's a string
                ancestorElement = targetElement.closest(ancestor);
                if (!ancestorElement) {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Target.NO_ANCESTOR_FOUND_WITH_SELECTOR.format(ancestor));
                }
            } else {
                // ancestor was set to be targetElement so use it as the implicit ancestor
                ancestorElement = ancestor;
            }

            if (ancestorElement) {
                try {
                    elements = ancestorElement.querySelectorAllDeep(selector);
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.FOUND_ELEMENTS.format(selector, elements.length));
                } catch (e) {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.FAILED_TO_QUERY_SELECTOR.format(selector));
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.ERROR, e.stack);
                }
            } else {
                // TODO: why log this a second time?
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Target.NO_ANCESTOR_FOUND_WITH_SELECTOR.format(ancestor));
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Target.NO_TARGET_TO_FIND_ANCESTOR);
        }

        if (valueType === Constants.DYNAMIC_DATA_COUNT_VAL) {
            valStr = elements.length;
        } else if (valueType === Constants.DYNAMIC_DATA_BOOLEAN_VAL) {
            valStr = elements.length ? true : false;
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.UNSUPPORTED_VALUE_TYPE.format(valueType));
        }
        return valStr;
    }

    /**
     * Get value for:
     *      ancestor, valueType situation (5)
     *
     * @private
     * @param {String} value
     * @param {String} ancestor
     * @param {String} selector
     * @param {String} valueType
     * @param {HTMLElement} targetElement
     * @returns {String|Boolean}
     */
    _getValue_ancestorValueType (value, ancestor, selector, valueType, targetElement) {
        let valStr = '';
        let ancestorElement = null;
        if (targetElement) {
            if (typeof ancestor === Constants.JS_TYPE_STRING && ancestor !== targetElement) {
                ancestorElement = targetElement.closest(ancestor);
                if (!ancestorElement) {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Target.NO_ANCESTOR_FOUND_WITH_SELECTOR.format(ancestor));
                }
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Target.NO_TARGET_TO_FIND_ANCESTOR);
        }

        if (valueType === Constants.DYNAMIC_DATA_BOOLEAN_VAL) {
            valStr = ancestorElement ? true : false;
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.UNSUPPORTED_VALUE_TYPE.format(valueType));
        }
        return valStr;
    }
}

export default TargetSelector; // JSDoc workaround for documenting classes
