import { Constants } from './Constants';
import { ConsoleStrings } from './ConsoleStrings';
import Logger from './Logger';
import ObservableElement from './UsageElementTypes/ObservableElement';

/**
 * Class that handles the processing of mutation events
 */
class MutationProcessor {

    /**
     * @static
     * @memberof MutationProcessor
     */
    static observer = null;

    /**
     * Function to create observer
     *
     * @static
     * @private
     */
    static _createObserver () {
        if (!this.observer) {
            this.observer = new MutationObserver(MutationProcessor.onMutationOfDOM);
        }
    }

    /**
     * Set up an observation on the observer
     *
     * @static
     * @param {HTMLElement} target
     * @param {Object} options
     */
    static set (target, options) {
        this._createObserver();
        MutationProcessor.observer.observe(target, options);
    }

    /**
     * Reset the observer
     * @static
     */
    static reset () {
        if (MutationProcessor.observer) {
            MutationProcessor.observer.disconnect();
        }
    }

    /**
     * This is our MutationObserver callback. It should never be called directly as it
     * requires a MutationRecord array as input. With the MutationRecord array we iterate
     * over them and our watch list to look for a target match. If we have a match we then
     * query for the element's selector to see if it's in the newly changed DOM area. If so,
     * we process that element the same as usual.
     *
     * @static
     * @param {MutationRecord[]} mutationRecords
     * @returns {Promise}
     */
    static async onMutationOfDOM (mutationRecords) {
        let logger = Logger.getLogger();
        let lib = null;
        if (!process.env.UNIT_TEST || (global && global.ANALYTICS_LIB_TEST)) {
            lib = ((await import('./AnalyticsLib')).default).getLib();
        }

        logger.debug(ConsoleStrings.MutationObserver.OnMutation.CHANGE_DETECTED_PROCESSING_LIST.format(ConsoleStrings.MutationObserver.LABEL));

        // First find the observer and check if a mutationRecord matches it
        let matched = false;
        let curElement = null;
        if (lib) {
            for (let ael = 0; ael < lib._analyticsElementWatchList.length && !matched; ael++) { // checking all 'observable' elements that are set up
                const curWatchListElement = lib._analyticsElementWatchList[ael];
                let curElementConfig = curWatchListElement.element;

                // Set up extended configuration settings
                let childListAll = false;
                let childListConfig = null;
                let childListConditions = null;
                let attributesAll = false;
                let attributesConfig = null;
                let attributesConditions = null;
                let characterDataAll = false;
                if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS in curElementConfig && curElementConfig[Constants.ELEMENT_DATA_OBSERVER_OPTIONS] !== null) {
                    let observerOptions = curElementConfig[Constants.ELEMENT_DATA_OBSERVER_OPTIONS];
                    if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST in observerOptions) {
                        if (typeof observerOptions[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST] === Constants.JS_TYPE_OBJECT) {
                            childListConfig = observerOptions[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST];
                            if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITIONS in childListConfig) {
                                // Copy conditions into a working, editable property called currentConditions to keep track of the match
                                childListConfig[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CURRENT_CONDITIONS] =
                                    JSON.parse(JSON.stringify(childListConfig[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITIONS]));
                                childListConditions = childListConfig[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CURRENT_CONDITIONS];
                            }
                        } else {
                            if (observerOptions[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST]) {
                                childListAll = true;
                            }
                        }
                    }
                    if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS_ATTRIBUTES in observerOptions) {
                        if (typeof observerOptions[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_ATTRIBUTES] === Constants.JS_TYPE_OBJECT) {
                            attributesConfig = observerOptions[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_ATTRIBUTES];
                            if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITIONS in attributesConfig) {
                                // Copy conditions into a working, editable property called currentConditions to keep track of the match
                                attributesConfig[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CURRENT_CONDITIONS] =
                                    JSON.parse(JSON.stringify(attributesConfig[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITIONS]));
                                attributesConditions = attributesConfig[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CURRENT_CONDITIONS];
                            }
                        } else {
                            if (observerOptions[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_ATTRIBUTES]) {
                                attributesAll = true;
                            }
                        }
                    }
                    if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHARACTER_DATA in observerOptions) {
                        if (typeof observerOptions[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHARACTER_DATA] === Constants.JS_TYPE_OBJECT) {
                            // Future characterData extended options go here
                            characterDataAll = true;
                        } else {
                            if (observerOptions[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHARACTER_DATA]) {
                                characterDataAll = true;
                            }
                        }
                    }
                }

                for (let m = 0; m < mutationRecords.length && !matched; m++) { // going through all MutationRecords in this mutation callback to check for match with the observers
                    const mutationRecord = mutationRecords[m];
                    if (mutationRecord.target === curWatchListElement.target || curWatchListElement.target.contains(mutationRecord.target)) {
                        if (mutationRecord.type === Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST && childListConfig !== null) {
                            if (Array.isArray(childListConditions)) {
                                for (let c = 0; c < childListConditions.length && !matched; c++) {
                                    let conditionElementMatched = false;
                                    let condition = childListConditions[c];
                                    if (Array.isArray(condition) && condition.length > 0) {
                                        do {
                                            conditionElementMatched = false;
                                            let thisCondition = condition[0];
                                            let thisConditionTarget = null;
                                            if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TARGET in thisCondition) {
                                                thisConditionTarget = thisCondition[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TARGET];
                                            } else {
                                                thisConditionTarget = curWatchListElement.selector;
                                            }
                                            let thisConditionType = null;
                                            if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE in thisCondition) {
                                                thisConditionType = thisCondition[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE];
                                            }
                                            let thisConditionAdded = null;
                                            if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST_ADDED in thisCondition) {
                                                thisConditionAdded = thisCondition[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST_ADDED];
                                            }
                                            let thisConditionRemoved = null;
                                            if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST_REMOVED in thisCondition) {
                                                thisConditionRemoved = thisCondition[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST_REMOVED];
                                            }

                                            if (thisConditionAdded && mutationRecord.addedNodes) {
                                                for (let x = 0; x < mutationRecord.addedNodes.length; x++) {
                                                    try {
                                                        if (mutationRecord.target && mutationRecord.target.matches(thisConditionTarget)) {
                                                            if (thisConditionType === Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE_ELEMENT &&
                                                                mutationRecord.addedNodes[x] &&
                                                                mutationRecord.addedNodes[x].nodeType === Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE_ELEMENT_ID
                                                            ) {
                                                                if (mutationRecord.addedNodes[x].matches(thisConditionAdded)) {
                                                                    conditionElementMatched = true;
                                                                    break;
                                                                }
                                                            } else if (thisConditionType === Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE_TEXT &&
                                                                mutationRecord.addedNodes[x].nodeType === Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE_TEXT_ID
                                                            ) {
                                                                let regex = new RegExp(thisConditionAdded);
                                                                if (regex.test(mutationRecord.addedNodes[x].textContent)) {
                                                                    conditionElementMatched = true;
                                                                    break;
                                                                }
                                                            }
                                                        }
                                                    } catch (e) {
                                                        logger.warn(ConsoleStrings.MutationObserver.OnMutation.CHILD_LIST_CONDITIONS_EXCEPTION_CAUGHT.format(
                                                            ConsoleStrings.MutationObserver.LABEL));
                                                        logger.error(e.stack);
                                                    }
                                                }
                                            } else if (thisConditionRemoved && mutationRecord.removedNodes) {
                                                for (let x = 0; x < mutationRecord.removedNodes.length; x++) {
                                                    try {
                                                        if (mutationRecord.target && mutationRecord.target.matches(thisConditionTarget)) {
                                                            if (thisConditionType === Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE_ELEMENT &&
                                                                mutationRecord.removedNodes[x] &&
                                                                mutationRecord.removedNodes[x].nodeType === Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE_ELEMENT_ID
                                                            ) {
                                                                if (mutationRecord.removedNodes[x].matches(thisConditionRemoved)) {
                                                                    conditionElementMatched = true;
                                                                    break;
                                                                }
                                                            } else if (thisConditionType === Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE_TEXT &&
                                                                mutationRecord.removedNodes[x].nodeType === Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_TYPE_TEXT_ID
                                                            ) {
                                                                let regex = new RegExp(thisConditionRemoved);
                                                                if (regex.test(mutationRecord.removedNodes[x].textContent)) {
                                                                    conditionElementMatched = true;
                                                                    break;
                                                                }
                                                            }
                                                        }
                                                    } catch (e) {
                                                        logger.error(ConsoleStrings.MutationObserver.OnMutation.CHILD_LIST_CONDITIONS_EXCEPTION_CAUGHT.format(
                                                            ConsoleStrings.MutationObserver.LABEL));
                                                        logger.error(e.stack);
                                                    }
                                                }
                                            }

                                            if (conditionElementMatched) {
                                                logger.trace(ConsoleStrings.MutationObserver.OnMutation.CONDITION_MATCHED.format(
                                                    ConsoleStrings.MutationObserver.LABEL,
                                                    thisConditionAdded ?
                                                        Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST_ADDED : Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CHILD_LIST_REMOVED,
                                                    thisConditionAdded ? thisConditionAdded : thisConditionRemoved)
                                                );
                                                condition.shift();
                                            }
                                        } while (conditionElementMatched && condition.length);
                                    } else {
                                        matched = true;
                                        curElement = curWatchListElement.element;
                                        logger.trace(ConsoleStrings.MutationObserver.OnMutation.CONDITIONS_ARRAY_EMPTY.format(ConsoleStrings.MutationObserver.LABEL));
                                    }
                                }

                                // If we are at the end of condition loop, see if any of the conditions fully matched now
                                if (Array.isArray(childListConditions) && !matched) {
                                    for (let c = 0; c < childListConditions.length && !matched; c++) {
                                        let condition = childListConditions[c];
                                        if (Array.isArray(condition) && condition.length === 0) {
                                            matched = true;
                                            curElement = curWatchListElement.element;
                                            logger.trace(ConsoleStrings.MutationObserver.OnMutation.CONDITIONS_ARRAY_EMPTY.format(ConsoleStrings.MutationObserver.LABEL));
                                        }
                                    }
                                }
                            }
                        } else if (mutationRecord.type === Constants.ELEMENT_DATA_OBSERVER_OPTIONS_ATTRIBUTES && attributesConfig !== null) {
                            if (Array.isArray(attributesConditions)) {
                                for (let c = 0; c < attributesConditions.length && !matched; c++) {
                                    let conditionElementMatched = false;
                                    let condition = attributesConditions[c];
                                    if (Array.isArray(condition) && condition.length > 0) {
                                        let thisCondition = condition[0];
                                        let thisConditionAttribute = null;
                                        if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_ATTRIBUTE in thisCondition) {
                                            thisConditionAttribute = thisCondition[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_ATTRIBUTE];
                                        }
                                        let thisConditionSelector = null;
                                        if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_SELECTOR in thisCondition) {
                                            thisConditionSelector = thisCondition[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_SELECTOR];
                                        }
                                        let thisConditionValue = null;
                                        if (Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_VALUE in thisCondition) {
                                            thisConditionValue = thisCondition[Constants.ELEMENT_DATA_OBSERVER_OPTIONS_CONDITION_VALUE];
                                        }

                                        if (thisConditionAttribute && thisConditionSelector && thisConditionValue) {
                                            if (mutationRecord.attributeName && mutationRecord.target && mutationRecord.target.attributes) {
                                                try {
                                                    if (mutationRecord.target && mutationRecord.target.matches(thisConditionSelector)) {
                                                        if (thisConditionAttribute === mutationRecord.attributeName) {
                                                            let attrItem = mutationRecord.target.attributes.getNamedItem(thisConditionAttribute);
                                                            if (attrItem.nodeValue && attrItem.nodeValue.indexOf(thisConditionValue) !== -1) {
                                                                conditionElementMatched = true;
                                                            }
                                                        }
                                                    }
                                                } catch (e) {
                                                    logger.warn(ConsoleStrings.MutationObserver.OnMutation.ATTRIBUTES_CONDITIONS_EXCEPTION_CAUGHT.format(
                                                        ConsoleStrings.MutationObserver.LABEL));
                                                    logger.error(e.stack);
                                                }
                                            }
                                        }

                                        if (conditionElementMatched) {
                                            logger.trace(ConsoleStrings.MutationObserver.OnMutation.ATTRIBUTE_CONDITION_MATCHED.format(
                                                ConsoleStrings.MutationObserver.LABEL,
                                                thisConditionSelector,
                                                thisConditionAttribute,
                                                thisConditionValue)
                                            );
                                            condition.shift();
                                        }
                                    } else {
                                        matched = true;
                                        curElement = curWatchListElement.element;
                                        logger.trace(ConsoleStrings.MutationObserver.OnMutation.CONDITIONS_ARRAY_EMPTY.format(ConsoleStrings.MutationObserver.LABEL));
                                    }
                                }

                                // If we finished condition loop, see if any of the conditions fully matched now
                                if (Array.isArray(attributesConditions) && !matched) {
                                    for (let c = 0; c < attributesConditions.length && !matched; c++) {
                                        let condition = attributesConditions[c];
                                        if (Array.isArray(condition) && condition.length === 0) {
                                            matched = true;
                                            curElement = curWatchListElement.element;
                                            logger.trace(ConsoleStrings.MutationObserver.OnMutation.CONDITIONS_ARRAY_EMPTY.format(ConsoleStrings.MutationObserver.LABEL));
                                        }
                                    }
                                }
                            }
                        } else {
                            if (childListAll || attributesAll || characterDataAll) {
                                matched = true;
                                curElement = curWatchListElement.element;
                                logger.trace(ConsoleStrings.MutationObserver.OnMutation.FOUND_ELEMENT_MATCH.format(ConsoleStrings.MutationObserver.LABEL));
                            }
                        }
                    }
                }
            }
        }

        // Then if a match is found, go through the observable's elements and process them if necessary
        if (matched) {
            let observableElement = new ObservableElement(curElement);
            await observableElement.handle();
            await observableElement.processElementList(mutationRecords);
        } else {
            logger.trace(ConsoleStrings.MutationObserver.OnMutation.NO_ELEMENT_MATCH.format(ConsoleStrings.MutationObserver.LABEL));
        }
    }
}

export default MutationProcessor; // JSDoc workaround for documenting classes