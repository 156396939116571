/** @module PageEvents */
import { Constants } from './Constants';
import { ConsoleStrings } from './ConsoleStrings';

/**
 * Function to run on the page's load event
 *
 * @async
 * @function loadEvent
 */
export async function loadEvent () {
    window._wkUsageAnalyticsLib._loadEventFired = true;
    window._wkUsageAnalyticsLib._logger.debug(ConsoleStrings.AnalyticsLib.Events.LOAD_FIRED);

    // Make sure the pre-scan is finished before running the main scan
    let interval = setInterval(async function () {
        if (window._wkUsageAnalyticsLib._preScanFinished) {
            clearInterval(interval);

            window._wkUsageAnalyticsLib._logger.trace(ConsoleStrings.AnalyticsLib.Events.START_LOAD_PROCESSING);

            if (window._wkUsageAnalyticsLib._analyzePageCalled) {
                await window._wkUsageAnalyticsLib.scanPage(true);
            } else {
                window._wkUsageAnalyticsLib._logger.trace(ConsoleStrings.AnalyticsLib.AnalyzePage.NOT_CALLED_NO_SCAN);
            }

            window._wkUsageAnalyticsLib._logger.trace(ConsoleStrings.AnalyticsLib.Events.FINISH_LOAD_PROCESSING);
        } else {
            window._wkUsageAnalyticsLib._logger.trace(ConsoleStrings.AnalyticsLib.Events.LOAD_PRESCAN_NOT_FINISHED);
        }
    }, Constants.LOAD_EVENT_PRE_SCAN_INTERVAL);
}
// After the page is fully loaded, run the main scan
if (typeof window !== 'undefined') {
    window.addEventListener("load", loadEvent, false);
}

/**
 * Function to run on the page's DOMContentLoaded event
 *
 * @async
 * @function domContentLoadedEvent
 */
export async function domContentLoadedEvent () {
    window._wkUsageAnalyticsLib._DOMContentLoadedEventFired = true;
    window._wkUsageAnalyticsLib._logger.debug(ConsoleStrings.AnalyticsLib.Events.START_DOM_CONTENT_PROCESSING);
    if (window._wkUsageAnalyticsLib._analyzePageCalled) {
        await window._wkUsageAnalyticsLib._preScanPage();
    } else {
        window._wkUsageAnalyticsLib._logger.trace(ConsoleStrings.AnalyticsLib.AnalyzePage.NOT_CALLED_NO_PRESCAN);
    }
    window._wkUsageAnalyticsLib._logger.debug(ConsoleStrings.AnalyticsLib.Events.FINISH_DOM_CONTENT_PROCESSING);
}

// After the DOM is loaded, run the pre-scan
if (typeof window !== 'undefined') {
    document.addEventListener("DOMContentLoaded", domContentLoadedEvent, false);
}

/**
 * Function to run on the page's unload event
 *
 * @async
 * @function unloadEvent
 */
export async function unloadEvent () {
    window._wkUsageAnalyticsLib._logger.debug(ConsoleStrings.AnalyticsLib.Events.START_UNLOAD_PROCESSING);
    if (window._wkUsageAnalyticsLib._analyzePageCalled) {
        if (window._wkUsageAnalyticsLib._scanStarted === false) {
            window._wkUsageAnalyticsLib._logger.trace(ConsoleStrings.AnalyticsLib.Events.NO_SCAN_UNLOAD_SCAN_NOW);
            await window._wkUsageAnalyticsLib.scanPage(true);
        }
    } else {
        window._wkUsageAnalyticsLib._logger.trace(ConsoleStrings.AnalyticsLib.AnalyzePage.NOT_CALLED_NO_SCAN);
    }
    window._wkUsageAnalyticsLib._logger.debug(ConsoleStrings.AnalyticsLib.Events.FINISH_UNLOAD_PROCESSING);
}

// Before we leave the page, check to see if the main scan has been run, and if not, run it now so we don't miss anything
if (typeof window !== 'undefined') {
    window.addEventListener("unload", unloadEvent, false);
}
