import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import UsageElement from './UsageElement';
import Utils from '../Utils';

/**
 * Class for tracking html elements
 * @extends UsageElement
 */
class HtmlElement extends UsageElement {

    /**
     * Constructor for HtmlElement
     * @param {Object} elementConfig
     */
    constructor (elementConfig) {
        super('HtmlElement', elementConfig);
    }

    /**
     * Function to hanldle the html element
     *
     * @async
     * @param {ObservableElement} observableElement
     * @returns {Promise}
     */
    async handle (observableElement) {
        await super.handle(observableElement);

        if (!this._checkPreCondition()) {
            return;
        }

        if (Constants.ELEMENT_DATA_SELECTOR in this._config && this._config[Constants.ELEMENT_DATA_SELECTOR] !== null) {
            let [waitForExist, waitForSelector, waitForExistTimeout] = this._getWaitForExistConfig();

            let trackMultiple = false;
            if (Constants.ELEMENT_DATA_TRACK_MULTIPLE in this._config) {
                trackMultiple = this._config[Constants.ELEMENT_DATA_TRACK_MULTIPLE];
            }

            const elements = await this._getHTMLElements(this._config[Constants.ELEMENT_DATA_SELECTOR], waitForExist, waitForSelector, waitForExistTimeout);

            if (elements.length) {
                this._processInitDynamicData();

                // set track true and rescan false by default
                let track = true;
                let rescan = false;

                const rescanOptions = this._checkIfRescan();

                let asyncOptions = null;
                if (Constants.ELEMENT_DATA_ASYNC in this._config) {
                    asyncOptions = this._config[Constants.ELEMENT_DATA_ASYNC];
                }

                if (asyncOptions) {
                    // set track default to false for async link
                    track = false;

                    // use rescan options rescan value
                    rescan = rescanOptions.rescan;

                    if (Constants.ELEMENT_DATA_ASYNC_OPTION_TRACK in asyncOptions) {
                        track = asyncOptions[Constants.ELEMENT_DATA_ASYNC_OPTION_TRACK];
                    }
                }

                if (track) {
                    const numOfCalls = trackMultiple ? elements.length : 1;

                    let data = null;
                    if (Constants.ELEMENT_DATA_DATA in this._config && this._config[Constants.ELEMENT_DATA_DATA] !== null) {
                        data = this._config[Constants.ELEMENT_DATA_DATA];

                        let trackingEventType = Constants.EVENT_TYPE_GENERAL;
                        if (Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE in this._config && this._config[Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE] !== null) {
                            trackingEventType = this._config[Constants.ELEMENT_DATA_TRACKING_EVENT_TYPE];
                        }

                        if (this._checkCondition()) {
                            for (let n = 0; n < numOfCalls; n++) {
                                this._logMessage(Constants.LOGGER_LEVELS_ENUM.DEBUG, ConsoleStrings.Element.MAKING_TRACK_EVENT_CALLS.format(
                                    trackingEventType, Utils.JSONStringify(data)));
                                this._lib.makeTrackEventCalls(trackingEventType, data);
                            }
                        }
                    } else {
                        if (!asyncOptions) {
                            this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.NO_DATA_NOT_TRACKING.format(ConsoleStrings.Element.TYPE_HTML));
                        }
                    }
                }

                if (rescan) {
                    await this._performRescan(rescanOptions);
                }
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.Element.ELEMENT_DOES_NOT_EXIST.format(
                    ((waitForExist && waitForSelector) ? waitForSelector : this._config[Constants.ELEMENT_DATA_SELECTOR])));
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.Element.MUST_HAVE_SELECTOR.format(ConsoleStrings.Element.TYPE_HTML));
        }
    }
}

export default HtmlElement; // JSDoc workaround for documenting classes
