import { Constants } from '../Constants';
import { ConsoleStrings } from '../ConsoleStrings';
import DynamicDataTypeSelector from './DynamicDataTypeSelector';

/**
 * Used to handle page value type selections in dynamic data objects
 * @extends DynamicDataTypeSelector
 */
class PageValueSelector extends DynamicDataTypeSelector {

    /**
     * Constructor for PageValueSelector
     * @param {Object} options
     * @param {Object} pageValues
     */
    constructor (options, pageValues) {
        super('PageValueSelector', options);
        this._pageValues = pageValues;
    }

    /**
     * The main function to get the value of this dynamic data object
     *
     * @returns {String|Number}
     */
    get () {
        const pageValues = this._getDynamicDataPageValuesObject();
        let valStr = '';

        if (this._selector) {
            if (this._selector.indexOf(Constants.DYNAMIC_DATA_PAGE_VALUE_OBJECT_PROPERTY_SEPARATOR) !== -1) {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Page.SELECTOR_HAS_SUB_OBJECT_INDICATOR);
                let propNames = this._selector.split(Constants.DYNAMIC_DATA_PAGE_VALUE_OBJECT_PROPERTY_SEPARATOR);
                let curObj = pageValues;
                for (let p = 0; p < propNames.length && curObj !== null; p++) {
                    if (propNames[p] in curObj) {
                        curObj = curObj[propNames[p]];
                    } else {
                        curObj = null;
                    }
                }
                if (curObj !== null) {
                    valStr = this._getValueFromProperty(curObj);
                }
            } else {
                if (this._selector in pageValues) {
                    valStr = this._getValueFromProperty(pageValues[this._selector]);
                } else {
                    this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Page.VALUE_NOT_AVAILABLE.format(this._selector));
                }
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.Page.VALUE_NOT_AVAILABLE.format(this._selector));
        }

        return this._applyModifiers(valStr);
    }

    /**
     * Function to get the actual object that was input to use.
     *
     * @private
     * @returns {Object}
     */
    _getDynamicDataPageValuesObject () {
        const values = {};

        // Set up input values
        if (this._pageValues !== null) {
            const inputKeys = Object.keys(this._pageValues);
            for (let x = 0; x < inputKeys.length; x++) {
                values[inputKeys[x]] = this._pageValues[inputKeys[x]];
            }
        }

        return values;
    }

    /**
     * Get the final value from an input property/object
     *
     * @private
     * @param {Object} prop
     * @returns {String}
     */
    _getValueFromProperty (prop) {
        let valStr = '';
        if (this._valueType && this._valueType === Constants.DYNAMIC_DATA_SINGLE_VAL) {
            valStr = prop;
        } else if (this._valueType && this._valueType === Constants.DYNAMIC_DATA_MULTI_VAL) {
            if (Array.isArray(prop)) {
                if (prop.length > 0) {
                    valStr = prop[0];
                }
                for (let valIndex = 1; valIndex < prop.length; valIndex++) {
                    valStr += (this._multiValSep + prop[valIndex]);
                }
            } else {
                this._logMessage(Constants.LOGGER_LEVELS_ENUM.TRACE, ConsoleStrings.DynamicData.MUTLIVAL_MUST_BE_ARRAY);
            }
        } else {
            this._logMessage(Constants.LOGGER_LEVELS_ENUM.WARN, ConsoleStrings.DynamicData.UNSUPPORTED_VALUE_TYPE.format(this._valueType));
        }
        return valStr;
    }
}

export default PageValueSelector; // JSDoc workaround for documenting classes
